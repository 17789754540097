import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Project } from 'types/store/projectsList';
import { selectSearchValue } from 'state/ProjectsList/projectsListSlice';
import EmptyProjectsTable from './EmptyProjectsTable/EmptyProjectsTable';
import ProjectsTableRow from './ProjectsTableRow/ProjectsTableRow';
import ProjectsTableLastRow from './ProjectsTableLastRow';

interface ProjectsTableBodyProps {
  tableName: string;
  projects: Project[];
  canFetchMoreProjects?: boolean;
  fetchMoreProjects: () => void;
}

const ProjectsTableBody: FC<ProjectsTableBodyProps> = ({
  tableName,
  projects,
  canFetchMoreProjects = false,
  fetchMoreProjects,
}) => {
  const isListEmpty = projects.length === 0;
  const searchValue = useSelector(selectSearchValue);
  return (
    <tbody>
      {projects.map((project: Project, index: number) => (
        <ProjectsTableRow
          key={index}
          project={project}
          isOdd={index % 2 !== 0}
        />
      ))}
      {isListEmpty && (
        <tr>
          <td colSpan={6}>
            <EmptyProjectsTable emptySearch={!searchValue} />
          </td>
        </tr>
      )}
      {!isListEmpty && canFetchMoreProjects && (
        <ProjectsTableLastRow
          isOdd={projects.length % 2 !== 0}
          tableName={tableName}
          onLastRowInView={fetchMoreProjects}
        />
      )}
    </tbody>
  );
};

export default ProjectsTableBody;
