import { GoalProject, StrategyGoalMetrics } from 'utils/types/strategyGoals';
import { PROJECT_STATUS } from 'utils/constants';

export const getProjectSummary = (
  projects: GoalProject[]
): StrategyGoalMetrics => {
  return projects.reduce(
    (acc, project) => {
      acc.totalProjectsCount++;
      if (project.status === PROJECT_STATUS.COMPLETED) {
        acc.totalCompletedProjectsCount++;
      }

      acc.totalProjectProgress.totalTasks += project.progress.totalTasks;
      acc.totalProjectProgress.totalCompletedTasks +=
        project.progress.completedTasks;
      acc.totalProjectProgress.totalEstimateHours +=
        project.progress.estimateHours;
      acc.totalProjectProgress.totalActualHours += project.progress.actualHours;

      return acc;
    },
    {
      totalProjectsCount: 0,
      totalCompletedProjectsCount: 0,
      totalProjectProgress: {
        totalTasks: 0,
        totalCompletedTasks: 0,
        totalEstimateHours: 0,
        totalActualHours: 0,
      },
    }
  );
};
