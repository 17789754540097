import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import useWeeksSlider from '../../hooks/useWeeksSlider';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';
import Timeline from '../Timeline/Timeline';

const Column: React.FC<{ isTargetDateInWeek: boolean }> = ({
  isTargetDateInWeek,
}) => (
  <div className='w-1/6 h-full flex-shrink-0 flex-grow'>
    {isTargetDateInWeek && (
      <div
        className={tailwindOverride(
          'w-full h-full',
          'px-2 py-1',
          'flex items-center justify-center',
          'bg-success-lighter'
        )}
      >
        <Typography
          variant='caption'
          className='text-success-darkest font-semibold'
        >
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.TARGET_COMPLETION')}
        </Typography>
      </div>
    )}
  </div>
);

const TargetCompletion: React.FC<{
  targetCompletionDate: Date | string;
}> = ({ targetCompletionDate }) => {
  const { weeksArray } = useWeeksSlider();
  const targetDate = moment(new Date(targetCompletionDate));
  return (
    <div className='w-full h-full flex bg-neutral-white'>
      <div className='w-2/5' />
      <div className='w-3/5 flex h-full'>
        <Timeline
          id='target-completion'
          className='bg-neutral-white'
          displayGridLoader={false}
        >
          {weeksArray.map((week: Week, index: number) => {
            const startDate = moment(new Date(week.startWeekDate));
            const endDate = moment(new Date(week.endWeekDate));
            const isTargetDateInWeek =
              targetDate.isSameOrAfter(startDate) &&
              targetDate.isSameOrBefore(endDate);
            return (
              <Column
                key={week.startWeekDate.valueOf() + index}
                isTargetDateInWeek={isTargetDateInWeek}
              />
            );
          })}
        </Timeline>
      </div>
    </div>
  );
};

export default TargetCompletion;
