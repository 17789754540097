import moment from 'moment';

export const setMomentLocale = async (locale: string) => {
  try {
    await import(`moment/locale/${locale}`);
    moment.locale(locale);
  } catch (error) {
    console.warn(`Locale ${locale} not found, falling back to default (en).`); // eslint-disable-line no-console
    moment.locale('en'); // Fallback locale
  }
};

// Get locale from localStorage or default to 'en'
const storedLocale = localStorage.getItem('locale') || 'en';
setMomentLocale(storedLocale);

export default moment;
