import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { Table, Typography } from '@getsynapse/design-system';
import { DATE, PATHS } from 'utils/constants';
import { formatProjectNumber } from 'utils/formatters';
import { SortingType } from 'utils/customTypes';
import {
  getOwnerColumn,
  getStatusColumn,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { getProgress } from 'Pages/GoalDetailsPage/helpers/helper';
import { GOAL_PROJECTS_TABLE_COLUMNS } from 'utils/constants/strategyGoals';
import {
  fetchGoalProjects,
  selectFormattedGoalProjects,
} from 'state/GoalProjects/GoalProjectsSlice';
import {
  getProjectPermissions,
  projectPermissionsSelectors,
} from 'state/normalized/projectPermissions/projectPermissionsSlice';
import classNames from 'classnames';

const GoalProjectsTable = ({ goalId }: { goalId: string }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const projects = useSelector(selectFormattedGoalProjects);
  const fetchProjectPermissions = useSelector(
    projectPermissionsSelectors.selectProjectPermissionsByIds
  );
  const projectIds = useMemo(
    () => projects.map((project) => project.id),
    [projects]
  );

  const projectPermissions = fetchProjectPermissions(projectIds);

  const projectsWithPermissions = projects.map((project) => {
    return {
      ...project,
      permissions: projectPermissions.find(
        (permission) => permission.id === project.id
      ),
    };
  });

  useEffect(() => {
    if (projectIds.length > 0) {
      dispatch(getProjectPermissions({ projectIds }));
    }
  }, [projectIds, dispatch]);

  const sortProjects = (orderByParam: string, order: SortingType) => {
    dispatch(fetchGoalProjects({ goalId, orderByParam, order }));
  };

  return (
    <div className='overflow-auto border rounded border-primary-lighter-two max-h-screen-minus-8rem'>
      <Table
        data-testid='goal-projects-table'
        className='border-0 w-full'
        canSelectRows={false}
        isSelectRowCellSticky
        data={{
          headData: {
            stickyHeader: true,
            handleSort: sortProjects,
            headCells: [
              {
                columnName: GOAL_PROJECTS_TABLE_COLUMNS.ID,
                content: intl.get('STRATEGY_GOALS.LINKED_PROJECTS.ID'),
              },
              {
                columnName: GOAL_PROJECTS_TABLE_COLUMNS.TITLE,
                content: intl.get('STRATEGY_GOALS.LINKED_PROJECTS.TITLE'),
              },
              {
                columnName: GOAL_PROJECTS_TABLE_COLUMNS.STATUS,
                content: intl.get('STRATEGY_GOALS.LINKED_PROJECTS.STATUS'),
              },
              {
                columnName: GOAL_PROJECTS_TABLE_COLUMNS.OWNER,
                content: intl.get('STRATEGY_GOALS.LINKED_PROJECTS.OWNER'),
              },
              {
                columnName: GOAL_PROJECTS_TABLE_COLUMNS.PROGRESS,
                content: intl.get('STRATEGY_GOALS.LINKED_PROJECTS.PROGRESS'),
              },
              {
                columnName: GOAL_PROJECTS_TABLE_COLUMNS.START_DATE,
                content: intl.get('STRATEGY_GOALS.LINKED_PROJECTS.START_DATE'),
              },
              {
                columnName: GOAL_PROJECTS_TABLE_COLUMNS.TARGET_COMPLETION_DATE,
                content: intl.get(
                  'STRATEGY_GOALS.LINKED_PROJECTS.TARGET_COMPLETION_DATE'
                ),
              },
            ],
          },
          rows: projectsWithPermissions.map((project) => {
            const canView = project?.permissions?.canView;
            return {
              id: project.id,
              className: classNames({
                'cursor-pointer': canView,
                'cursor-not-allowed': !canView,
              }),
              onClick: () =>
                canView && history.push(`${PATHS.PROJECT_PAGE}/${project.id}`),
              cells: [
                {
                  content: formatProjectNumber(project.projectNumber),
                },
                {
                  content: canView ? (
                    <Link
                      to={`${PATHS.PROJECT_PAGE}/${project.id}`}
                      title={project.title}
                    >
                      {project.title}
                    </Link>
                  ) : (
                    <div title={project.title}>{project.title}</div>
                  ),
                  className: 'max-w-60 truncate',
                },
                {
                  content: getStatusColumn(project.status),
                },
                {
                  content: project.owners && getOwnerColumn(project.owners),
                },
                {
                  content: getProgress(
                    project.progress.totalTasks,
                    project.progress.completedTasks,
                    'w-22'
                  ),
                },
                {
                  content:
                    project.projectStartDate &&
                    moment(new Date(project.projectStartDate)).format(
                      DATE.SHORT_FORMAT
                    ),
                },
                {
                  content:
                    project.projectTargetCompletionDate &&
                    moment(
                      new Date(project.projectTargetCompletionDate)
                    ).format(DATE.SHORT_FORMAT),
                },
              ],
            };
          }),
        }}
        emptyComponent={
          <Typography
            className='flex justify-center w-full my-4'
            variant='body2'
          >
            {intl.get('STRATEGY_GOALS.LINKED_PROJECTS.NO_LINKED_PROJETS')}
          </Typography>
        }
      />
    </div>
  );
};
export default GoalProjectsTable;
