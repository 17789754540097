import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import {
  Modal,
  Dropdown,
  Typography,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import { PATHS } from 'utils/constants';
import { ProjectTemplate } from 'utils/types/templates';
import { selectAllTemplates } from 'state/ProjectTemplates/projectTemplatesSlice';
import AddProjectIcon from 'assets/icons/add-project-icon.svg';

const CreateProjectModal: React.FC<{
  showModal: boolean;
  onCloseModal: () => void;
}> = ({ showModal = false, onCloseModal }) => {
  const history = useHistory();
  const [shouldDisplayModal, setShouldDisplayModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const projectTemplates = useSelector(selectAllTemplates);

  useEffect(() => {
    if (showModal && !shouldDisplayModal) {
      setShouldDisplayModal(showModal);
    }
  }, [showModal, shouldDisplayModal]);

  const handleCloseModal = () => {
    onCloseModal();
    setShouldDisplayModal(false);
  };

  const handleCreateProject = () => {
    history.push(`${PATHS.NEW_PROJECT_PAGE}/${selectedTemplate}`);
  };

  return (
    <Modal
      title={intl.get('PROJECTS_LIST_PAGE.CREATE_PROJECT_MODAL.TITLE')}
      titleIcon={{ src: AddProjectIcon }}
      isOpen={shouldDisplayModal}
      size='large'
      closeModal={handleCloseModal}
      aria-label={intl.get('PROJECTS_LIST_PAGE.CREATE_PROJECT_MODAL.TITLE')}
      actionButtons={[
        {
          children: intl.get('CREATE'),
          variant: 'primary',
          disabled: !selectedTemplate,
          'data-testid': 'create-project-button',
          onClick: handleCreateProject,
          className: tailwindOverride({
            'cursor-not-allowed': !selectedTemplate,
          }),
        },
      ]}
      data-testid='create-project-modal'
    >
      <Typography variant='body' className='mb-4 text-primary'>
        {intl.get('PROJECTS_LIST_PAGE.CREATE_PROJECT_MODAL.BODY')}
      </Typography>
      <FormItem
        label={intl.get(
          'PROJECTS_LIST_PAGE.CREATE_PROJECT_MODAL.TEMPLATE_FIELD_LABEL'
        )}
        labelProps={{ required: true }}
        className='w-88'
      >
        <Dropdown
          options={projectTemplates}
          getOptionLabel={(option: ProjectTemplate) => option.name}
          placeholder={intl.get('SELECT')}
          onChange={(option: ProjectTemplate) =>
            setSelectedTemplate(option.id!)
          }
          listProps={{ 'data-testid': 'templates-list' }}
          triggerProps={{ 'data-testid': 'select-template' }}
          renderOption={(
            option: ProjectTemplate,
            isSelected: boolean,
            selectOption,
            { className, ...otherProps }
          ) => (
            <li
              {...otherProps}
              data-value={option.id}
              data-testid={option.id}
              key={option.id}
              className={tailwindOverride('group', className, {
                'hover:bg-primary focus-visible:bg-primary': isSelected,
              })}
              onClick={selectOption}
            >
              <div className='flex w-100 flex-col space-y-1 '>
                <Typography
                  variant='label'
                  weight='medium'
                  className='text-primary'
                >
                  {option.name}
                </Typography>
                {option.description && (
                  <Typography variant='caption' className='text-neutral'>
                    {option.description}
                  </Typography>
                )}
              </div>
            </li>
          )}
        />
      </FormItem>
    </Modal>
  );
};

export default CreateProjectModal;
