import intl from 'react-intl-universal';
import { useMemo } from 'react';
import { Typography } from '@getsynapse/design-system';
import { Icon } from '@getsynapse/design-system';
import completedProjectsIcon from 'assets/icons/projects.svg';
import actualHoursIcon from 'assets/icons/actual-hours.svg';
import { StrategyGoalMetrics } from 'utils/types/strategyGoals';
import TasksChart from './TasksChart';
import { calculatePercentage } from '../../helpers/helper';

const GoalMetricContent = ({
  status,
  metricData,
}: {
  status: string;
  metricData: string;
}) => {
  return (
    <div className='flex flex-col'>
      <Typography variant='caption' className='text-neutral'>
        {status}
      </Typography>
      <Typography className='text-xl font-semibold'>{metricData}</Typography>
    </div>
  );
};

const GoalMetrics = ({ metrics }: { metrics: StrategyGoalMetrics }) => {
  const projectsCount = useMemo(() => {
    return metrics.totalCompletedProjectsCount !== 0 ||
      metrics.totalProjectsCount !== 0
      ? `${metrics.totalCompletedProjectsCount ?? 0}/${
          metrics.totalProjectsCount ?? 0
        } ${intl.get('STRATEGY_GOALS.GOAL_METRICS.PROJECTS')}`
      : `0 ${intl.get('STRATEGY_GOALS.GOAL_METRICS.PROJECTS')}`;
  }, [metrics.totalCompletedProjectsCount, metrics.totalProjectsCount]);

  const tasksCount = useMemo(() => {
    return metrics.totalProjectProgress &&
      (metrics.totalProjectProgress?.totalCompletedTasks !== 0 ||
        metrics.totalProjectProgress?.totalTasks !== 0)
      ? `${metrics.totalProjectProgress?.totalCompletedTasks ?? 0}/${
          metrics.totalProjectProgress?.totalTasks ?? 0
        } ${intl.get('STRATEGY_GOALS.GOAL_METRICS.TASKS')}`
      : `0/0 ${intl.get('STRATEGY_GOALS.GOAL_METRICS.TASKS')}`;
  }, [metrics.totalProjectProgress]);

  const actualHoursCount = useMemo(() => {
    return metrics.totalProjectProgress &&
      (metrics.totalProjectProgress?.totalActualHours !== 0 ||
        metrics.totalProjectProgress?.totalEstimateHours !== 0)
      ? `${metrics.totalProjectProgress?.totalActualHours ?? 0}/${
          metrics.totalProjectProgress?.totalEstimateHours ?? 0
        } ${intl.get('STRATEGY_GOALS.GOAL_METRICS.ACTUAL_HOURS')}`
      : `0 ${intl.get('STRATEGY_GOALS.GOAL_METRICS.ACTUAL_HOURS')}`;
  }, [metrics.totalProjectProgress]);

  const tasksCompletedPercentage = useMemo(() => {
    return metrics.totalProjectProgress &&
      (metrics?.totalProjectProgress?.totalTasks !== 0 ||
        metrics?.totalProjectProgress?.totalCompletedTasks !== 0)
      ? calculatePercentage(
          metrics?.totalProjectProgress?.totalCompletedTasks,
          metrics?.totalProjectProgress?.totalTasks
        )
      : 0;
  }, [metrics.totalProjectProgress]);
  const tasksCompletedPercentageText = `${tasksCompletedPercentage}%`;

  return (
    <div className='flex flex-row justify-between mb-4' data-cy='goal-metrics'>
      <div
        data-testid='completed-tasks'
        className='flex items-center border border-neutral-lighter-two rounded mr-2 px-6 py-3 w-2/6 h-20'
      >
        <TasksChart
          tasksCompletedPercentage={tasksCompletedPercentage}
          tasksCompletedPercentageText={tasksCompletedPercentageText}
        />
        <GoalMetricContent
          status={intl.get('STRATEGY_GOALS.GOAL_METRICS.COMPLETED')}
          metricData={tasksCount}
        />
      </div>
      <div
        data-testid='completed-projects'
        className='flex items-center border border-neutral-lighter-two rounded mr-2 px-6 py-4 w-2/6 h-20'
      >
        <Icon src={completedProjectsIcon} className='text-2xl mr-3' />
        <GoalMetricContent
          status={intl.get('STRATEGY_GOALS.GOAL_METRICS.COMPLETED')}
          metricData={projectsCount}
        />
      </div>
      <div
        data-testid='actual-hours'
        className='flex items-center border border-neutral-lighter-two rounded w-2/6 h-20 px-6 py-4'
      >
        <Icon src={actualHoursIcon} className='text-2xl mr-3' />
        <GoalMetricContent
          status={intl.get('STRATEGY_GOALS.GOAL_METRICS.EFFORT_COMPLETED')}
          metricData={actualHoursCount}
        />
      </div>
    </div>
  );
};

export default GoalMetrics;
