import { ProjectOwner } from 'types/store/projectsList';
import { ProjectStatus } from 'utils/types/projectsList';
import { INACTIVE_PROJECTS_STATUSES } from 'utils/constants/projectsList';
import { isUserStatusDisabled } from 'utils/functions';

export const mapProjectOwnerToAvatar = (owner: ProjectOwner) => {
  const allInitials = owner.name.split(' ').map((name) => name[0]);
  const initial = allInitials[0] + allInitials.splice(-1);
  return {
    imageSrc: owner.avatarUrl || '',
    initial,
    name: owner.name,
    disabled: isUserStatusDisabled(owner.status),
  };
};

export const isProjectCompletionDatePast: (
  status: ProjectStatus,
  targetCompletionDate: string | undefined
) => boolean = (status, targetCompletionDate) => {
  if (!targetCompletionDate) {
    return false;
  }

  if (
    INACTIVE_PROJECTS_STATUSES.includes(
      status as 'completed' | 'canceled' | 'closed'
    )
  ) {
    return false;
  }

  const targetDate = new Date(targetCompletionDate);
  const currentDate = new Date();
  return targetDate.getTime() < currentDate.getTime();
};
