import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Icon,
  Tooltip,
  Table,
  IconButton,
  AvatarGroup,
  Typography,
} from '@getsynapse/design-system';
import {
  DATE,
  TASKS_TABLE_TABS,
  PROJECT_USER_ACTIONS,
  TASK_STATUS,
  PROJECT_STATUS,
  PROJECT_PARTICIPANT_TYPE,
  PROJECT_PERMISSIONS_BY_LEVEL,
  TASK_TYPE_CATEGORY,
} from 'utils/constants';
import {
  Task,
  ProjectTasksTableTab,
  AvatarUser,
  TableExportOptions,
} from 'utils/customTypes';
import { getTaskType } from 'utils/tasks';
import { taskModalDefaultValues } from '../../helpers/constants';
import {
  getDueAndActualDateColumn,
  getStatusColumn,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import {
  teamSearchParam,
  mySearchParam,
  teamTasksTableFilters,
  myTasksTableFilters,
  teamTasksTableReorder,
  reorderRow,
} from 'state/ProjectTasks/projectTaskSlice';
import {
  getCurrentProjectData,
  reorderProjectTasks,
} from 'state/Project/projectSlice';
import { selectIsUserLd, selectUserId } from 'state/User/userSlice';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';
import CreateTaskModal from '../../CreateTaskModal';
import DuplicateTaskModal from './DuplicateTaskModal';
import UserAvatar from 'Atoms/UserAvatar';
import EmptyTasks from 'assets/images/empty-tasks.svg';
import searchNoReturns from 'assets/images/no-projects-found-table.svg';
import noFilterResults from 'assets/images/empty-filtered-projects-table.svg';
import disabledTask from 'assets/icons/disabled.svg';
import completedTask from 'assets/icons/success-checkmark.svg';
import ReorderIconArrow from './ReorderIcon';
import classNames from 'classnames';
import { getCurrentLocale } from 'utils/localeHelper';

const TaskTable: React.FC<{
  tasksList: Task[];
  projectId: string;
  onSelectTasks: (tasks: string[]) => void;
  taskTable: ProjectTasksTableTab;
  selectedTasksIds?: string[];
  totalTasks?: number;
  onSelectExportOption: (exportOption: TableExportOptions) => void;
  openTaskPanel: (taskId: string) => void;
  hasActiveTask?: boolean;
}> = ({
  tasksList,
  projectId,
  onSelectTasks,
  taskTable,
  selectedTasksIds = [],
  totalTasks = 0,
  onSelectExportOption,
  openTaskPanel,
  hasActiveTask,
}) => {
  const getStartedText = intl.get('TASKS.TABLE.GET_STARTED');
  const history = useHistory();
  const { canUser } = useUserPermissionsContext();
  const isLDUser = useSelector(selectIsUserLd);
  const userId = useSelector(selectUserId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskData, setTaskData] = useState<Task>(taskModalDefaultValues);
  const { state } = useLocation();
  const fromPage = get(state, 'from', '');
  const currentLocale = getCurrentLocale();
  moment.locale(currentLocale);
  const { permissionsLevel } = useHasUserAccess();
  const availableUserActions = useMemo(
    () => PROJECT_PERMISSIONS_BY_LEVEL[permissionsLevel],
    [permissionsLevel]
  );
  const hasDisabledTask = useMemo(
    () =>
      tasksList.some(
        (task) => task.disabled || task.status === TASK_STATUS.COMPLETED
      ),
    [tasksList]
  );

  const getIconColumn = (task: Task) => {
    if (task.disabled) {
      return (
        <Tooltip
          data-cy={`task-disabled-tooltip-${task.id}`}
          trigger={
            <div>
              <Icon src={disabledTask} className='h-5 w-5' />
            </div>
          }
          openMode='hover2'
          ariaId='task-disabled'
          position='topCenter'
          timeout={0}
          showPopper
          contentProps={{
            className: classnames(
              'bg-neutral-dark',
              'text-neutral-white',
              'rounded',
              'font-body px-3.5 py-2 z-10'
            ),
          }}
        >
          <span>{intl.get('TASKS.TABLE.INACTIVE')}</span>
        </Tooltip>
      );
    } else if (task.status === TASK_STATUS.COMPLETED) {
      return <Icon src={completedTask} className='h-5 w-5' />;
    } else {
      return '';
    }
  };

  const getAssignedUsers = (assignedUser: AvatarUser[] = []) => {
    if (isEmpty(assignedUser)) {
      return (
        <span className='text-neutral-dark'>
          {intl.get('TASKS.TABLE.NO_ASSIGNEE')}
        </span>
      );
    }
    if (assignedUser.length === 1) {
      return (
        <div className='flex items-center'>
          <UserAvatar user={assignedUser[0]} className='mr-2' />
          {`${get(assignedUser, '0.data.firstName')} ${get(
            assignedUser,
            '0.data.lastName'
          )}`}
        </div>
      );
    } else if (assignedUser.length > 1) {
      return (
        <div className='flex items-center'>
          <AvatarGroup
            avatars={assignedUser.map((user) => {
              const firstName = get(user, 'data.firstName');
              const lastName = get(user, 'data.lastName');
              return {
                imageSrc: user.avatar_url,
                initial: get(firstName, '[0]'),
                name: `${firstName} ${lastName}`,
              };
            })}
          />
        </div>
      );
    }
  };

  const getEstimatedAndActualHoursColumn = (task: Task) => {
    const estimatedHours = task.estimate_hours
      ? parseFloat(task.estimate_hours)
      : 0;
    const actualHours = task.actual_hours ? parseFloat(task.actual_hours) : 0;
    const hasActuals = actualHours > 0;
    return (
      <Typography variant='label' className='text-primary'>
        {`${estimatedHours}h${hasActuals ? ` / ${actualHours}h` : ''}`}
      </Typography>
    );
  };

  const searchParam = useSelector(
    taskTable === TASKS_TABLE_TABS.TEAM_TASKS ? teamSearchParam : mySearchParam
  );

  const appliedFilters = useSelector(
    taskTable === TASKS_TABLE_TABS.TEAM_TASKS
      ? teamTasksTableFilters
      : myTasksTableFilters
  );

  const appliedReorder =
    useSelector(teamTasksTableReorder) &&
    taskTable === TASKS_TABLE_TABS.TEAM_TASKS;

  let emptyTableImageSrc = null;
  if (!isEmpty(appliedFilters)) {
    emptyTableImageSrc = noFilterResults;
  }

  if (!isEmpty(searchParam)) {
    emptyTableImageSrc = searchNoReturns;
  }

  const dispatch = useDispatch();
  const projectData = useSelector(getCurrentProjectData);

  const reorderRowHandler = async (direction: string, taskId: string) => {
    if (!projectData?.tasksOrdering) {
      return;
    }
    const currentIndex = projectData?.tasksOrdering?.indexOf(taskId);
    if (currentIndex === 0 && direction === 'up') return;

    const moveUp = currentIndex - 1;
    const moveDown = currentIndex + 1;

    if (!projectData.tasksOrdering) return;

    let newTasksOrderingList =
      projectData?.tasksOrdering?.length > 0
        ? [...projectData?.tasksOrdering]
        : [];

    let newIndex = direction === 'up' ? moveUp : moveDown;
    [newTasksOrderingList[currentIndex], newTasksOrderingList[newIndex]] = [
      newTasksOrderingList[newIndex],
      newTasksOrderingList[currentIndex],
    ];

    await dispatch(
      reorderProjectTasks({ projectId, taskIds: newTasksOrderingList })
    );

    dispatch(reorderRow(newTasksOrderingList));
  };

  const getIsFirstTask = (taskId: string) => {
    if (!projectData?.tasksOrdering) {
      return true;
    }
    const currentIndex = projectData?.tasksOrdering?.indexOf(taskId);
    return currentIndex === 0;
  };

  const getIsLastTask = (taskId: string) => {
    if (!projectData?.tasksOrdering) {
      return true;
    }
    const currentIndex = projectData.tasksOrdering.indexOf(taskId);
    return currentIndex === projectData.tasksOrdering.length - 1;
  };

  const handleDuplicateModal = async (task: Task) => {
    setTaskData({
      ...task,
      name: intl.get('TASKS.TABLE.COPY_OF_PLACEHOLDER').concat(' ', task.name),
    });
    setIsModalOpen(true);
  };

  const isTasksTableEmpty = tasksList.length === 0;

  const shouldDisplayAddTaskButton =
    ![PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
      projectData.status
    ) && canUser(PROJECT_USER_ACTIONS.ADD_TASK);

  return (
    <div
      className={classnames('w-full max-h-request-details rounded-b', {
        'overflow-auto border border-neutral-lighter-two': !isTasksTableEmpty,
        'overflow-hidden border-r border-neutral-lighter-two':
          isTasksTableEmpty,
      })}
    >
      <Table
        className={classnames('relative', {
          'max-w-full border-0': !isTasksTableEmpty,
          'w-full': isTasksTableEmpty,
        })}
        isSelectRowCellSticky={true}
        onSelectRows={onSelectTasks}
        canSelectRows={!isTasksTableEmpty}
        selectedRowsIds={selectedTasksIds}
        data={{
          headData: {
            stickyHeader: true,
            onSelectExportOption: onSelectExportOption,
            headCells: [
              {
                content: '',
                className: classnames('p-0 h-full', {
                  hidden: !appliedReorder,
                  'w-14 left-14 z-2': !isTasksTableEmpty,
                }),
              },
              ...(hasDisabledTask
                ? [
                    {
                      content: '',
                      className: classnames('h-full p-0', {
                        'w-12 z-2': !isTasksTableEmpty,
                        'left-28': appliedReorder,
                        'left-14': !appliedReorder,
                      }),
                    },
                  ]
                : []),
              {
                'data-cy': 'header__task-title',
                content: intl.get('TASKS.TABLE.HEAD.NAME'),
                className: classnames('h-full', {
                  'w-1/3 z-2': !isTasksTableEmpty,
                  'left-26': !appliedReorder && hasDisabledTask,
                  'left-40': appliedReorder && hasDisabledTask,
                  'left-14': !appliedReorder && !hasDisabledTask,
                  'left-28': appliedReorder && !hasDisabledTask,
                }),
              },
              {
                'data-cy': 'header__task-assignee',
                content: intl.get('TASKS.TABLE.HEAD.ASSIGNEE_UPDATE'),
                className: classnames('h-full', { 'w-44': !isTasksTableEmpty }),
              },
              {
                'data-cy': 'header__task-type',
                content: intl.get('TASKS.TABLE.HEAD.TASK_TYPE'),
                className: classnames('h-full', { 'w-31': !isTasksTableEmpty }),
              },
              {
                'data-cy': 'header__task-start-date',
                content: intl.get('TASKS.TABLE.HEAD.START_DATE'),
                className: classnames('h-full', { 'w-38': !isTasksTableEmpty }),
              },
              {
                'data-cy': 'header__task-due-date',
                content: intl.get('TASKS.TABLE.HEAD.DUE_ACTUAL_DATE'),
                className: classnames('h-full', { 'w-60': !isTasksTableEmpty }),
              },
              {
                'data-cy': 'header__task-status',
                content: intl.get('TASKS.TABLE.HEAD.STATUS'),
                className: classnames('h-full', { 'w-20': !isTasksTableEmpty }),
              },
              {
                'data-cy': 'header__task-estimated-hours',
                content: intl.get('TASKS.TABLE.HEAD.ESTIMATED_ACTUAL_HOURS'),
                className: classnames('h-full', { 'w-34': !isTasksTableEmpty }),
              },
              {
                content: <div></div>,
                className: classnames('h-full bg-primary-lightest z-2', {
                  'w-12 right-0': !isTasksTableEmpty,
                }),
              },
            ],
          },

          rows: tasksList.map((task: Task, index: number) => {
            const isFirst = getIsFirstTask(task.id);
            const isLast = getIsLastTask(task.id);
            const isOdd = index % 2 !== 0;
            const isSelected = selectedTasksIds.includes(task.id);

            const isCurrentUserTaskCreator = !isEmpty(task)
              ? userId === task.created_by
              : false;

            const isUserAssignedToTask = task.assignedUsers?.some(
              (user) => user.id === userId
            );

            const canUpdateTask =
              permissionsLevel === PROJECT_PARTICIPANT_TYPE.OWNER ||
              permissionsLevel === PROJECT_PARTICIPANT_TYPE.MEMBER ||
              (availableUserActions.includes(
                PROJECT_USER_ACTIONS.UPDATE_TASK
              ) &&
                (isUserAssignedToTask || isCurrentUserTaskCreator));

            const isReadOnly = [
              PROJECT_STATUS.CLOSED,
              PROJECT_STATUS.CANCELED,
            ].includes(projectData.status);

            const isViewOnlyMode =
              task.status === TASK_STATUS.ON_HOLD ||
              task.disabled ||
              !canUpdateTask ||
              isReadOnly;

            const stickyCellsStyles = (className: string) =>
              classnames(
                'border-transparent',
                {
                  'sticky z-1': !isTasksTableEmpty,
                  'bg-neutral-white': !isOdd && !isSelected,
                  'bg-neutral-lightest-two': isOdd && !isSelected,
                  'bg-secondary-lightest border-secondary-darker': isSelected,
                },
                className
              );

            return {
              'data-cy': `task-${task.id}`,
              id: task.id,
              className: 'group cursor-pointer relative',
              onClick: () => {
                if (hasActiveTask) {
                  openTaskPanel(task.id);
                } else {
                  if (fromPage) {
                    history.push(`${projectId}/tasks/${task.id}`, {
                      from: fromPage,
                    });
                  } else {
                    history.push(`${projectId}/tasks/${task.id}`);
                  }
                }
              },
              cells: [
                {
                  content: (
                    <div className='w-14 flex items-center justify-center'>
                      <ReorderIconArrow
                        name='arrow-up'
                        className='mr-2'
                        isFirst={isFirst}
                        onClickHandler={() => {
                          if (isFirst) return;
                          reorderRowHandler('up', task.id);
                        }}
                        id={task.id}
                        dataCy={`task-reorder-up-${task.id}`}
                      />
                      <ReorderIconArrow
                        name='arrow-down'
                        isLast={isLast}
                        onClickHandler={() => {
                          if (isLast) return;
                          reorderRowHandler('down', task.id);
                        }}
                        id={task.id}
                        dataCy={`task-reorder-down-${task.id}`}
                      />
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames('w-14 h-full p-0 left-14', {
                      hidden: !appliedReorder,
                    })
                  ),
                },
                ...(hasDisabledTask
                  ? [
                      {
                        content: (
                          <div className='w-12 flex h-full items-center justify-center'>
                            {getIconColumn(task)}
                          </div>
                        ),
                        className: stickyCellsStyles(
                          classnames('w-12 h-full p-0', {
                            'left-28': appliedReorder,
                            'left-14': !appliedReorder,
                          })
                        ),
                      },
                    ]
                  : []),
                {
                  content: <div className='w-full truncate'>{task.name}</div>,
                  className: stickyCellsStyles(
                    classnames('w-1/3 h-full', {
                      'left-26': !appliedReorder && hasDisabledTask,
                      'left-40': appliedReorder && hasDisabledTask,
                      'left-14': !appliedReorder && !hasDisabledTask,
                      'left-28': appliedReorder && !hasDisabledTask,
                    })
                  ),
                },
                {
                  content: (
                    <div className='w-44'>
                      {getAssignedUsers(task?.assignedUsers)}
                    </div>
                  ),
                  className: 'w-44',
                },
                {
                  content: (
                    <div className='w-31 whitespace-normal'>
                      {task?.type
                        ? getTaskType(task.type, TASK_TYPE_CATEGORY.TABLE)
                        : intl.get('TASKS.TABLE.NO_TASK_TYPE')}
                    </div>
                  ),
                  className: 'w-31',
                },
                {
                  content: (
                    <div
                      data-testid={`task-${task.id}__start-date`}
                      className={classNames('w-38', {
                        'text-neutral-dark': !task.start_date,
                      })}
                    >
                      {task?.start_date
                        ? moment(new Date(task.start_date)).format(
                            DATE.TASK_TABLE_FORMAT
                          )
                        : intl.get('TASKS.TABLE.NO_DATE')}
                    </div>
                  ),
                  className: 'w-30 truncate',
                },
                {
                  content: (
                    <div className='w-60'>
                      {getDueAndActualDateColumn(task)}
                    </div>
                  ),
                  className: 'w-52',
                },
                {
                  content: (
                    <div className='w-20'>{getStatusColumn(task.status)}</div>
                  ),
                  className: 'w-27',
                },
                {
                  content: (
                    <div className='w-34'>
                      {getEstimatedAndActualHoursColumn(task)}
                    </div>
                  ),
                  className: 'w-34',
                },
                {
                  content: (
                    <div
                      className={classnames('h-full text-center relative', {
                        'w-16': !isTasksTableEmpty,
                        'w-full': isTasksTableEmpty,
                      })}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                        event.stopPropagation()
                      }
                    >
                      {
                        <div className='hidden group-hover:flex'>
                          <Tooltip
                            openMode='hover1'
                            timeout={0}
                            showPopper
                            position='topCenter'
                            contentProps={{
                              className: 'px-3 py-2 text-sm z-50 absolute',
                            }}
                            usePortal
                            trigger={
                              <IconButton
                                name={
                                  !isViewOnlyMode ? 'pencil-outline' : 'eye'
                                }
                                data-cy={`task-quick-edit-button-${task.id}`}
                                iconClassname='pointer-events-none'
                                className='text-base text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                                onClick={() => openTaskPanel(task.id)}
                                aria-label={`task-quick-${
                                  isViewOnlyMode ? 'view' : 'edit'
                                }-button-${task.id}`}
                              />
                            }
                          >
                            {!isViewOnlyMode
                              ? intl.get('SIDE_PANEL.QUICK_EDIT')
                              : intl.get('SIDE_PANEL.QUICK_VIEW')}
                          </Tooltip>
                          {isLDUser &&
                            canUser(PROJECT_USER_ACTIONS.DUPLICATE_TASK) && (
                              <Tooltip
                                openMode='hover1'
                                timeout={0}
                                showPopper
                                position='topCenter'
                                contentProps={{
                                  className: 'px-3 py-2 text-sm z-50',
                                }}
                                usePortal
                                trigger={
                                  <IconButton
                                    name='copy-outline'
                                    iconClassname='pointer-events-none'
                                    data-cy={`task-${task.id}-duplicate-option`}
                                    className='text-base ml-2 text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                                    onClick={() => handleDuplicateModal(task)}
                                  />
                                }
                              >
                                {intl.get('SIDE_PANEL.DUPLICATE')}
                              </Tooltip>
                            )}
                        </div>
                      }
                    </div>
                  ),
                  className: stickyCellsStyles('w-12 right-0'),
                },
              ],
            };
          }),
          total: totalTasks,
        }}
        data-cy={taskTable}
        emptyComponent={
          !isEmpty(appliedFilters) || !isEmpty(searchParam) ? (
            <RenderNoRecords
              dataCy='no-task-found'
              caption={intl.get('TASKS.TABLE.NO_RECORDS')}
              imageSrc={emptyTableImageSrc}
              className={
                !isEmpty(appliedFilters)
                  ? 'h-empty-filtered-table-body'
                  : 'h-empty-table-body'
              }
            />
          ) : (
            <RenderNoRecords
              dataCy='no-task-found'
              caption={intl.get('TASKS.TABLE.EMPTY')}
              imageSrc={EmptyTasks}
              className='h-empty-table-body'
              labelClassName='mt-0'
              imageClassName='-ml-4'
            >
              <div className='mx-auto'>
                {shouldDisplayAddTaskButton && (
                  <CreateTaskModal
                    renderText={getStartedText}
                    componentType='table'
                  />
                )}
              </div>
            </RenderNoRecords>
          )
        }
      />
      <DuplicateTaskModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        taskData={taskData}
      />
    </div>
  );
};

export default TaskTable;
