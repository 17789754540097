import React, { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { TextField, Dropdown, Button } from '@getsynapse/design-system';
import { ProjectProcess, ProjectsBoardTabs, Option } from 'utils/customTypes';
import { ProjectFilters } from 'utils/types/filters';
import { BOARD_SORTING_OPTIONS, PROJECTS_BOARD_TABS } from 'utils/constants';
import { selectProjectProcesses } from 'state/Processes/processesSlice';
import {
  setBoardProcess,
  setBoardSorting,
  setBoardSearchParam,
  selectAssociatedProcessesAndStages,
  setBoardFilters,
} from 'state/Projects/projectsSlice';
import {
  selectFiltersSettingsByType,
  createFilterSetting,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import ProjectsFiltersSidePanel from './ProjectsFiltersSidePanel/ProjectsFiltersSidePanel';
import AppliedFiltersTags from './ProjectsFiltersSidePanel/AppliedFiltersTags';
import {
  createUpdateUserViewSetting,
  getUserSettingValues,
  getViewSettingValues,
  selectCurrentViewSettingValue,
  selectViewSettingValues,
} from 'state/ViewSettings/viewSettingsSlice';

type ProcessOption = { enabled: boolean } & Option;

const BoardHeader: React.FC<{
  boardTab: ProjectsBoardTabs;
  selectedProcessId: string;
  searchParam: string;
}> = ({ selectedProcessId, boardTab, searchParam }) => {
  const dispatch = useDispatch();
  const organizationProjectProcesses = useSelector(selectProjectProcesses);
  const processesAssociatedToProjects = useSelector(
    selectAssociatedProcessesAndStages
  );
  const filtersSettings = useSelector(selectFiltersSettingsByType(boardTab));
  const boardView = useMemo(
    () =>
      boardTab === PROJECTS_BOARD_TABS.TEAM_BOARD
        ? 'PROJECT_VIEW_BY_TEAM_BOARD'
        : 'PROJECT_VIEW_BY_MY_BOARD',
    [boardTab]
  );
  const boardSort = useMemo(
    () =>
      boardTab === PROJECTS_BOARD_TABS.TEAM_BOARD
        ? 'PROJECT_SORT_BY_TEAM_BOARD'
        : 'PROJECT_SORT_BY_MY_BOARD',
    [boardTab]
  );
  const savedSortSetting = useSelector(
    selectCurrentViewSettingValue(boardSort)
  );
  const savedViewSetting = useSelector(
    selectCurrentViewSettingValue(boardView)
  );
  const sortOptions = useSelector(selectViewSettingValues(boardSort));
  const viewOptions = useSelector(selectViewSettingValues(boardView));

  const appliedFilters = useMemo(
    () =>
      filtersSettings
        ? (filtersSettings.settings as ProjectFilters)
        : ({} as ProjectFilters),
    [filtersSettings]
  );

  useEffect(() => {
    if (appliedFilters) {
      dispatch(setBoardFilters(appliedFilters, boardTab));
    }
  }, [appliedFilters, boardTab, dispatch]);

  useEffect(() => {
    dispatch(getUserSettingValues());
    dispatch(
      getViewSettingValues([
        'PROJECT_SORT_BY_TEAM_BOARD',
        'PROJECT_VIEW_BY_TEAM_BOARD',
        'PROJECT_SORT_BY_MY_BOARD',
        'PROJECT_VIEW_BY_MY_BOARD',
      ])
    );
  }, [dispatch, boardTab]);

  const [shouldDisplayFilters, setShouldDisplayFilters] =
    useState<boolean>(false);

  const availableProcesses = useMemo(() => {
    const processes = [];
    for (const process of organizationProjectProcesses) {
      const isProcessDisabled = process.enabled !== null && !process.enabled;
      const isProcessAssociated = process.id in processesAssociatedToProjects;
      if (!isProcessDisabled || isProcessAssociated) {
        processes.push(process);
      }
    }
    return processes;
  }, [organizationProjectProcesses, processesAssociatedToProjects]);

  const processesOptions = useMemo(() => {
    return availableProcesses.map((process: ProjectProcess) => ({
      value: process.id,
      label: process.processName,
      enabled: process.enabled !== null ? process.enabled : true,
    }));
  }, [availableProcesses]);

  const selectedProcess = useMemo(() => {
    return processesOptions.filter(
      (option: Option) =>
        option.value === (savedViewSetting?.value || selectedProcessId)
    );
  }, [processesOptions, selectedProcessId, savedViewSetting]);

  const onChangeProcess = (option: Option) => {
    dispatch(setBoardProcess(option.value, boardTab));
    dispatch(
      createUpdateUserViewSetting({
        view_settings_id: viewOptions[0]?.view_settings_id,
        value: option.value,
      })
    );
  };

  const sortingOptions = useMemo(() => {
    return sortOptions?.map((option) => ({
      value: option.value,
      label: intl.get(`PROJECTS_LIST_PAGE.BOARD.SORTING.${option.value}`),
      option,
    }));
  }, [sortOptions]);

  const handleChangeSorting = (option: Option) => {
    const setting = sortOptions.find((opt) => opt.value === option.value);
    dispatch(setBoardSorting(BOARD_SORTING_OPTIONS[option.value], boardTab));
    if (setting) {
      dispatch(
        createUpdateUserViewSetting({
          view_settings_id: setting?.view_settings_id,
          view_settings_values_id: setting?.view_settings_values_id,
        })
      );
    }
  };

  useEffect(() => {
    if (savedSortSetting) {
      dispatch(
        setBoardSorting(BOARD_SORTING_OPTIONS[savedSortSetting.value], boardTab)
      );
    }
  }, [savedSortSetting, sortingOptions, boardTab, dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBoardSearchParam(event.target.value, boardTab));
  };

  const handleSetFilters = (filters: ProjectFilters) => {
    dispatch(setBoardFilters(filters, boardTab));
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({ filter_type: boardTab, filter_settings: filters })
      );
    }
  };

  const toggleFilters = () => {
    setShouldDisplayFilters((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ProjectsFiltersSidePanel
        open={shouldDisplayFilters}
        filters={appliedFilters}
        onClose={toggleFilters}
        onUpdateFilters={handleSetFilters}
        isBoardView
      />
      <div className='flex absolute top-0 right-0 mr-26'>
        <div>
          <Dropdown
            options={processesOptions}
            values={selectedProcess}
            onChange={onChangeProcess}
            triggerProps={{
              placeholder: intl.get('PROJECTS_LIST_PAGE.BOARD.VIEW_BY'),
              className: 'w-60',
              size: 'sm',
              'data-cy': 'board-process-picker',
              getOptionLabel: (opt: Option) =>
                `${intl.get('PROJECTS_LIST_PAGE.BOARD.VIEW_BY')} ${opt.label}`,
            }}
            renderOption={(
              option: ProcessOption,
              isSelected: boolean,
              selectOption,
              { className, ...otherProps }
            ) => (
              <li
                {...otherProps}
                className={classnames(className, {
                  'bg-neutral-black text-neutral-white': isSelected,
                })}
                onClick={() => selectOption()}
              >
                <div className='w-full flex justify-between'>
                  <span className='truncate flex-1'>{option.label}</span>
                  {!option.enabled && (
                    <span>{`(${intl
                      .get('TASKS.TABLE.HEAD.DISABLED')
                      .toLowerCase()})`}</span>
                  )}
                </div>
              </li>
            )}
          />
        </div>
        <div className='ml-2'>
          <TextField
            placeholder={intl.get('PROJECTS_LIST_PAGE.BOARD.SEARCH')}
            variant='search'
            height='small'
            className='w-61'
            inputClassName='h-8.5'
            value={searchParam}
            onChange={handleSearchChange}
            data-cy='board-search-input'
          />
        </div>
        <div className='ml-2'>
          <Dropdown
            options={sortingOptions}
            values={
              savedSortSetting
                ? [
                    {
                      value: savedSortSetting.value,
                      label: intl.get(
                        `PROJECTS_LIST_PAGE.BOARD.SORTING.${savedSortSetting.value}`
                      ),
                      option: savedSortSetting,
                    },
                  ]
                : undefined
            }
            onChange={handleChangeSorting}
            triggerProps={{
              placeholder: intl.get('PROJECTS_LIST_PAGE.BOARD.SORT_BY'),
              className: 'w-47',
              size: 'sm',
              'data-cy': 'board-sorting-picker',
              getOptionLabel: (opt: Option) =>
                `${intl.get('PROJECTS_LIST_PAGE.BOARD.SORT_BY')} ${opt.label}`,
            }}
          />
        </div>
        <Button
          iconName='filter-circle'
          iconPosition='left'
          size='small'
          variant='tertiary'
          className={classnames('ml-2 py-0', {
            'bg-neutral-white': shouldDisplayFilters,
          })}
          onClick={toggleFilters}
          data-cy='board-filter-button'
        >
          {intl.get('PROJECTS_LIST_PAGE.BOARD.FILTER')}
        </Button>
      </div>
      <AppliedFiltersTags
        filters={appliedFilters}
        onUpdateFilters={handleSetFilters}
        onDisplayAllFilters={toggleFilters}
        className='border-0 px-0'
      />
    </React.Fragment>
  );
};

export default BoardHeader;
