import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Alert } from '@reach/alert';
import { Snackbar } from '@getsynapse/design-system';
import {
  setUpdateHoursStatus,
  selectUpdateHoursStatus,
  setFailedOperation,
  selectFailedOperation,
} from 'state/ActualHours/actualHoursSlice';
import { FailedOperation, UpdateHoursStatus } from 'types/store/actualHours';
import useSnackbarNotifications from 'Pages/TaskDetailPage/hooks/useSnackbarNotifications';

const ActionsNotificationsList = () => {
  const { notifications, showNotification } = useSnackbarNotifications();
  const dispatch = useDispatch();
  const status = useSelector(selectUpdateHoursStatus);
  const failedOperation = useSelector(selectFailedOperation);

  const onCloseNotificationCallback = useCallback(() => {
    dispatch(setFailedOperation(undefined));
    dispatch(setUpdateHoursStatus(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (status === UpdateHoursStatus.Succes) {
      onCloseNotificationCallback();
      showNotification({
        variant: 'success',
        title: '',
        message: intl.get(
          'TASK_ACTUAL_HOURS.UPDATE_ACTUAL_HOURS_SUCCESS_MESSAGE'
        ),
      });
    }
  }, [status, dispatch, showNotification, onCloseNotificationCallback]);

  useEffect(() => {
    if (status === UpdateHoursStatus.Failed) {
      onCloseNotificationCallback();
      showNotification({
        variant: 'error',
        title: intl.get('TASK_ACTUAL_HOURS.UPDATE_ACTUAL_HOURS_ERROR.TITLE'),
        message: intl.get(
          'TASK_ACTUAL_HOURS.UPDATE_ACTUAL_HOURS_ERROR.MESSAGE'
        ),
      });
    }
  }, [status, dispatch, showNotification, onCloseNotificationCallback]);

  useEffect(() => {
    if (
      status === UpdateHoursStatus.Partial &&
      failedOperation === FailedOperation.Add
    ) {
      onCloseNotificationCallback();
      showNotification({
        variant: 'error',
        title: intl.get('TASK_ACTUAL_HOURS.ADD_ACTUAL_HOURS_ERROR.TITLE'),
        message: intl.get('TASK_ACTUAL_HOURS.ADD_ACTUAL_HOURS_ERROR.MESSAGE'),
      });
    }
  }, [
    failedOperation,
    status,
    dispatch,
    showNotification,
    onCloseNotificationCallback,
  ]);

  useEffect(() => {
    if (
      status === UpdateHoursStatus.Partial &&
      failedOperation === FailedOperation.Delete
    ) {
      onCloseNotificationCallback();
      showNotification({
        variant: 'error',
        title: intl.get('TASK_ACTUAL_HOURS.DELETE_ACTUAL_HOURS_ERROR.TITLE'),
        message: intl.get(
          'TASK_ACTUAL_HOURS.DELETE_ACTUAL_HOURS_ERROR.MESSAGE'
        ),
      });
    }
  }, [
    status,
    failedOperation,
    dispatch,
    showNotification,
    onCloseNotificationCallback,
  ]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className='fixed left-6 bottom-12 z-50 flex flex-col gap-y-2'>
      {notifications.map((notification) => (
        <Alert key={notification.id} type='assertive'>
          <Snackbar {...notification} />
        </Alert>
      ))}
    </div>
  );
};

export default ActionsNotificationsList;
