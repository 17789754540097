import { FC } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  tailwindOverride,
  TableRow,
  Typography,
} from '@getsynapse/design-system';
import { Project } from 'types/store/projectsList';
import { PATHS } from 'utils/constants';
import { formatDate } from 'utils/formatters';
import { isProjectCompletionDatePast } from '../../helpers';
import ProjectOwners from '../ProjectOwners/ProjectOwners';
import ProjectStatusTag from '../ProjectStatusTag/ProjectStatusTag';

interface ProjectsTableRowProps {
  project: Project;
  isOdd?: boolean;
}

const ProjectsTableRow: FC<ProjectsTableRowProps> = ({ project, isOdd }) => {
  const history = useHistory();
  const isCompletionDatePast = isProjectCompletionDatePast(
    project.status,
    project.targetCompletionDate
  );
  return (
    <TableRow
      className={tailwindOverride(
        'cursor-pointer',
        isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'
      )}
      key={project.id}
      onClick={() => history.push(`${PATHS.PROJECT_PAGE}/${project.id}`)}
      cells={[
        {
          content: project.displayId,
          className: 'w-32',
        },
        {
          content: (
            <div className='w-72 truncate'>
              <Link
                to={`${PATHS.PROJECT_PAGE}/${project.id}`}
                title={project.name}
              >
                {project.name}
              </Link>
            </div>
          ),
        },
        {
          content: <ProjectStatusTag status={project.status} />,
        },
        {
          content: <ProjectOwners owners={project.owners} />,
        },
        {
          content: formatDate(project.startDate),
        },
        {
          content: (
            <Typography
              variant='label'
              className={tailwindOverride({
                'bg-error-lightest text-error-darker': isCompletionDatePast,
              })}
            >
              {formatDate(project.targetCompletionDate)}
            </Typography>
          ),
        },
        {
          content: formatDate(project.updatedAt),
        },
      ]}
    />
  );
};

export default ProjectsTableRow;
