import { useMemo, useState } from 'react';
import {
  Typography,
  Dropdown,
  Icon,
  tailwindOverride,
} from '@getsynapse/design-system';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import UserAvatar from 'Atoms/UserAvatar';
import { CurrentUser } from 'utils/customTypes';
import { PATHS, PROFILE_CONTEXT_MENU } from 'utils/constants';
import avatarIcon from 'assets/icons/avatar.svg';
import calendarIcon from 'assets/icons/calendar.svg';
import mailIcon from 'assets/icons/mail.svg';
import LanguageModal from 'Organisms/LanguageModal/LanguageModal'; // Adjust import path as needed

type ProfileDropdownProps = {
  user: CurrentUser;
};

type customOption = {
  name: string;
  icon: string;
  userId?: string;
  key?: string;
  onClick?: () => void;
};

const AvatarTrigger = ({ user }: ProfileDropdownProps) => {
  return (
    <>
      <UserAvatar
        size='small'
        user={{
          avatar_url: user.avatar_url,
          data: {
            firstName: user.firstName!,
            lastName: user.lastName!,
          },
        }}
      />
      <Typography variant='body2' className='text-neutral-black'>
        {user.full_name}
      </Typography>
    </>
  );
};

const ProfileDropdown = ({ user }: ProfileDropdownProps) => {
  const {
    timeOff20 = false,
    userEmailNotificationSettings = false,
    frenchTranslation = false,
  } = useFlags();
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false); // State for modal visibility
  const history = useHistory();
  const options = useMemo(() => {
    let timeOffRoute = PATHS.TIME_OFF;
    if (timeOff20) {
      timeOffRoute = PATHS.MANAGE_TIME_OFF;
    }
    if (user.id) {
      return userEmailNotificationSettings
        ? [
            {
              name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[0]}`),
              icon: avatarIcon,
              userId: user.id || '',
              key: PROFILE_CONTEXT_MENU[0],
              route: PATHS.PROFILE,
              onClick: () => history.push(PATHS.PROFILE),
            },
            {
              name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[1]}`),
              icon: mailIcon,
              key: PROFILE_CONTEXT_MENU[1],
              route: PATHS.NOTIFICATIONS,
              onClick: () => history.push(PATHS.NOTIFICATIONS),
            },
            {
              name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[2]}`),
              icon: calendarIcon,
              key: PROFILE_CONTEXT_MENU[2],
              route: timeOffRoute,
              onClick: () => history.push(timeOffRoute),
            },
            frenchTranslation && {
              name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[3]}`), // Add Language option
              icon: calendarIcon, // Use a language icon if available
              key: PROFILE_CONTEXT_MENU[3],
              onClick: () => setIsLanguageModalOpen(true), // Open modal on click
            },
          ].filter(Boolean)
        : [
            {
              name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[0]}`),
              icon: avatarIcon,
              userId: user.id || '',
              key: PROFILE_CONTEXT_MENU[0],
              route: PATHS.PROFILE,
            },
            {
              name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[2]}`),
              icon: calendarIcon,
              key: PROFILE_CONTEXT_MENU[2],
              route: timeOffRoute,
            },
            frenchTranslation && {
              name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[3]}`), // Add Language option
              icon: calendarIcon, // Use a language icon if available
              key: PROFILE_CONTEXT_MENU[3],
              onClick: () => setIsLanguageModalOpen(true), // Open modal on click
            },
          ].filter(Boolean);
    } else {
      return [];
    }
  }, [
    timeOff20,
    user.id,
    userEmailNotificationSettings,
    frenchTranslation,
    history,
  ]);

  return (
    <div>
      <Dropdown
        options={options}
        onChange={(option) => option.onClick?.()} // Execute the onClick function if it exists
        renderTrigger={({ onToggleDropdown }) => (
          <div
            onClick={onToggleDropdown}
            className={tailwindOverride(
              'flex space-x-2 items-center cursor-pointer rounded-sm',
              'hover:bg-neutral-lightest ring-4 ring-transparent',
              'hover:ring-neutral-lightest'
            )}
            data-cy='profile-dropdown_trigger'
          >
            <AvatarTrigger user={user} />
          </div>
        )}
        popperDiffWidth
        listProps={{ className: 'w-44 z-10' }}
        renderOption={(
          option: customOption,
          isSelected: boolean,
          selectOption: () => void,
          { className, ...otherProps }
        ) => (
          <li
            {...otherProps}
            tabIndex={isSelected ? 0 : -1}
            className={tailwindOverride('flex w-full group', className, {
              'hover:bg-neutral-lighter-two': isSelected,
              'hover:shadow-list-item-hover': isSelected,
              'focus-visible:bg-neutral-lighter-two': isSelected,
              'hover:text-neutral-dark': isSelected,
            })}
            onClick={() => {
              selectOption();
              option.onClick?.(); // Trigger the onClick function if it exists
            }}
            data-cy={`profile-dropdown_option-${option.key}`}
          >
            <div className='flex items-center'>
              <Icon src={option.icon} className='text-lg' />
              <span className='ml-2.5 text-primary text-sm leading-6'>
                {option.name}
              </span>
            </div>
          </li>
        )}
      />

      {/* Render the LanguageModal if isLanguageModalOpen is true */}
      {frenchTranslation && isLanguageModalOpen && (
        <LanguageModal
          isOpen={isLanguageModalOpen} // Pass the isOpen prop here
          onClose={() => setIsLanguageModalOpen(false)} // Close modal function
        />
      )}
    </div>
  );
};

export default ProfileDropdown;
