import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestFilters } from 'utils/types/filters';
import RequestsTable from 'Organisms/RequestsTable/RequestsTable';
import RequestsTableFooter from 'Organisms/RequestsTable/RequestsTableFooter';
import RequestsTableOperationHeader from 'Organisms/RequestsTable/RequestsTableOperationHeader';
import { REQUESTS_TABLE } from 'utils/constants';
import { ACTIVE_REQUEST_STATUSES } from 'utils/constants/request';
import { requestsSelectors } from 'state/normalized/requests/requestsSlice';
import useRequestsTable from 'Hooks/Requests/useRequestsTable';
import ActiveRequestsSidePanelFilters from './ActiveRequestsSidePanelFilters';
import {
  selectFiltersSettingsByType,
  createFilterSetting,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { setFilters } from 'state/Requests/requestSlice';
import { RequestStatus } from 'utils/types/request';

const ActiveRequestsTable = () => {
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleFilters = () => setShowFilters((prev) => !prev);
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(REQUESTS_TABLE.ACTIVE_REQUESTS)
  );
  const appliedFilters = useMemo(
    () =>
      filtersSettings
        ? (filtersSettings.settings as RequestFilters)
        : ({} as RequestFilters),
    [filtersSettings]
  );

  const { requests, loading, canFetchMore, onFetchMore, searchRequests } =
    useRequestsTable({
      statuses:
        (appliedFilters.status as readonly RequestStatus[]) ||
        ACTIVE_REQUEST_STATUSES,
    });
  const totalCount = useSelector(requestsSelectors.selectTotalRequests);
  const loadedCount = useSelector(requestsSelectors.selectLoadedRequests);

  useEffect(() => {
    if (appliedFilters) {
      dispatch(setFilters(appliedFilters, REQUESTS_TABLE.ACTIVE_REQUESTS));
    }
  }, [appliedFilters, dispatch]);

  const handleSaveFiltersSettings = (filters: RequestFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: REQUESTS_TABLE.ACTIVE_REQUESTS,
          filter_settings: filters,
        })
      );
    }
  };

  const handleUpdateFilters = (filters: RequestFilters) => {
    dispatch(setFilters(filters, REQUESTS_TABLE.ACTIVE_REQUESTS));
    handleSaveFiltersSettings(filters);
  };
  return (
    <>
      <ActiveRequestsSidePanelFilters
        filters={appliedFilters}
        onClose={toggleFilters}
        open={showFilters}
        onUpdateFilters={handleUpdateFilters}
      />
      <RequestsTableOperationHeader
        handleUpdateFilters={handleUpdateFilters}
        appliedFilters={appliedFilters}
        onSearchChange={searchRequests}
        showFilters={showFilters}
        toggleFilters={toggleFilters}
      />
      <RequestsTable
        requests={requests}
        loading={loading}
        canFetchMore={canFetchMore}
        onFetchMore={onFetchMore}
      />
      <RequestsTableFooter total={totalCount} loaded={loadedCount} />
    </>
  );
};

export default ActiveRequestsTable;
