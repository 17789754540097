import { combineReducers } from '@reduxjs/toolkit';
import requests from './requests/requestsSlice';
import userSummaries from './userSummaries/userSummariesSlice';
import formSummaries from './formSummaries/formSummariesSlice';
import projectSummaries from './projectSummaries/projectSummariesSlice';
import projectPermissions from './projectPermissions/projectPermissionsSlice';
import businessUnitSummaries from './businessUnitSummaries/businessUnitSummariesSlice';

export default combineReducers({
  requests,
  userSummaries,
  formSummaries,
  projectSummaries,
  projectPermissions,
  businessUnitSummaries,
});
