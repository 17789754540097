import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { StrategyGoal } from 'utils/types/strategyGoals';

class SingleStrategyGoalAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/strategy/goals`);
  }

  fetchStrategyGoalDetail = async (goalId: string) => {
    const res = await this.instance.get(`/${goalId}`);
    return res;
  };

  deleteGoal = async (goalId: string) => {
    const res = await this.instance.delete(`/${goalId}`);
    return res;
  };

  updateGoal = async (goalId: string, data: Partial<StrategyGoal>) => {
    const res = await this.instance.put(`/${goalId}`, data);
    return res.data;
  };
}

const singleStrategyGoalAPI = new SingleStrategyGoalAPI();

export default singleStrategyGoalAPI;
