import React from 'react';
import intl from 'react-intl-universal';
import { FormItem } from '@getsynapse/design-system';
import { REQUEST_TABLE_FILTERS } from 'utils/constants';
import { Option, rangeDateType } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';
import {
  RangeFilter,
  RequestFilters,
  RequestFiltersKey,
} from 'utils/types/filters';
import { isArrayOfOptions, isDateRangeObject } from 'utils/typeGuards';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import useRequestFilters from 'Pages/RequestsListPage/hooks/useRequestFilters';

const ActiveRequestsFiltersForm: React.FC<{
  filters: RequestFilters;
  onUpdateFilters: (newFilters: RequestFilters) => void;
}> = ({ filters, onUpdateFilters }) => {
  const { getFilterOptionsByKey } = useRequestFilters();
  const statusOptions = getFilterOptionsByKey(REQUEST_TABLE_FILTERS.STATUS);

  const isDateRangeFilterEmpty = (filter: RangeFilter) =>
    filter.from === null && filter.to === null;

  const updateFilters = (
    filterKey: RequestFiltersKey,
    value: Option[] | rangeDateType | string,
    rangeFilterKey: 'from' | 'to' | null = null
  ) => {
    let filtersCopy = { ...filters } as RequestFilters;
    if (isArrayOfOptions(value)) {
      if (value.length === 0) {
        delete filtersCopy[filterKey];
      } else {
        filtersCopy[filterKey] = value.map((option) => option.value);
      }
    } else if (isDateRangeObject(value)) {
      const filterValue = value as rangeDateType;
      filtersCopy[filterKey] = {
        ...(filtersCopy[filterKey] as RangeFilter),
        [rangeFilterKey as string]: filterValue.startDate
          ? filterValue.startDate.toString()
          : null,
      };
      if (isDateRangeFilterEmpty(filtersCopy[filterKey] as RangeFilter)) {
        delete filtersCopy[filterKey];
      }
    }
    onUpdateFilters(filtersCopy);
  };

  return (
    <div className='mt-8 mb-12 flex flex-col space-y-5'>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS')}>
        <MultiSelectDropdown
          options={statusOptions}
          values={getInitialValueForDropDown(
            statusOptions,
            filters[REQUEST_TABLE_FILTERS.STATUS] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(REQUEST_TABLE_FILTERS.STATUS, options)
          }
          triggerProps={{
            'aria-label': intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS'),
            'data-cy': 'requests-filters__status-picker',
          }}
          listProps={{ 'data-cy': 'requests-filters__status__options-list' }}
          placeholder={intl.get('SELECT')}
        />
      </FormItem>
      <FormItem
        label={intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_CREATOR')}
      ></FormItem>
      <FormItem
        label={intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_OWNER')}
      ></FormItem>
      <FormItem
        label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.LINKED_PROJECTS')}
      ></FormItem>
      <FormItem
        label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.BUSINESS_UNIT')}
      ></FormItem>
    </div>
  );
};

export default ActiveRequestsFiltersForm;
