import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Status, SortingType } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { GoalProject } from 'utils/types/strategyGoals';
import { RootState } from 'state/store';
import goalProjectsAPI from './GoalProjectsAPI';
import { getProjectSummary } from './helpers';
interface GoalProjectsState {
  goalLinkedProjects: {
    status: Status;
    list: GoalProject[];
  };
}

/* ============================= INITIAL STATE ============================== */

const initialState: GoalProjectsState = {
  goalLinkedProjects: {
    status: SLICE_STATUS.IDLE,
    list: [],
  },
};

/* ============================= ACTIONS ============================== */

/* ============================== REDUX THUNK =============================== */
export const fetchGoalProjects = createAsyncThunk(
  'goalProjects/FETCH_GOAL_PROJECTS',
  async ({
    goalId,
    orderByParam,
    order,
  }: {
    goalId: string;
    orderByParam?: string;
    order?: SortingType;
  }) => {
    const response = await goalProjectsAPI.fetchGoalProjects(
      goalId,
      orderByParam,
      order
    );
    return response?.data;
  }
);
/* ================================= REDUCER ================================ */
const projectGoalsSlice = createSlice({
  name: 'goalProjects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoalProjects.pending, (state) => {
        state.goalLinkedProjects.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchGoalProjects.rejected, (state) => {
        state.goalLinkedProjects.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchGoalProjects.fulfilled, (state, action) => {
        state.goalLinkedProjects.list = action.payload;
        state.goalLinkedProjects.status = SLICE_STATUS.IDLE;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectGoalProjects = (state: RootState) =>
  state.goalProjects.goalLinkedProjects.list;

export const selectFormattedGoalProjectsMetrics = createSelector(
  selectGoalProjects,
  (goalProjects) => {
    const metrics = getProjectSummary(goalProjects);
    return metrics;
  }
);

export const selectFormattedGoalProjects = createSelector(
  selectGoalProjects,
  (goalProjects) =>
    goalProjects.map((project: any) => ({
      ...project,
      owners: project.owners.map((owner: any) => ({
        data: {
          firstName: owner.firstName,
          lastName: owner.lastName,
          email: owner.email,
        },
        avatar_url: owner.avatarUrl,
        status: owner.status,
      })),
    }))
);

export const selectGoalProjectsStatus = (state: RootState) =>
  state.goalProjects.goalLinkedProjects.status;

export default projectGoalsSlice.reducer;
