import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { Typography } from '@getsynapse/design-system';
import { DATE } from 'utils/constants';
import cognotaDotted from 'assets/images/cognota-dotted.svg';
import { getCurrentLocale } from 'utils/localeHelper';

const ArchivedBanner: React.FC<{ archivedDate: string }> = ({
  archivedDate,
}) => {
  const currentLocale = getCurrentLocale();
  return (
    <div
      data-cy='archived-project-banner'
      className='h-16 bg-neutral-lightest py-4 px-6 flex items-center'
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${cognotaDotted})`,
        backgroundPosition: '14rem center',
      }}
    >
      <Typography variant='h5'>
        {intl.get('PROJECT_DETAIL.ARCHIVE.PROJECT_ARCHIVED_ON', {
          archivedDate: moment(archivedDate)
            .locale(currentLocale)
            .format(DATE.SHORT_FORMAT),
        })}
      </Typography>
    </div>
  );
};

export default ArchivedBanner;
