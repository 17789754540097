import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState, ThunkApi } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { ProjectSummary } from 'types/store/normalized';

/* ============================== STATE SETUP =============================== */
interface ProjectSummariesState {
  status: Status;
}

const initialState: ProjectSummariesState = {
  status: SLICE_STATUS.IDLE,
};

const adapter = createEntityAdapter<ProjectSummary>();

/* ============================== REDUX THUNK =============================== */
function concurrencyCondition(
  thunkParameters: unknown,
  { getState }: { getState: () => RootState }
): boolean {
  const { status } = getState().normalized.projectSummaries;
  return status !== SLICE_STATUS.LOADING;
}

interface GetListParams {
  projectIds?: string[];
}

interface GetProjectSummariesResponse {
  data: ProjectSummary[];
  totalCount: number;
  links: { next: string };
  cursor: string;
}

const NO_OP_RESPONSE: GetProjectSummariesResponse = {
  data: [],
  totalCount: 0,
  cursor: '',
  links: { next: '' },
};

export const getProjectSummaries = createAsyncThunk<
  GetProjectSummariesResponse,
  GetListParams,
  ThunkApi
>(
  'normalized/projectSummary/getProjectSummaries',
  async (
    { projectIds = [] },
    { getState, extra: { getProjectSummariesApi } }
  ) => {
    const state = getState();
    const uniqueProjects = new Set(projectIds);
    const notCached = Array.from(uniqueProjects).filter(
      (id) => !state.normalized.projectSummaries.entities[id]
    );

    if (notCached.length === 0) {
      return NO_OP_RESPONSE;
    }

    const { data, meta, links } = await getProjectSummariesApi().getList({
      params: {
        projectIds: notCached,
      },
    });
    return { data, totalCount: meta.totalCount, cursor: meta.cursor, links };
  },
  { condition: concurrencyCondition }
);

/* ================================= REDUCER ================================ */
const projectSummariesSlice = createSlice({
  name: 'normalized/projectSummary',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectSummaries.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getProjectSummaries.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getProjectSummaries.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);

        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelectors = adapter.getSelectors<RootState>(
  (state) => state.normalized.projectSummaries
);

const selectStatus = (state: RootState) =>
  state.normalized.projectSummaries.status;

const selectProjectSummariesByIds = (state: RootState) => (ids: string[]) =>
  ids
    .map((id) => defaultSelectors.selectById(state, id))
    .filter((summary): summary is ProjectSummary => summary !== undefined);

export const projectSummarySelectors = {
  ...defaultSelectors,
  selectStatus,
  selectProjectSummariesByIds,
};

export default projectSummariesSlice.reducer;
