import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState, ThunkApi } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { ProjectPermissions } from 'types/store/normalized';
import { Deserializer } from 'jsonapi-serializer';

/* ============================== STATE SETUP =============================== */
interface ProjectPermissionsState {
  status: Status;
}

const initialState: ProjectPermissionsState = {
  status: SLICE_STATUS.IDLE,
};

const adapter = createEntityAdapter<ProjectPermissions>();

/* ============================== REDUX THUNK =============================== */
function concurrencyCondition(
  thunkParameters: unknown,
  { getState }: { getState: () => RootState }
): boolean {
  const { status } = getState().normalized.projectPermissions;
  return status !== SLICE_STATUS.LOADING;
}

interface GetListParams {
  projectIds?: string[];
}

const NO_OP_RESPONSE: ProjectPermissions[] = [];

export const getProjectPermissions = createAsyncThunk<
  ProjectPermissions[],
  GetListParams,
  ThunkApi
>(
  'normalized/projectPermissions/getProjectPermissions',
  async (
    { projectIds = [] },
    { getState, extra: { getProjectPermissionsApi } }
  ) => {
    const state = getState();
    const uniqueProjects = new Set(projectIds);
    const notCached = Array.from(uniqueProjects).filter(
      (id) => !state.normalized.projectPermissions.entities[id]
    );

    if (notCached.length === 0) {
      return NO_OP_RESPONSE;
    }

    const { data } = await getProjectPermissionsApi().getList({
      params: {
        projectIds: notCached,
      },
    });
    const deserializer = new Deserializer({
      keyForAttribute: 'camelCase',
    });
    const result: ProjectPermissions[] = await deserializer.deserialize({
      data,
    });

    return result;
  },
  { condition: concurrencyCondition }
);

/* ================================= REDUCER ================================ */
const projectPermissionsSlice = createSlice({
  name: 'normalized/projectPermissions',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectPermissions.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getProjectPermissions.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getProjectPermissions.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload);

        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelectors = adapter.getSelectors<RootState>(
  (state) => state.normalized.projectPermissions
);

const selectStatus = (state: RootState) =>
  state.normalized.projectPermissions.status;

const selectProjectPermissionsByIds = (state: RootState) => (ids: string[]) =>
  ids
    .map((id) => defaultSelectors.selectById(state, id))
    .filter(
      (permissions): permissions is ProjectPermissions =>
        permissions !== undefined
    );

export const projectPermissionsSelectors = {
  ...defaultSelectors,
  selectStatus,
  selectProjectPermissionsByIds,
};

export default projectPermissionsSlice.reducer;
