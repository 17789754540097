import { FC, useMemo, useState, useCallback } from 'react';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import { TableOperationHeader } from '@getsynapse/design-system';

interface ProjectsTableOperationHeaderProps {
  onSearchChange: (value: string) => void;
}

const ProjectsTableOperationHeader: FC<ProjectsTableOperationHeaderProps> = ({
  onSearchChange,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchChange = useMemo(() => {
    return debounce((value: string) => {
      onSearchChange(value);
    }, 500);
  }, [onSearchChange]);

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      debouncedSearchChange(value);
    },
    [debouncedSearchChange]
  );

  return (
    <TableOperationHeader
      searchProps={{
        placeholder: intl.get('SEARCH_PLACEHOLDER'),
      }}
      inputValue={searchValue}
      setInputValue={handleSearchChange}
    />
  );
};

export default ProjectsTableOperationHeader;
