import { FC, useState } from 'react';
import {
  Table,
  TableOperationHeader,
  tailwindOverride,
} from '@getsynapse/design-system';
import { TimeOffEntry } from 'types/store/manageTimeOff';
import { TimeOffEntriesTableSorting } from 'types/store/manageTimeOff';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import TableFooter from './TableFooter/TableFooter';
import TimeOffSidePanelFilters from '../TimeOffFilters/TimeOffSidePanelFilters';
import AppliedFiltersTags from './AppliedFiltersTags/AppliedFiltersTags';
import intl from 'react-intl-universal';
export interface TimeOffTableListProps {
  id: string;
  timeOffEntries: TimeOffEntry[];
  sorting: TimeOffEntriesTableSorting;
  handleSort: (sortBy: string, order: string) => void;
  isFilterPanelOpen: boolean;
  savedFilters: TimeOffEntryFilters;
  onUpdateFilters: (filters: any) => void;
  onCloseFilterPanel: () => void;
  toggleFilterPanel: () => void;
  minFilterDate?: string;
  maxFilterDate?: string;
  canFetchMore?: boolean;
  fetchMoreTimeOffEntries: () => void;
  totalCount?: number;
}

const TimeOffTableList: FC<TimeOffTableListProps> = ({
  timeOffEntries,
  id,
  handleSort,
  sorting,
  isFilterPanelOpen,
  savedFilters,
  onUpdateFilters,
  onCloseFilterPanel,
  toggleFilterPanel,
  minFilterDate,
  maxFilterDate,
  canFetchMore = false,
  fetchMoreTimeOffEntries,
  totalCount,
}) => {
  const [rootId, setRootId] = useState<Element | null>(null);
  return (
    <div>
      <div className='px-px'>
        <TimeOffSidePanelFilters
          open={isFilterPanelOpen}
          savedFilters={savedFilters}
          onUpdateFilters={onUpdateFilters}
          onClosePanel={onCloseFilterPanel}
          minFilterDate={minFilterDate}
          maxFilterDate={maxFilterDate}
        />
        <TableOperationHeader
          searchProps={{
            placeholder: intl.get('SEARCH_PLACEHOLDER'),
          }}
          hideSearch
          className='border-b-0 shadow-none'
          actionButtons={
            <FilterButton
              aria-pressed={isFilterPanelOpen}
              disabled={false}
              onToggleFilter={toggleFilterPanel}
            />
          }
        />
        <AppliedFiltersTags
          savedFilters={savedFilters}
          onUpdateFilters={onUpdateFilters}
        />
      </div>
      <div
        ref={(el) => setRootId(el)}
        className={tailwindOverride(
          'w-full max-h-time-off-table pb-6',
          'rounded-b border-neutral-lighter-two',
          'relative overflow-y-auto',
          'layout-cell layout-scrollbar'
        )}
      >
        <Table
          canSelectRows={false}
          data={{
            headData: {
              headCells: [],
            },
            rows: [],
          }}
          className='w-full'
          aria-label={id}
        >
          <TableHeader
            sortBy={sorting.sortBy}
            order={sorting.order}
            handleSort={handleSort}
          />
          <TableBody
            timeOffEntries={timeOffEntries}
            rootId={rootId}
            canFetchMore={canFetchMore}
            fetchMoreTimeOffEntries={fetchMoreTimeOffEntries}
            savedFilters={savedFilters}
          />
        </Table>
      </div>
      <TableFooter total={totalCount || timeOffEntries.length} />
    </div>
  );
};

export default TimeOffTableList;
