import PageTitle from 'Molecules/PageTitle/PageTitle';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import Divider from 'Atoms/Divider';
import { NotificationSettingGroup } from './components/NotificationSettingGroup';
import useNotificationSettings from './hooks/useNotificationSettings';

function SettingsHeader() {
  return (
    <>
      <div className='w-full grid grid-cols-2'>
        <Typography weight='medium' className='text-sm text-neutral-dark'>
          {intl.get(`NOTIFICATIONS_SETTINGS.HEADER_CATEGORIES`)}:
        </Typography>
        <Typography
          weight='medium'
          className='text-right mr-8 text-sm text-neutral-dark'
        >
          {intl.get(`NOTIFICATIONS_SETTINGS.HEADER_NOTIFICATION_METHOD_EMAIL`)}
        </Typography>
      </div>
      <Divider />
    </>
  );
}

export function NotificationsSettingsPage() {
  const { notificationSettings, handleSettingChange } =
    useNotificationSettings();

  return (
    <div className='h-full flex flex-col' data-cy='notifications-settings-page'>
      <PageTitle
        titleComponent={intl
          .get('NOTIFICATIONS_SETTINGS.TITLE')
          .defaultMessage('Notifications Settings')}
        dataCy='notifications-settings-page-title'
      />

      <DetailsPage
        dataCy='notifications-settings-page-details'
        className='flex flex-col'
      >
        <Typography
          variant='h4'
          data-cy='notifications-settings-page-description'
          className='pb-5'
        >
          {intl
            .get('NOTIFICATIONS_SETTINGS.DESCRIPTION')
            .defaultMessage('Manage Notifications')}
        </Typography>
        <div className='w-720px px-3 py-5 rounded-t-lg border border-neutral-lighter-two flex-grow flex flex-col space-y-5'>
          <SettingsHeader />

          {notificationSettings &&
            notificationSettings.map((setting, i) => (
              <span key={setting.category}>
                <NotificationSettingGroup
                  setting={setting}
                  onToggle={handleSettingChange}
                  key={setting.category}
                />
                {i !== notificationSettings.length - 1 && <Divider />}
              </span>
            ))}
        </div>
      </DetailsPage>
    </div>
  );
}
