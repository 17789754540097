import { Deserializer, Serializer } from 'jsonapi-serializer';
import { JsonApiResponse } from 'types/store/jsonApi';
import type {
  Project,
  ProjectOwner,
  ProjectWithOwner,
  FetchProjectsListParams,
} from 'types/store/projectsList';
import {
  BASE_URL,
  ACTIVE_PROJECTS_FIELD_PARAM,
} from 'utils/constants/projectsList';

interface DeserializedData extends Omit<Project, 'owners'> {
  owners?: ProjectOwner[];
}

interface NormalizedData {
  projects: Project[];
  projectOwners: ProjectOwner[];
}

export const deserialize: (
  jsonApiResponse: JsonApiResponse<Project>
) => Promise<ProjectWithOwner[]> = async (jsonApiResponse) => {
  const deserializer = new Deserializer({
    keyForAttribute: 'camelCase',
    transform: (record: JsonApiResponse<Project>) => {
      delete record['links'];
      delete record['meta'];
      return record;
    },
  });
  return await deserializer.deserialize(jsonApiResponse);
};

export const serialize: (
  data: ProjectWithOwner[],
  links?: Record<string, string>,
  meta?: Record<string, string | number>
) => Promise<JsonApiResponse<Project>> = async (
  data,
  links = {},
  meta = {}
) => {
  const serializer = new Serializer('projects', {
    attributes: [
      'id',
      'organizationId',
      'displayId',
      'name',
      'status',
      'priority',
      'owners',
      'health',
      'teams',
      'category',
      'startDate',
      'targetCompletionDate',
      'resourcingType',
      'process',
      'stage',
    ],
    owners: {
      ref: 'id',
      included: true,
      attributes: ['id', 'name', 'email', 'status', 'avatarUrl'],
    },
    topLevelLinks: links,
    meta,
  });
  return await serializer.serialize(data);
};

export const normalize: (data: DeserializedData[]) => NormalizedData = (
  data
) => {
  const projects = new Map<string, Project>();
  const projectOwners = new Map<string, ProjectOwner>();
  for (const item of data) {
    const { owners = [], ...props } = item;
    projects.set(item.id, { ...props, owners: owners.map((o) => o.id) });
    for (const owner of owners) {
      projectOwners.set(owner.id, owner);
    }
  }
  return {
    projects: Array.from(projects.values()),
    projectOwners: Array.from(projectOwners.values()),
  };
};

export const buildJsonApiUrl: (params: FetchProjectsListParams) => string = (
  params
) => {
  const searchParams = new URLSearchParams();
  if (params.filter) {
    Object.entries(params.filter).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((value) => {
          searchParams.append(`filter[${key}][]`, value);
        });
      } else {
        if (values) {
          searchParams.append(`filter[${key}]`, values as string);
        }
      }
    });
  }

  if (params.search) {
    searchParams.append('filter[name]', params.search);
    searchParams.append('filter[displayId]', params.search);
  }

  if (params.page) {
    searchParams.append('page[limit]', params.page.limit.toString());
    if (params.page.cursor) {
      searchParams.append('page[cursor]', params.page.cursor);
    }
  } else {
    searchParams.append('page[limit]', '100');
  }

  if (params.sort) {
    searchParams.append('sort', params.sort);
  } else {
    searchParams.append('sort', '-updatedAt');
  }

  if (params.field) {
    searchParams.append('field[project]', params.field);
  } else {
    searchParams.append('field[project]', ACTIVE_PROJECTS_FIELD_PARAM);
  }

  if (params.include) {
    searchParams.append('include', params.include);
  } else {
    searchParams.append('include', 'owners');
  }

  return `${BASE_URL}?${searchParams.toString()}`;
};
