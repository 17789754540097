import React, { useCallback, useState, useEffect } from 'react';
import { SidePanelFilter } from '@getsynapse/design-system';
import FiltersForm from './TimeOffFiltersForm';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';
import intl from 'react-intl-universal';

interface TimeOffSidePanelFiltersProps {
  open: boolean;
  onUpdateFilters: (filters: TimeOffEntryFilters) => void;
  onClosePanel: () => void;
  savedFilters: TimeOffEntryFilters;
  minFilterDate?: string;
  maxFilterDate?: string;
}

const TimeOffSidePanelFilters: React.FC<TimeOffSidePanelFiltersProps> = ({
  open,
  onUpdateFilters,
  onClosePanel,
  savedFilters,
  minFilterDate,
  maxFilterDate,
}) => {
  const [appliedFilters, setAppliedFilters] = useState<
    TimeOffEntryFilters | {}
  >({});

  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);

  const setInitialFilters = useCallback(() => {
    if (savedFilters) {
      setAppliedFilters(savedFilters);
    }
  }, [savedFilters]);

  useEffect(() => {
    setInitialFilters();
  }, [setInitialFilters]);

  const handleUpdateFilters = (savedFilters: TimeOffEntryFilters) => {
    setAppliedFilters({ ...appliedFilters, ...savedFilters });
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClosePanel = useCallback(() => {
    setInitialFilters();
    setCanApplyFilters(false);
    onClosePanel();
  }, [onClosePanel, setInitialFilters]);

  const handleClearAllFilters = () => {
    onUpdateFilters({});
    setAppliedFilters({});
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={handleClosePanel}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='time-off-filters-side-panel'
      applyButtonLabel={intl.get('FILTER_PANEL.APPLY')}
      clearAllButtonLabel={intl.get('FILTER_PANEL.CLEAR_ALL')}
    >
      {
        <FiltersForm
          appliedFilters={appliedFilters}
          onUpdateFilters={handleUpdateFilters}
          setCanApplyFilters={setCanApplyFilters}
          minFilterDate={minFilterDate}
          maxFilterDate={maxFilterDate}
        />
      }
    </SidePanelFilter>
  );
};

export default TimeOffSidePanelFilters;
