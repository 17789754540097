import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectTotalProjectsCount,
  selectLoadedProjectsCount,
} from 'state/ProjectsList/projectsListSlice';
import useProjectsList from 'Pages/ProjectsListPage/hooks/useProjectsList';
import ProjectsTableOperationHeader from './components/ProjectsTableOperationHeader/ProjectsTableOperationHeader';
import ProjectsTable from './components/ProjectsTable';
import ProjectsTableFooter from './components/ProjectsTableFooter';

const ActiveProjects = () => {
  const {
    isLoading,
    fetchActiveProjects,
    projects,
    canFetchMoreProjects,
    fetchMoreProjects,
    searchProjects,
    handleSort,
  } = useProjectsList();

  useEffect(() => {
    fetchActiveProjects();
  }, [fetchActiveProjects]);

  const totalCount = useSelector(selectTotalProjectsCount);
  const loadedCount = useSelector(selectLoadedProjectsCount);

  return (
    <>
      <ProjectsTableOperationHeader onSearchChange={searchProjects} />
      <ProjectsTable
        tableName='active-projects'
        projects={projects}
        canFetchMoreProjects={canFetchMoreProjects}
        fetchMoreProjects={fetchMoreProjects}
        isLoading={isLoading}
        handleSort={handleSort}
      />
      <ProjectsTableFooter total={totalCount} loaded={loadedCount} />
    </>
  );
};

export default ActiveProjects;
