import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState, ThunkApi } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { FormSummary } from 'types/store/normalized';

/* ============================== STATE SETUP =============================== */
interface FormSummariesState {
  status: Status;
}

const initialState: FormSummariesState = {
  status: SLICE_STATUS.IDLE,
};

const adapter = createEntityAdapter<FormSummary>();

/* ============================== REDUX THUNK =============================== */
function concurrencyCondition(
  thunkParameters: unknown,
  { getState }: { getState: () => RootState }
): boolean {
  const { status } = getState().normalized.formSummaries;
  return status !== SLICE_STATUS.LOADING;
}

interface GetListParams {
  formIds?: string[];
}

interface GetFormSummariesResponse {
  data: FormSummary[];
  totalCount: number;
  links: { next: string };
  cursor: string;
}

const NO_OP_RESPONSE: GetFormSummariesResponse = {
  data: [],
  totalCount: 0,
  cursor: '',
  links: { next: '' },
};

export const getFormSummaries = createAsyncThunk<
  GetFormSummariesResponse,
  GetListParams,
  ThunkApi
>(
  'normalized/formSummary/getFormSummaries',
  async ({ formIds = [] }, { getState, extra: { getFormSummariesApi } }) => {
    const state = getState();
    const uniqueForms = new Set(formIds);
    const notCached = Array.from(uniqueForms).filter(
      (id) => !state.normalized.formSummaries.entities[id]
    );

    if (notCached.length === 0) {
      return NO_OP_RESPONSE;
    }

    const { data, meta, links } = await getFormSummariesApi().getList({
      params: {
        formIds: notCached,
      },
    });
    return { data, totalCount: meta.totalCount, cursor: meta.cursor, links };
  },
  { condition: concurrencyCondition }
);

/* ================================= REDUCER ================================ */
const formSummariesSlice = createSlice({
  name: 'normalized/formSummary',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFormSummaries.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getFormSummaries.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getFormSummaries.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelectors = adapter.getSelectors<RootState>(
  (state) => state.normalized.formSummaries
);

const selectStatus = (state: RootState) =>
  state.normalized.formSummaries.status;

const selectFormSummaryById = (state: RootState) => (id: string) =>
  defaultSelectors.selectById(state, id);

export const formSummarySelectors = {
  ...defaultSelectors,
  selectStatus,
  selectFormSummaryById,
};

export default formSummariesSlice.reducer;
