import config from 'config/Config';
import BaseApi from './BaseApi';
import { ProjectPermissions } from 'types/store/normalized';
import { JsonApiResponse } from 'types/store/jsonApi';

export class ProjectPermissionsApi extends BaseApi<
  JsonApiResponse<ProjectPermissions>
> {
  constructor() {
    super(`${config.get('backendURL')}project-permissions`);
  }
}
