import { TableOperationHeader } from '@getsynapse/design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTaskBundleTableSearch,
  setTaskBundleTableSearch,
} from 'state/TasksBundle/tasksBundleSlice';
import intl from 'react-intl-universal';

const TaskBundleTableFilters = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector(selectTaskBundleTableSearch);

  const handleSearch = (value: string) => {
    dispatch(setTaskBundleTableSearch(value));
  };

  return (
    <div>
      <TableOperationHeader
        className='border-b-0 bg-neutral-white'
        inputValue={searchValue}
        setInputValue={handleSearch}
        filterComponent={[]}
        searchProps={{
          placeholder: intl.get('SEARCH_PLACEHOLDER'),
        }}
      />
    </div>
  );
};

export default TaskBundleTableFilters;
