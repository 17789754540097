import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import moment from 'moment';
import { InsightsCapacityActiveFilter } from 'utils/customTypes';

class CapacityAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(`${config.get('insightsBackendURL')}capacity`);
  }

  getCapacityAggregates = async ({
    startDate = '',
    endDate = '',
    businessUnitIds = [],
  }: InsightsCapacityActiveFilter) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }

    const { data } = await this.instance.get('/aggregates', {
      params: {
        startDate,
        endDate,
        businessUnitIds,
      },
    });
    return data;
  };

  getDCATrends = async ({
    startDate = '',
    endDate = '',
    businessUnitIds = [],
  }: InsightsCapacityActiveFilter) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }

    const { data } = await this.instance.get('/dca_trend', {
      params: {
        startDate,
        endDate,
        businessUnitIds,
      },
    });
    return data;
  };

  getTeamsUtilization = async ({
    startDate = '',
    endDate = '',
    businessUnitIds = [],
  }: InsightsCapacityActiveFilter) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }

    const { data } = await this.instance.get('/teams_utilization', {
      params: {
        startDate,
        endDate,
        businessUnitIds,
      },
    });
    return data;
  };

  getDemandByProject = async ({
    startDate = '',
    endDate = '',
    businessUnitIds = [],
  }: InsightsCapacityActiveFilter) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }

    const { data } = await this.instance.get('/project_category_demand', {
      params: {
        startDate,
        endDate,
        businessUnitIds,
      },
    });
    return data;
  };

  getBusinessTeamsDemand = async ({
    startDate = '',
    endDate = '',
    businessUnitIds = [],
  }: InsightsCapacityActiveFilter) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }
    const { data } = await this.instance.get('/business_team_demand', {
      params: {
        startDate,
        endDate,
        businessUnitIds,
      },
    });
    return data;
  };

  getAllocationByResourceType = async ({
    startDate = '',
    endDate = '',
    businessUnitIds = [],
  }: InsightsCapacityActiveFilter) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }
    const { data } = await this.instance.get('/resource_type_allocation', {
      params: {
        startDate,
        endDate,
        businessUnitIds,
      },
    });
    return data;
  };

  getTaskTypeEstimation = async ({
    startDate = '',
    endDate = '',
    businessUnitIds = [],
  }: InsightsCapacityActiveFilter) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }
    const { data } = await this.instance.get('/task_type_estimation', {
      params: {
        startDate,
        endDate,
        businessUnitIds,
      },
    });
    return data;
  };

  getUtilizationByResources = async (
    { startDate = '', endDate = '' },
    sort: string
  ) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      startDate = moment()
        .subtract(1, 'year')
        .startOf('month')
        .format('YYYY-MM-DD');
      endDate = moment().endOf('month').format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
    }
    const { data } = await this.instance.get('/resource_utilization', {
      params: {
        startDate,
        endDate,
        sort,
      },
    });
    return data;
  };
}

const capacityAPI = new CapacityAPI();

export default capacityAPI;
