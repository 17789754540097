import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TableOperationHeader } from '@getsynapse/design-system';
import debounce from 'lodash/debounce';
import { setFilters, setProgramsSearch } from 'state/Programs/programsSlice';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import ProgramsSidePanelFilters from './ProgramsSidePanelFilters/ProgramsSidePanelFilters';
import AppliedFiltersTags from 'Organisms/AppliedFiltersTags/AppliedFiltersTags';
import { ProgramFilters } from 'utils/types/filters';
import useProgramFilters from '../hooks/useProgramFilters';
import intl from 'react-intl-universal';

interface ProgramsTableHeaderProps {
  savedFilters: ProgramFilters;
  saveFilters: (updatedFilters: ProgramFilters) => void;
}

const ProgramsTableHeader: React.FC<ProgramsTableHeaderProps> = ({
  savedFilters,
  saveFilters,
}) => {
  const dispatch = useDispatch();
  const { getOptionsbyKey } = useProgramFilters();

  const debouncedSearchHandler = debounce((value: string) => {
    dispatch(setProgramsSearch(value));
  }, 500);

  const [showFilterComponent, setShowFilterComponent] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const handleToggleFilterComponent = () => {
    setShowFilterComponent((prevState) => !prevState);
  };

  const handleUpdateFilters = (updatedFilters: ProgramFilters) => {
    dispatch(setFilters(updatedFilters));
    saveFilters(updatedFilters);
  };

  const handleSearh = useCallback(
    (value: string) => {
      setSearchValue(value);
      debouncedSearchHandler(value);
    },
    [debouncedSearchHandler]
  );

  return (
    <>
      <ProgramsSidePanelFilters
        savedFilters={savedFilters}
        onClose={handleToggleFilterComponent}
        open={showFilterComponent}
        onUpdateFilters={handleUpdateFilters}
      />
      <TableOperationHeader
        searchProps={{
          placeholder: intl.get('SEARCH_PLACEHOLDER'),
        }}
        className='border-b-0 bg-neutral-white'
        inputValue={searchValue}
        setInputValue={handleSearh}
        actionButtons={
          <FilterButton
            aria-pressed={showFilterComponent}
            disabled={false}
            onToggleFilter={handleToggleFilterComponent}
          />
        }
      />
      <AppliedFiltersTags<ProgramFilters>
        savedFilters={savedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={handleToggleFilterComponent}
        getOptionsbyKey={getOptionsbyKey}
        dataTestid='applied-programs-filters-tags'
      />
    </>
  );
};

export default ProgramsTableHeader;
