import { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TableOperationHeader } from '@getsynapse/design-system';
import { RequestFilters } from 'utils/types/filters';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import AppliedFiltersTags from '../../Pages/RequestsListPage/components/RequestsSidePanelFilters/AppliedFiltersTags';
interface RequestsTableOperationHeaderProps {
  onSearchChange: (value: string) => void;
  showFilters: boolean;
  toggleFilters: () => void;
  appliedFilters: RequestFilters;
  handleUpdateFilters: (filters: RequestFilters) => void;
}

const RequestsTableOperationHeader = ({
  onSearchChange,
  showFilters,
  toggleFilters,
  appliedFilters,
  handleUpdateFilters,
}: RequestsTableOperationHeaderProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { intakeActiveTabFilters = false } = useFlags();
  const renderActionButtons = () => {
    return intakeActiveTabFilters ? (
      <FilterButton
        aria-pressed={showFilters}
        onToggleFilter={toggleFilters}
        data-cy='openRequests-table__filter-button'
      />
    ) : (
      <></>
    );
  };
  const debouncedSearchChange = useMemo(() => {
    return debounce((value: string) => {
      onSearchChange(value);
    }, 500);
  }, [onSearchChange]);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    debouncedSearchChange(value);
  };
  return (
    <>
      <TableOperationHeader
        searchProps={{
          'data-testid': 'openRequests-search_input',
          placeholder: intl.get('SEARCH_PLACEHOLDER'),
        }}
        inputValue={searchTerm}
        setInputValue={handleSearchChange}
        actionButtons={renderActionButtons()}
      />
      <AppliedFiltersTags
        filters={appliedFilters}
        onDisplayAllFilters={toggleFilters}
        onUpdateFilters={handleUpdateFilters}
      />
    </>
  );
};

export default RequestsTableOperationHeader;
