import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Typography, IconButton, Button } from '@getsynapse/design-system';
import {
  selectStrategyGoalDetail,
  selectStrategyGoalDetailOwner,
  selectStrategyGoalDetailTeam,
  updateStrategyGoal,
} from 'state/SingleStrategyGoal/SingleStrategyGoalSlice';
import GoalTeam from 'Pages/StrategyGoalsPage/components/AddGoalModal/components/GoalTeam';
import TimePeriod from 'Pages/StrategyGoalsPage/components/AddGoalModal/components/TimePeriod';
import GoalTitle from 'Pages/StrategyGoalsPage/components/AddGoalModal/components/GoalTitle';
import GoalOwner from 'Pages/StrategyGoalsPage/components/AddGoalModal/components/GoalOwner';
import {
  GoalOwner as GoalOwnerType,
  GoalTeam as GoalTeamType,
  StrategyGoal,
  TimePeriodType,
} from 'utils/types/strategyGoals';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const GoalDetails = () => {
  const dispatch = useDispatch();

  const strategyGoalDetailOwner = useSelector(selectStrategyGoalDetailOwner);
  const strategyGoalDetail = useSelector(selectStrategyGoalDetail);
  const strategyGoalDetailTeam = useSelector(selectStrategyGoalDetailTeam);

  const [editMode, setEditMode] = useState(false);
  const [updatedGoal, setUpdatedGoal] = useState<StrategyGoal>(
    {} as StrategyGoal
  );

  const { title, timePeriod } = updatedGoal;

  const hasChanges = useMemo(() => {
    return !isEqual(strategyGoalDetail, updatedGoal);
  }, [strategyGoalDetail, updatedGoal]);

  useEffect(() => {
    setUpdatedGoal(strategyGoalDetail);
  }, [strategyGoalDetail]);

  const updateGoalFields = useCallback(
    (
      value:
        | string
        | string[]
        | GoalOwnerType[]
        | GoalTeamType[]
        | TimePeriodType
        | number,
      field: string
    ) => {
      setUpdatedGoal((prevState) => {
        return {
          ...prevState,
          [field]: value,
        };
      });
    },
    []
  );

  const updatedGoalHandler = async () => {
    let data: Partial<StrategyGoal> = {};
    const updatedData = {
      title: updatedGoal.title,
      type: updatedGoal.type,
      timePeriod: updatedGoal.timePeriod,
    };

    const originalData = {
      title: strategyGoalDetail.title,
      type: strategyGoalDetail.type,
      timePeriod: strategyGoalDetail.timePeriod,
    };

    if (!isEqual(originalData, updatedData)) {
      data = { ...updatedData };
    }

    if (!isEqual(updatedGoal.owners, strategyGoalDetail.owners)) {
      data.owners = updatedGoal.owners.map(
        (owner) => (owner as GoalOwnerType).id
      );
    }
    if (!isEqual(updatedGoal.teams, strategyGoalDetail.teams)) {
      data.teams = updatedGoal.teams.map((team) => (team as GoalTeamType).id);
    }
    setEditMode(false);
    try {
      await dispatch(updateStrategyGoal({ goalId: updatedGoal.id, data }));
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get('STRATEGY_GOALS.UPDATE_SUCCESS_TITLE'),
        })
      );
    } catch {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get('STRATEGY_GOALS.UPDATE_ERROR_TITLE'),
          notificationMessage: intl.get(
            'STRATEGY_GOALS.ADD_GOAL_MODAL.ERROR_MESSAGE'
          ),
        })
      );
    }
  };

  const cancelUpdateHandler = () => {
    if (hasChanges) {
      setUpdatedGoal(strategyGoalDetail);
    }
    setEditMode(false);
  };

  return (
    <div className='h-full flex flex-col pb-8 right-0 p-4 w-90 2xl:w-3/12 border border-neutral-lighter-two rounded'>
      <UnsavedChangesPrompt hasChanges={hasChanges} />
      <div className='flex justify-between'>
        <Typography
          variant='h5'
          className='pb-4 font-semibold'
          data-cy='goal-details'
        >
          {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_DETAILS')}
        </Typography>
        {strategyGoalDetail.permissions.canEdit && (
          <IconButton
            name='pencil'
            description='edit'
            hasASize={false}
            data-testid='edit-goal-button'
            className='flex items-center justify-center rounded-sm h-6 w-6 text-primary-dark hover:bg-primary-lightest hover:text-primary-dark 
          focus-visible:bg-primary-lightest focus-visible:text-primary-dark'
            onClick={() => {
              if (!editMode) setEditMode(true);
            }}
          />
        )}
      </div>
      <GoalTitle
        onChange={updateGoalFields}
        value={title}
        isRequired={editMode}
      />
      <GoalTeam
        onChange={updateGoalFields}
        value={strategyGoalDetailTeam}
        isRequired={editMode}
      />
      <GoalOwner
        onChange={updateGoalFields}
        value={strategyGoalDetailOwner}
        isRequired={editMode}
      />
      <TimePeriod
        onChange={updateGoalFields}
        value={[
          {
            label: `${timePeriod?.type} ${timePeriod?.year}`,
            value: {
              timePeriod: timePeriod?.type,
              year: timePeriod?.year,
            },
          },
        ]}
        isRequired={editMode}
      />
      {editMode && (
        <div className='mt-auto flex pt-8 pb-5'>
          <Button
            disabled={!hasChanges || !title}
            className='mr-4'
            onClick={updatedGoalHandler}
            data-testid='save-goal-button'
          >
            {intl.get('SAVE')}
          </Button>
          <Button
            variant='tertiary'
            onClick={cancelUpdateHandler}
            data-testid='cancel-goal-button'
          >
            {intl.get('CANCEL')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GoalDetails;
