import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Button,
  Dropdown,
  FormItem,
  Modal,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import {
  fetchProjectTemplates,
  selectAllTemplates,
} from 'state/ProjectTemplates/projectTemplatesSlice';
import AddProjectIcon from 'assets/icons/add-project-icon.svg';
import { ProjectTemplate } from 'utils/types/templates';
import useImportProjects from 'Pages/ProjectsListPage/hooks/useImportProjects';
import { useImport } from 'Hooks/useImport';
import importIcon from 'assets/icons/import.svg';

interface ImportProjectModalProps {
  showModal: boolean;
  onClose: () => void;
}

const ImportProjectModal: React.FC<ImportProjectModalProps> = ({
  showModal,
  onClose,
}) => {
  const dispatch = useDispatch();
  const projectTemplates: ProjectTemplate[] = useSelector(selectAllTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState<ProjectTemplate>();
  const [isLoading, setIsLoading] = useState(false);
  const onCloseModal = () => {
    setSelectedTemplate(undefined);
    onClose();
  };
  const { currentUser, settings, onData } = useImportProjects(selectedTemplate);
  const { startImport } = useImport(
    onData,
    currentUser,
    settings,
    onCloseModal
  );

  const onStartImport = () => {
    setIsLoading(true);
    startImport();
    setTimeout(() => setIsLoading(false), 1500);
  };

  useEffect(() => {
    dispatch(fetchProjectTemplates({ status: 'published' }));
  }, [dispatch]);

  const selectTemplate: (templateId: string) => void = useCallback(
    (templateId) => {
      const selectedTemplate = projectTemplates.find(
        (template) => template.id === templateId
      );

      if (selectedTemplate) {
        setSelectedTemplate(selectedTemplate);
      }
    },
    [projectTemplates]
  );

  return (
    <Modal
      title={intl.get('IMPORT_PROJECT_MODAL.TITLE')}
      titleIcon={{ src: AddProjectIcon }}
      isOpen={showModal}
      size='large'
      closeModal={onCloseModal}
      aria-label={intl.get('IMPORT_PROJECT_MODAL.TITLE')}
      data-testid='import-project-select-template-modal'
    >
      <Typography variant='body' className='mb-4 text-primary'>
        {intl.get('IMPORT_PROJECT_MODAL.DESCRIPTION')}
      </Typography>
      <FormItem
        label={intl.get(
          'PROJECTS_LIST_PAGE.CREATE_PROJECT_MODAL.TEMPLATE_FIELD_LABEL'
        )}
        labelProps={{ required: true }}
        className='w-88'
      >
        <Dropdown
          options={projectTemplates}
          getOptionLabel={(option: ProjectTemplate) => option.name}
          onChange={(option: ProjectTemplate) => selectTemplate(option.id!)}
          triggerProps={{ 'data-testid': 'select-template' }}
          placeholder={intl.get('SELECT')}
          renderOption={(
            option: ProjectTemplate,
            isSelected: boolean,
            selectOption,
            { className, ...otherProps }
          ) => (
            <li
              {...otherProps}
              data-value={option.id}
              data-testid={option.id}
              key={option.id}
              className={tailwindOverride('group', className, {
                'hover:bg-primary focus-visible:bg-primary': isSelected,
              })}
              onClick={selectOption}
            >
              <div className='flex w-100 flex-col space-y-1 '>
                <Typography
                  variant='label'
                  weight='medium'
                  className='text-primary'
                >
                  {option.name}
                </Typography>
                {option.description && (
                  <Typography variant='caption' className='text-neutral'>
                    {option.description}
                  </Typography>
                )}
              </div>
            </li>
          )}
        />
      </FormItem>

      <div className='flex mt-8 space-x-3'>
        <Button
          key={selectedTemplate?.id}
          color='default'
          iconPosition='left'
          size='small'
          variant='primary'
          iconSrc={importIcon}
          onClick={onStartImport}
          disabled={!selectedTemplate}
          loading={isLoading}
          className='mr-2'
        >
          {intl.get('IMPORT')}
        </Button>
      </div>
    </Modal>
  );
};

export default ImportProjectModal;
