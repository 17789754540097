import { FC } from 'react';
import intl from 'react-intl-universal';
import { Tag, tailwindOverride } from '@getsynapse/design-system';
import { PROJECT_STATUS } from 'utils/constants/projectsList';
import { ProjectStatus } from 'utils/types/projectsList';

interface ProjectStatusTagProps {
  status: ProjectStatus;
}

const mapTagColor: Record<
  ProjectStatus,
  { className: string; textClassName: string }
> = {
  [PROJECT_STATUS.NEW]: {
    className: tailwindOverride(
      'bg-purple-lightest group-hover:border-purple-dark'
    ),
    textClassName: 'text-purple-darker',
  },
  [PROJECT_STATUS.IN_PLANNING]: {
    className: tailwindOverride(
      'bg-warning-lightest group-hover:border-warning-light'
    ),
    textClassName: 'text-warning-darker',
  },
  [PROJECT_STATUS.IN_PROGRESS]: {
    className: tailwindOverride(
      'bg-teal-lightest group-hover:border-teal-light'
    ),
    textClassName: 'text-teal-dark',
  },
  [PROJECT_STATUS.COMPLETED]: {
    className: tailwindOverride(
      'bg-success-lightest group-hover:border-success-dark'
    ),
    textClassName: 'text-success-darker',
  },
  [PROJECT_STATUS.ON_HOLD]: {
    className: tailwindOverride(
      'bg-tertiary-lightest group-hover:border-tertiary'
    ),
    textClassName: 'text-tertiary-darker',
  },
  [PROJECT_STATUS.CANCELED]: {
    className: tailwindOverride('bg-error-lightest group-hover:border-error'),
    textClassName: 'text-error-darker',
  },
  [PROJECT_STATUS.CLOSED]: {
    className: tailwindOverride(
      'bg-secondary-lightest group-hover:border-secondary'
    ),
    textClassName: 'text-secondary-darker',
  },
};

const mapTagText = (status: ProjectStatus) => {
  const key = `${status}`.toUpperCase();
  return intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.${key}`).d('');
};

const ProjectStatusTag: FC<ProjectStatusTagProps> = ({ status }) => {
  const { className, textClassName } = mapTagColor[status];
  const text = mapTagText(status);
  return (
    <Tag
      className={tailwindOverride(
        'text-xs border border-transparent py-0.5',
        className
      )}
      textClassName={textClassName}
      label={text}
    />
  );
};

export default ProjectStatusTag;
