import intl from 'react-intl-universal';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  SidePanelFilter,
  FormItem,
  UsersPicker,
  Dropdown,
} from '@getsynapse/design-system';
import {
  fetchProjects,
  fetchProgramsForDropdown,
} from 'state/Schedule/ScheduleSlice';
import useEventsFilters from 'Pages/LearningSchedule/hooks/useEventsFilters';
import useSidePanelFilters from 'Hooks/useSidePanelFilters';
import { EventFilters, EventFiltersKey } from 'utils/types/filters';
import { Option, UserOption } from 'utils/customTypes';
import { EVENTS_TABLE_FILTERS } from 'utils/constants/learningSchedule';
import {
  getInitialValueForDropDown,
  getInitialUsersPickerValue,
} from 'utils/functions';

type EventsFiltersSidePanelProps = {
  open: boolean;
  onUpdateFilters: (filters: EventFilters) => void;
  onClose: () => void;
  savedFilters: EventFilters;
};

const EventsFiltersSidePanel = ({
  open,
  onUpdateFilters,
  onClose,
  savedFilters,
}: EventsFiltersSidePanelProps) => {
  const dispatch = useDispatch();
  const {
    projectOptions,
    facilitatorOptions,
    eventTypesOptions,
    programOptions,
    projectStatusOptions,
  } = useEventsFilters();

  const [projectSearchValue, setProjectSearchValue] = useState<string>('');
  const [programSearchValue, setProgramSearchValue] = useState<string>('');

  const {
    appliedFilters,
    canApplyFilters,
    handleUpdateFilters,
    handleApplyFilters,
    handleClearAllFilters,
    handleClosePanel,
  } = useSidePanelFilters<EventFilters>(savedFilters, onUpdateFilters, onClose);

  useEffect(() => {
    const findProjects = debounce(async () => {
      dispatch(fetchProjects(projectSearchValue));
    }, 300);

    if (open) {
      findProjects();
    }

    if (!open) {
      findProjects.cancel();
    }
  }, [projectSearchValue, dispatch, open]);

  useEffect(() => {
    const findPrograms = debounce(async () => {
      dispatch(fetchProgramsForDropdown(programSearchValue));
    }, 300);

    if (open) {
      findPrograms();
    }

    if (!open) {
      findPrograms.cancel();
    }
  }, [programSearchValue, dispatch, open]);

  const updateFilters = (key: EventFiltersKey, value: Option[]) => {
    const updatedFilters = { ...appliedFilters };
    if (value.length === 0) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = value.map((option) => option.value);
    }
    handleUpdateFilters(updatedFilters);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={handleClosePanel}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      applyButtonLabel={intl.get('FILTER_PANEL.APPLY')}
      clearAllButtonLabel={intl.get('FILTER_PANEL.CLEAR_ALL')}
    >
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.FILTERS.FACILITATORS.LABEL')}
        className='mb-6 mt-4'
      >
        <UsersPicker
          triggerText={intl.get(
            'SCHEDULE.EVENTS.FILTERS.FACILITATORS.TRIGGER_TEXT'
          )}
          usersList={facilitatorOptions}
          selectedUsersList={getInitialUsersPickerValue(
            facilitatorOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.FACILITATORS
            ] as string[]
          )}
          onChange={(selectedUsers: UserOption[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.FACILITATORS, selectedUsers)
          }
          emptyUsersListText={intl.get(
            'SCHEDULE.EVENTS.FILTERS.FACILITATORS.NO_AVAILABLE_FACILITATORS'
          )}
          triggerProps={{ 'data-testid': 'facilitators_trigger' }}
          popperProps={{ 'data-testid': 'facilitators_popper' }}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.FILTERS.EVENT_TYPE_LABEL')}
        className='mb-6'
      >
        <Dropdown
          options={eventTypesOptions}
          onChange={(options: Option[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.EVENT_TYPE, options)
          }
          multiple
          values={getInitialValueForDropDown(
            eventTypesOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.EVENT_TYPE
            ] as string[]
          )}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.FILTERS.PROGRAM_LABEL')}
        className='mb-6'
      >
        <Dropdown
          options={programOptions}
          onChange={(options: Option[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.PROGRAMS, options)
          }
          filterable
          multiple
          values={getInitialValueForDropDown(
            programOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.PROGRAMS
            ] as string[]
          )}
          triggerProps={{ 'data-testid': 'programs-dropdown_trigger' }}
          listProps={{
            'data-testid': 'programs-dropdown_list',
          }}
          onFilterChange={(value) => setProgramSearchValue(value)}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.FILTERS.PROJECT_LABEL')}
        className='mb-6'
      >
        <Dropdown
          options={projectOptions}
          onChange={(options: Option[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.PROJECTS, options)
          }
          filterable
          multiple
          values={getInitialValueForDropDown(
            projectOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.PROJECTS
            ] as string[]
          )}
          triggerProps={{ 'data-testid': 'projects-dropdown_trigger' }}
          listProps={{
            'data-testid': 'projects-dropdown_list',
          }}
          onFilterChange={(value) => setProjectSearchValue(value)}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.FILTERS.PROJECT_STATUS_LABEL')}
      >
        <Dropdown
          options={projectStatusOptions}
          onChange={(options: Option[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.PROJECT_STATUS, options)
          }
          filterable
          multiple
          values={getInitialValueForDropDown(
            projectStatusOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.PROJECT_STATUS
            ] as string[]
          )}
          triggerProps={{ 'data-testid': 'projects-status-dropdown_trigger' }}
          listProps={{
            'data-testid': 'projects-status-dropdown_list',
          }}
        />
      </FormItem>
    </SidePanelFilter>
  );
};

export default EventsFiltersSidePanel;
