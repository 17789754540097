import { FC } from 'react';
import { tailwindOverride, Table } from '@getsynapse/design-system';
import { Project } from 'types/store/projectsList';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import ProjectsTableHeader from './ProjectsTableHeader/ProjectsTableHeader';
import ProjectsTableBody from './ProjectsTableBody';

interface ProjectsTableProps {
  isLoading?: boolean;
  tableName: string;
  projects: Project[];
  canFetchMoreProjects?: boolean;
  fetchMoreProjects: () => void;
  handleSort: (column: string) => void;
}

const ProjectsTable: FC<ProjectsTableProps> = ({
  isLoading = false,
  projects,
  canFetchMoreProjects = false,
  fetchMoreProjects,
  tableName,
  handleSort,
}) => {
  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <div
      className={tailwindOverride(
        'rounded-b border-neutral-lighter-two border border-t-0',
        'overflow-auto',
        'max-h-centralized-tasks-table'
      )}
    >
      <Table
        className='w-full'
        canSelectRows={false}
        data={{
          headData: {
            headCells: [],
          },
          rows: [],
        }}
        aria-label={tableName}
      >
        <ProjectsTableHeader handleSort={handleSort} />
        <ProjectsTableBody
          tableName={tableName}
          projects={projects}
          canFetchMoreProjects={canFetchMoreProjects}
          fetchMoreProjects={fetchMoreProjects}
        />
      </Table>
    </div>
  );
};

export default ProjectsTable;
