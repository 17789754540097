import { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Tabs, tailwindOverride } from '@getsynapse/design-system';
import { PATHS } from 'utils/constants';
import { TIME_OFF_PAGE_TABS } from 'utils/constants/manageTimeOff';
import UpcomingTimeOffList from '../UpcomingTimeOffList/UpcomingTimeOffList';
import PastTimeOffList from '../PastTimeOffList/PastTimeOffList';
import AddTimeOffButton from '../AddTimeOffButton/AddTimeOffButton';
import { getCurrentLocale } from 'utils/localeHelper';

const TimeOffTabs = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ userId: string }>();
  const queryParams = new URLSearchParams(location.search);
  let activeTab = queryParams.get('activeTab');
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    let tabToSwitch = 0;

    if (activeTab === TIME_OFF_PAGE_TABS.HISTORY) {
      tabToSwitch = 1;
    }

    if (currentTab !== tabToSwitch) {
      setCurrentTab(tabToSwitch);
    }
  }, [activeTab, currentTab]);

  const handleChangeTab = (index: number) => {
    setCurrentTab(index);

    let baseRoute = PATHS.MANAGE_TIME_OFF;
    let activeTab = TIME_OFF_PAGE_TABS.UPCOMING_TIME_OFF;

    if (index === 1) {
      activeTab = TIME_OFF_PAGE_TABS.HISTORY;
    }

    if (params.userId) {
      baseRoute = `${baseRoute}/${params.userId}`;
    }

    history.push(`${baseRoute}?activeTab=${activeTab}`);
  };

  const currentLocale = getCurrentLocale();
  const tabClassname =
    currentLocale === 'en' ? 'max-w-sm mb-4' : 'max-w-sm mb-8';
  return (
    <div className='px-6 relative flex-grow h-full'>
      <div className={tailwindOverride('absolute top-0 right-0', 'w-32 mr-6')}>
        <AddTimeOffButton />
      </div>
      <Tabs
        index={currentTab}
        onChange={handleChangeTab}
        tabListProps={{ className: tabClassname }}
        tabPanelsProps={{ className: 'h-time-off-tab-content overflow-hidden' }}
        type='tab'
        data={[
          {
            label: intl.get('TEAMS.TIME_OFF.UPCOMING_TIME_OFF'),
            content: currentTab === 0 && <UpcomingTimeOffList />,
          },
          {
            label: intl.get('TEAMS.TIME_OFF.HISTORY'),
            content: currentTab === 1 && <PastTimeOffList />,
          },
        ]}
      />
    </div>
  );
};

export default TimeOffTabs;
