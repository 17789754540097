import { useState, useEffect, useCallback } from 'react';
import { TableOperationHeader } from '@getsynapse/design-system';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import {
  setProgramProjectsTableSearch,
  setProjectsFiltersStatus,
  setFilters,
} from 'state/Program/programSlice';
import { ProgramProjectFilters } from 'utils/types/filters';
import ProgramProjectsSidepanelFilters from './ProgramProjects/ProgramProjectsFilters/ProgramProjectsSidepanelFilters';
import AppliedFiltersTags from 'Organisms/AppliedFiltersTags/AppliedFiltersTags';
import useProgramProjectFilters from './ProgramProjects/hooks/useProgramProjectFilters';
import intl from 'react-intl-universal';

interface ProgramProjectsTableHeaderProps {
  savedFilters: ProgramProjectFilters;
  saveFilters: (updatedFilters: ProgramProjectFilters) => void;
}

const ProgramProjectsTableHeader: React.FC<ProgramProjectsTableHeaderProps> = ({
  savedFilters,
  saveFilters,
}) => {
  const dispatch = useDispatch();
  const { getOptionsbyKey } = useProgramProjectFilters(savedFilters);

  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchHandler = debounce((value: string) => {
    dispatch(setProgramProjectsTableSearch(value));
  }, 500);
  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      debouncedSearchHandler(value);
    },
    [debouncedSearchHandler]
  );

  const [showFilterComponent, setShowFilterComponent] = useState(false);
  const handleToggleFilterComponent = () => {
    setShowFilterComponent((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(setProjectsFiltersStatus(showFilterComponent));
  }, [showFilterComponent, dispatch]);

  const handleUpdateFilters = (updatedFilters: ProgramProjectFilters) => {
    dispatch(setFilters(updatedFilters));
    saveFilters(updatedFilters);
  };

  return (
    <>
      <ProgramProjectsSidepanelFilters
        savedFilters={savedFilters}
        onClose={handleToggleFilterComponent}
        open={showFilterComponent}
        onUpdateFilters={handleUpdateFilters}
      />
      <TableOperationHeader
        searchProps={{
          placeholder: intl.get('SEARCH_PLACEHOLDER'),
        }}
        className='border-b-0 bg-neutral-white'
        inputValue={searchValue}
        setInputValue={handleSearch}
        actionButtons={
          <FilterButton
            aria-pressed={showFilterComponent}
            disabled={false}
            onToggleFilter={handleToggleFilterComponent}
          />
        }
      />
      <AppliedFiltersTags<ProgramProjectFilters>
        savedFilters={savedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={handleToggleFilterComponent}
        getOptionsbyKey={getOptionsbyKey}
        dataTestid='applied-program-projects-filters-tags'
      />
    </>
  );
};

export default ProgramProjectsTableHeader;
