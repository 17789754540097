import React, { useEffect, useState } from 'react';
import { SidePanelFilter } from '@getsynapse/design-system';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';
import { TaskFilters } from 'utils/types/filters';
import FiltersForm from './TasksFiltersForm';
import intl from 'react-intl-universal';

export type TasksFiltersSidePanelProps = {
  open: boolean;
  onClose: () => void;
  filters: TaskFilters;
  taskTable: CentralizedTasksTableTab;
  onUpdateFilters: (filters: TaskFilters) => void;
};

const TasksFiltersSidePanel: React.FC<TasksFiltersSidePanelProps> = ({
  filters,
  onClose,
  open,
  taskTable,
  onUpdateFilters,
}) => {
  const [appliedFilters, setAppliedFilters] = useState<TaskFilters>({});
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters);
    }
  }, [filters]);

  const handleUpdateFilters = (updatedFilters: TaskFilters) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClearAllFilters = () => {
    onUpdateFilters({});
    setAppliedFilters({});
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={onClose}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='tasks-filters-side-panel'
      applyButtonLabel={intl.get('FILTER_PANEL.APPLY')}
      clearAllButtonLabel={intl.get('FILTER_PANEL.CLEAR_ALL')}
    >
      <FiltersForm
        filters={appliedFilters}
        taskTable={taskTable}
        onUpdateFilters={handleUpdateFilters}
      />
    </SidePanelFilter>
  );
};

export default TasksFiltersSidePanel;
