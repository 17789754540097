import { useState, useEffect, useCallback, useMemo, ReactNode } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@getsynapse/design-system';
import {
  fetchProjects,
  fetchUserProjects,
  resetBoardView,
  updatePagination,
  resetTableSearch,
} from 'state/Projects/projectsSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { getOrganizationProcesses } from 'state/Processes/processesSlice';
import { selectIsUserLd } from 'state/User/userSlice';
import { fetchProject, setProjectId } from 'state/Project/projectSlice';
import { Pagination, ProjectsTableTab } from 'utils/customTypes';
import { SETTINGS_FILTERS } from 'utils/constants';
import { ProjectListTabsKeys } from 'utils/types/projectsList';
import useProjectsListTabs from '../hooks/useProjectsListTabs';
import DuplicateProjectModal from '../components/DuplicateProject/DuplicateProjectModal';
import TeamProjects from '../components/TeamProjects/TeamProjects';
import MyProjects from '../components/MyProjects/MyProjects';
import ActiveProjects from '../components/ActiveProjects/ActiveProjects';
import ArchiveProjectModal from 'Organisms/ArchiveProjectModal/ArchiveProjectModal';
import ProjectSidePanel from 'Organisms/ProjectSidePanel/ProjectSidePanel';

const TableView: React.FC<{ handleAddProject: () => void }> = ({
  handleAddProject,
}) => {
  const { availableTabs, getTabIndexByKey, getTabKeyByIndex } =
    useProjectsListTabs();
  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('activeTab');
  const dispatch = useDispatch();
  const [currentTabIndex, setCurrentTabIndex] = useState(() => {
    if (activeTab) {
      return getTabIndexByKey(activeTab as ProjectListTabsKeys);
    }
    return 0;
  });
  const [isDuplicateProjectModalOpen, setIsDuplicateProjectModalOpen] =
    useState<boolean>(false);
  const [displayArchiveModal, setDisplayArchiveModal] = useState(false);
  const isLDUser = useSelector(selectIsUserLd);

  useEffect(() => {
    dispatch(resetBoardView());
    dispatch(getOrganizationProcesses());
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.TEAM_PROJECTS_TABLE));
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.MY_PROJECTS_TABLE));
    dispatch(resetTableSearch());
  }, [dispatch]);

  const handleDuplicateProject = useCallback(
    (projectId: string) => {
      dispatch(fetchProject(projectId));
      setIsDuplicateProjectModalOpen(true);
    },
    [dispatch]
  );

  const onArchiveProject = useCallback(
    (projectId: string) => {
      dispatch(fetchProject(projectId));
      setDisplayArchiveModal(true);
    },
    [dispatch]
  );

  const afterArchiveProject = () => {
    dispatch(fetchProjects());
    dispatch(fetchUserProjects());
  };

  const onCloseProjectPanel = async () => {
    await dispatch(setProjectId(''));
  };

  const handleUpdatePagination = useCallback(
    (pagination: Pagination, table: ProjectsTableTab) => {
      dispatch(updatePagination(pagination, table));
    },
    [dispatch]
  );

  const handleTabChange = useCallback(
    (index: number) => {
      setCurrentTabIndex(index);
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?activeTab=${
          getTabKeyByIndex(index) as ProjectListTabsKeys
        }`
      );
    },
    [getTabKeyByIndex]
  );

  const tabsContent = useMemo(
    () => ({
      activeItems: <ActiveProjects />,
      teamItems: (
        <TeamProjects
          onAddProject={handleAddProject}
          onDuplicateProject={handleDuplicateProject}
          onArchiveProject={onArchiveProject}
          onUpdatePagination={handleUpdatePagination}
        />
      ),
      myItems: (
        <MyProjects
          onAddProject={handleAddProject}
          onDuplicateProject={handleDuplicateProject}
          onArchiveProject={onArchiveProject}
          onUpdatePagination={handleUpdatePagination}
        />
      ),
    }),
    [
      handleAddProject,
      handleDuplicateProject,
      onArchiveProject,
      handleUpdatePagination,
    ]
  );

  const tabsData = useMemo(
    () =>
      Object.keys(availableTabs).reduce<
        Array<{ label: ReactNode; content: false | JSX.Element }>
      >((tabs, currentTabKey) => {
        const tabKey = currentTabKey as ProjectListTabsKeys;
        const tab = availableTabs[tabKey];
        if (!tab) {
          return tabs;
        } else {
          const availableTab = {
            label: tab.label,
            content:
              tab.index === currentTabIndex &&
              tabsContent[tabKey as ProjectListTabsKeys],
          };
          return tabs.concat(availableTab);
        }
      }, []),
    [availableTabs, currentTabIndex, tabsContent]
  );

  return (
    <div className='relative w-full h-full'>
      <DuplicateProjectModal
        isOpen={isDuplicateProjectModalOpen}
        onCloseModal={() => setIsDuplicateProjectModalOpen(false)}
      />
      <ArchiveProjectModal
        afterArchiveProject={afterArchiveProject}
        shouldDisplayModal={displayArchiveModal}
        onCloseModal={() => setDisplayArchiveModal(false)}
      />
      <ProjectSidePanel onClosePanel={onCloseProjectPanel} />
      <div className='pb-4 px-6'>
        {isLDUser ? (
          <Tabs
            index={currentTabIndex}
            onChange={handleTabChange}
            tabListProps={{ className: 'max-w-lg mb-4' }}
            tabPanelsProps={{ className: 'max-h-table overflow-y-auto' }}
            type='tab'
            data={tabsData}
          />
        ) : (
          <div>
            <span className='text-neutral-dark font-semibold text-base'>
              {intl.get('PROJECTS_LIST_PAGE.MY_PROJECTS')}
            </span>
            <MyProjects
              onAddProject={handleAddProject}
              onDuplicateProject={handleDuplicateProject}
              onArchiveProject={onArchiveProject}
              onUpdatePagination={handleUpdatePagination}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableView;
