import { tailwindOverride } from '@getsynapse/design-system';

const Skelton = ({
  className,
  isCircle = false,
}: {
  className?: string;
  isCircle?: boolean;
}) => (
  <div
    className={tailwindOverride(
      `bg-primary-lighter-two animate-pulse h-3 w-1/2 ${
        isCircle ? 'rounded-circle' : 'rounded-sm'
      }`,
      className
    )}
  ></div>
);

export default Skelton;
