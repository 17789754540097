import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { TableHeadCell, tailwindOverride } from '@getsynapse/design-system';
import { selectSortValue } from 'state/ProjectsList/projectsListSlice';
import { ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS } from 'utils/constants/projectsList';

interface ProjectsTableHeaderProps {
  handleSort: (column: string) => void;
}

const ProjectsTableHeader: FC<ProjectsTableHeaderProps> = ({ handleSort }) => {
  const sortValue = useSelector(selectSortValue);

  const onSort = useCallback(
    (orderByParam: string, order: 'desc' | 'asc') => {
      let column = orderByParam;
      if (order === 'desc') {
        column = `-${orderByParam}`;
      }
      handleSort(column);
    },
    [handleSort]
  );

  const stickyHeaderStyles = tailwindOverride(
    'sticky top-0 z-1 bg-primary-lightest h-10'
  );

  const headerCells = [
    {
      columnName: ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_NUMBER,
      content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_NUMBER'),
    },
    {
      columnName: ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_NAME,
      content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_NAME'),
    },
    {
      columnName: ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS.STATUS,
      content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.STATUS'),
    },
    {
      columnName: ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS.OWNERS,
      content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_OWNER'),
    },
    {
      columnName: ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS.START_DATE,
      content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.START_DATE'),
    },
    {
      columnName: ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS.TARGET_COMPLETION_DATE,
      content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.TARGET_COMPLETION_DATE'),
    },
    {
      columnName: ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS.UPDATED_AT,
      content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.UPDATED_AT'),
    },
  ];

  return (
    <thead
      className={tailwindOverride(
        'h-10 bg-primary-lightest',
        'text-primary text-caption font-semibold'
      )}
    >
      <tr>
        {headerCells.map((cell, index) => {
          const isActive = sortValue
            ? sortValue === cell.columnName ||
              sortValue === `-${cell.columnName}`
            : false;
          const isDesc = isActive && sortValue === `-${cell.columnName}`;
          const isAsc = isActive && sortValue === cell.columnName;
          return (
            <TableHeadCell
              key={index}
              columnName={cell.columnName}
              content={cell.content}
              className={tailwindOverride(stickyHeaderStyles, {
                'bg-secondary-lightest': isActive,
              })}
              handleSort={() => {
                onSort(cell.columnName!, isAsc ? 'desc' : 'asc');
              }}
              isActive={isActive}
              isDesc={isDesc}
              isAsc={isAsc}
              aria-label={cell.columnName}
            />
          );
        })}
      </tr>
    </thead>
  );
};

export default ProjectsTableHeader;
