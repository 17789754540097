import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';

interface LanguageContextProps {
  currentLocale: string;
  switchLanguage: (language: string) => void;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // Retrieve saved locale or default to 'ja'
  const [currentLocale, setCurrentLocale] = useState<string>(
    () => localStorage.getItem('locale') || 'en'
  );

  const switchLanguage = (language: string) => {
    setCurrentLocale(language);
    localStorage.setItem('locale', language);
    window.location.reload(); // Reload to apply the new language
  };

  useEffect(() => {
    localStorage.setItem('locale', currentLocale);
  }, [currentLocale]);

  return (
    <LanguageContext.Provider value={{ currentLocale, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
