import { FC } from 'react';
import {
  Avatar,
  AvatarGroup,
  TableRow,
  tailwindOverride,
} from '@getsynapse/design-system';
import { getRequestRoute } from 'utils/dynamicRoutes';
import intl from 'react-intl-universal';

import {
  ProjectWithPermissions,
  Request,
  UserSummary,
} from 'types/store/normalized';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsUserLd, selectOrganizationId } from 'state/User/userSlice';
import RequestStatusTag from 'Atoms/RequestStatusTag/RequestStatusTag';
import { formatDate, formatRequestLearningPriority } from 'utils/formatters';

import { userSummarySelectors } from 'state/normalized/userSummaries/userSummariesSlice';
import { isUserStatusDisabled } from 'utils/functions';
import { formSummarySelectors } from 'state/normalized/formSummaries/formSummariesSlice';
import { projectSummarySelectors } from 'state/normalized/projectSummaries/projectSummariesSlice';
import { projectPermissionsSelectors } from 'state/normalized/projectPermissions/projectPermissionsSlice';
import LinkedProjectsTooltip from 'Pages/RequestsListPage/components/LinkedProjectsTooltip';
import { businessUnitSummarySelectors } from 'state/normalized/businessUnitSummaries/businessUnitSummariesSlice';

interface TableRowProps {
  request: Request;
  isOdd?: boolean;
}

const mapToAvatar = (user: UserSummary) => {
  const allInitials = user.name.split(' ').map((n) => n[0]);
  const initial = allInitials[0] + allInitials.splice(-1);
  return {
    imageSrc: user.avatarUrl || '',
    initial,
    name: user.name,
    disabled: isUserStatusDisabled(user.status),
  };
};

const mapToAvatarGroup = (owners: UserSummary[]) => owners.map(mapToAvatar);

const RequestTableRow: FC<TableRowProps> = ({ request, isOdd }) => {
  const history = useHistory();
  const organizationId = useSelector(selectOrganizationId)!;
  const isLearningUser = useSelector(selectIsUserLd);
  const getUserSummary = useSelector(
    userSummarySelectors.selectUserSummaryById
  );
  const getUserSummaries = useSelector(
    userSummarySelectors.selectUserSummariesByIds
  );
  const getFormSummary = useSelector(
    formSummarySelectors.selectFormSummaryById
  );
  const getProjectSummaries = useSelector(
    projectSummarySelectors.selectProjectSummariesByIds
  );
  const getProjectPermissions = useSelector(
    projectPermissionsSelectors.selectProjectPermissionsByIds
  );
  const getBusinessUnitSummaries = useSelector(
    businessUnitSummarySelectors.selectBusinessUnitSummaryById
  );

  const owners = getUserSummaries(request.ownerIds);
  const requester = getUserSummary(request.requesterId || '');
  const form = getFormSummary(request.formId);
  const projects = getProjectSummaries(request.projectIds);
  const projectPermissions = getProjectPermissions(request.projectIds);
  const businessUnit = getBusinessUnitSummaries(
    request.businessTeamId as string
  );

  const projectsWithPermissions = projects.map((project) => ({
    ...project,
    permissions: projectPermissions.find(
      (permissions) => permissions.id === project.id
    ),
  }));

  return (
    <TableRow
      className={tailwindOverride(
        'cursor-pointer',
        isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'
      )}
      key={request.id}
      onClick={() => history.push(getRequestRoute(request.id, organizationId))}
      cells={[
        {
          content: request.number,
        },
        {
          content: (
            <div className='w-64 truncate'>
              <Link
                to={getRequestRoute(request.id, organizationId)}
                title={request.title}
              >
                {request.title}
              </Link>
            </div>
          ),
        },
        {
          content: <RequestStatusTag status={request.status} />,
        },
        ...(isLearningUser
          ? [
              {
                content: formatRequestLearningPriority(
                  request.learnAndDevelopmentPriority
                ),
              },
            ]
          : []),
        {
          content: requester && (
            <div className='flex items-center'>
              <Avatar
                {...mapToAvatar(requester)}
                deactivatedText={intl.get('DEACTIVATED')}
              />
              <span className='ml-2.5'>{requester.name}</span>
            </div>
          ),
        },
        {
          content:
            owners && owners.length === 1 ? (
              <div className='flex items-center'>
                <AvatarGroup
                  avatars={mapToAvatarGroup(owners)}
                  deactivatedText={intl.get('DEACTIVATED')}
                />
                <span className='ml-2.5'>{owners[0].name}</span>
              </div>
            ) : (
              <AvatarGroup
                avatars={mapToAvatarGroup(owners)}
                deactivatedText={intl.get('DEACTIVATED')}
              />
            ),
          className: `font-semibold`,
        },
        {
          content: request.projectIds?.length ? (
            <LinkedProjectsTooltip
              numberOfProjects={`${request.projectIds.length.toString()} ${intl
                .get('ENTITIES.PROJECT', { num: request.projectIds.length })
                .toLowerCase()}`}
              projects={projectsWithPermissions as ProjectWithPermissions[]}
            />
          ) : (
            intl.get(
              'REQUESTS_LIST_PAGE.TABLE.LINKED_PROJECTS.NO_LINKED_PROJETS'
            )
          ),
        },
        ...(isLearningUser
          ? [
              {
                content: (
                  <div className='w-44 truncate'>{businessUnit?.name}</div>
                ),
              },
            ]
          : []),
        {
          content: <div className='w-44 truncate'>{form?.title}</div>,
        },
        {
          content: formatDate(request.createdAt),
        },
        {
          content: formatDate(request.submittedAt),
        },
        {
          content: formatDate(request.decisionDate),
        },
        {
          content: formatDate(request.updatedAt),
        },
      ]}
    />
  );
};

export default RequestTableRow;
