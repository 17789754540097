import { useEffect, useMemo } from 'react';
import { TabPanels, TabPanel, Tabs } from '@reach/tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList } from '@getsynapse/design-system';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import {
  fetchAggregatesBy,
  getRequestsBy,
  getActiveFiltersQueryParams,
} from 'state/Insights/InsightsSlice';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import {
  INSIGHTS_INTAKE_REQUESTS_BY_CHART,
  INSIGHTS_INTAKE_REQUESTS_BY_CHART_FILTERS,
} from 'utils/constants';
import LineChartComponent from './LineChart';
import FormLineChart from './FormLineChart';
import TypeLineChart from './TypeLineChart';
import BusinessUnitChart from './BusinessUnitChart';

const ChartPanels = () => {
  const dispatch = useDispatch();
  const { activeTab } = useParams<{ activeTab: string }>();
  const requestsByState = useSelector(getRequestsBy);
  const { status } = requestsByState;
  const activeFilters = useSelector(getActiveFiltersQueryParams);

  const tabMapping = useMemo(() => {
    return {
      [INSIGHTS_INTAKE_REQUESTS_BY_CHART.STATUS]: 0,
      [INSIGHTS_INTAKE_REQUESTS_BY_CHART.BUSINESS_UNIT]: 1,
      [INSIGHTS_INTAKE_REQUESTS_BY_CHART.FORM]: 2,
      [INSIGHTS_INTAKE_REQUESTS_BY_CHART.TYPE]: 3,
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(
    activeTab ? tabMapping[activeTab] : 0
  );

  const handleTabChange = (index: number) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    dispatch(
      fetchAggregatesBy({
        filter: INSIGHTS_INTAKE_REQUESTS_BY_CHART_FILTERS[activeIndex],
        activeFilters,
      })
    );
  }, [dispatch, activeIndex, activeFilters]);

  return (
    <Tabs
      index={activeIndex || 0}
      className='flex flex-col min-h-full'
      onChange={handleTabChange}
    >
      <PageTitle
        titleComponent={intl.get('INSIGHTS_PAGE_TABS.REQUESTS_BY.TITLE') + ' :'}
        titleVariant='label'
        className='ml-2 mt-2'
        headerChildren={
          <div className='flex flex-row mr-auto ml-4'>
            <TabList type='subHeader'>
              <Tab index={0} type='subHeader'>
                <Typography
                  variant='caption'
                  className={classnames({ 'font-semibold': activeIndex === 0 })}
                >
                  {intl.get(
                    'INSIGHTS_PAGE_TABS.INSIGHTS_INTAKE_REQUESTS_BY_CHART.STATUS'
                  )}
                </Typography>
              </Tab>
              <Tab index={1} type='subHeader'>
                <Typography
                  variant='caption'
                  className={classnames({ 'font-semibold': activeIndex === 1 })}
                >
                  {intl.get(
                    'INSIGHTS_PAGE_TABS.INSIGHTS_INTAKE_REQUESTS_BY_CHART.BUSINESS_UNIT'
                  )}
                </Typography>
              </Tab>
              <Tab index={2} type='subHeader'>
                <Typography
                  variant='caption'
                  className={classnames({ 'font-semibold': activeIndex === 2 })}
                >
                  {intl.get(
                    'INSIGHTS_PAGE_TABS.INSIGHTS_INTAKE_REQUESTS_BY_CHART.TYPE'
                  )}
                </Typography>
              </Tab>
              <Tab index={3} type='subHeader'>
                <Typography
                  variant='caption'
                  className={classnames({ 'font-semibold': activeIndex === 3 })}
                >
                  {intl.get(
                    'INSIGHTS_PAGE_TABS.INSIGHTS_INTAKE_REQUESTS_BY_CHART.FORM'
                  )}
                </Typography>
              </Tab>
            </TabList>
          </div>
        }
      />
      <TabPanels className='relative w-full h-full'>
        <TabPanel key={0} className='w-full h-full pt-4'>
          <LineChartComponent status={status} state={requestsByState} />
        </TabPanel>
        <TabPanel key={1} className='w-full h-full pt-4'>
          <BusinessUnitChart status={status} state={requestsByState} />
        </TabPanel>
        <TabPanel key={2} className='w-full h-full pt-4'>
          <TypeLineChart status={status} state={requestsByState} />
        </TabPanel>
        <TabPanel key={3} className='w-full h-full pt-4'>
          <FormLineChart status={status} state={requestsByState} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ChartPanels;
