import { useState, SetStateAction, Dispatch } from 'react';
import classnames from 'classnames';
import {
  Table,
  Tooltip,
  IconButton,
  TableOperationHeader,
  Typography,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { TaskTemplate } from 'utils/types/taskTemplate';
import { get, isEmpty } from 'lodash';
import ReorderToggle from 'Organisms/TableHeaderActionButtons/ReorderToggle';
import ReorderIcon from 'Pages/ProjectPage/tabs/Tasks/TasksListPage/components/ReorderIcon';
import CreateTaskTemplateModal from './CreateTaskTemplateModal';
import EditTaskTemplateModal from './EditTaskTemplateModal';
import { DeleteTaskConfirmationModal } from './DeleteTaskConfirmationModal';
import EmptyTasksTable from './EmptyTasksTable';
import { getEmptyTableRow } from './helpers';

const BuildBundle = ({
  templates,
  setTemplates,
  setNewAndUpdatedTemplates,
  isEditing = false,
}: {
  templates: TaskTemplate[];
  setTemplates: Dispatch<SetStateAction<TaskTemplate[]>>;
  setNewAndUpdatedTemplates: Dispatch<SetStateAction<Set<string>>>;
  isEditing?: boolean;
}) => {
  const [showReorderButton, setShowReorderButton] = useState(false);
  const [activeTask, setActiveTask] = useState<TaskTemplate | null>(null);
  const [taskToDelete, setTaskToDelete] = useState<number | null>(null);

  const removeTaskTemplate = (index: number) => {
    const templateToRemove = templates[index];
    if (!isEmpty(templateToRemove)) {
      setTemplates((prevTemplates) => {
        const newTemplates = [...prevTemplates];
        newTemplates.splice(index, 1);
        return newTemplates;
      });
      setNewAndUpdatedTemplates((prevTemplatesIds) => {
        if (templateToRemove.id) {
          prevTemplatesIds.delete(templateToRemove.id);
        }
        return prevTemplatesIds;
      });
    }
  };

  const moveItem = (from: number, to: number) => {
    const templatesAfterMove = [...templates];
    const itemToMove = templatesAfterMove.splice(from, 1)[0];
    templatesAfterMove.splice(to, 0, itemToMove);
    setTemplates(templatesAfterMove);
  };

  const onReOrder = (direction: string, id: string) => {
    const currentIndex = templates.findIndex((template) => template.id === id);
    if ((currentIndex === 0 && direction === 'up') || currentIndex === -1)
      return;
    if (currentIndex === templates.length - 1 && direction === 'down') return;

    if (direction === 'up') {
      moveItem(currentIndex, currentIndex - 1);
    } else if (direction === 'down') {
      moveItem(currentIndex, currentIndex + 1);
    }

    setNewAndUpdatedTemplates((prevTemplatesIds) => {
      if (id) {
        return prevTemplatesIds.add(id);
      }
      return prevTemplatesIds;
    });
  };

  const [searchParamValue, setSearchParamValue] = useState('');
  const handleSearch = (value: string) => {
    setSearchParamValue(value);
  };

  const formattedTemplates = templates.filter((template) => {
    if (searchParamValue) {
      return (
        template.name
          ?.toLocaleLowerCase()
          .includes(searchParamValue.toLocaleLowerCase()) || false
      );
    } else {
      return true;
    }
  });

  const handleReorderButtonClick = (showReorder: boolean) => {
    setShowReorderButton(showReorder);
  };

  const isTemplatesTableEmpty = templates.length === 0;

  return (
    <div className='flex flex-col'>
      <div className='my-4 flex justify-between'>
        <Typography
          variant='body'
          className='text-neutral-dark text-lg font-normal'
        >
          {intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.TASK_LIST')}
        </Typography>

        <CreateTaskTemplateModal
          setNewAndUpdatedTemplates={setNewAndUpdatedTemplates}
          setTemplates={setTemplates}
        />
      </div>
      {isEditing && (
        <TableOperationHeader
          className='border-0 rounded-b-none bg-neutral-white'
          inputValue={searchParamValue}
          setInputValue={handleSearch}
          searchProps={{
            disabled: showReorderButton || isTemplatesTableEmpty,
            placeholder:
              (!showReorderButton || !isTemplatesTableEmpty) &&
              intl.get('SEARCH_PLACEHOLDER'),
          }}
          actionButtons={
            <ReorderToggle
              disabled={isTemplatesTableEmpty || searchParamValue}
              isChecked={showReorderButton}
              onClick={handleReorderButtonClick}
            />
          }
        />
      )}
      <div
        className={classnames(
          'w-full overflow-auto',
          { 'max-h-task-bundles-table-body': isEditing },
          { 'max-h-filtered-table-body': !isEditing }
        )}
        id='task-templates-table-scroll'
      >
        <Table
          className='w-full mb-1 relative'
          canSelectRows={false}
          data={{
            headData: {
              stickyHeader: true,
              headCells: [
                {
                  content: '',
                  className: classnames('h-full', {
                    'z-2 py-3 px-4': !isTemplatesTableEmpty,
                    hidden: !showReorderButton,
                  }),
                },
                {
                  content: (
                    <>
                      {intl.get(
                        'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.NAME'
                      )}
                      <span className='text-error-dark'>*</span>
                    </>
                  ),
                  className: classnames('font-semibold py-3 px-4 w-5/12', {}),
                },
                {
                  content: intl.get(
                    'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.DESCRIPTION'
                  ),
                  className: 'font-semibold py-3 px-4 w-7/12',
                },
                {
                  content: intl.get('TASK.TASK_TYPE'),
                  className: 'font-semibold py-3 px-4',
                },
                {
                  content: intl.get('TASK.ESTIMATED_HOURS'),
                  className: 'font-semibold py-3 px-4',
                },
                {
                  content: <div></div>,
                  className: classnames('h-full bg-primary-lightest z-2', {
                    'w-16 right-0': !(templates.length === 0),
                  }),
                },
              ],
            },
            rows:
              formattedTemplates.length === 0
                ? searchParamValue && getEmptyTableRow(searchParamValue)
                : formattedTemplates.map(
                    (taskTemplate: TaskTemplate, index) => {
                      const isFirst = index === 0;
                      const isLast = index === formattedTemplates.length - 1;

                      return {
                        id: `${index}`,
                        className: classnames(
                          'cursor-pointer w-full table-fixed',
                          {
                            'cursor-auto': showReorderButton,
                          }
                        ),
                        onClick: () => {
                          !showReorderButton && setActiveTask(templates[index]);
                        },
                        cells: [
                          {
                            content: (
                              <div className='w-24 flex items-center justify-end pr-4'>
                                <ReorderIcon
                                  name='arrow-up'
                                  className='mr-4 cursor-pointer'
                                  isFirst={isFirst}
                                  onClickHandler={() => {
                                    if (isFirst) return;
                                    onReOrder('up', taskTemplate.id);
                                  }}
                                  id={taskTemplate.id}
                                  dataCy={`task-reorder-up-${taskTemplate.id}`}
                                />
                                <ReorderIcon
                                  name='arrow-down'
                                  className='cursor-pointer'
                                  isLast={isLast}
                                  onClickHandler={() => {
                                    if (isLast) return;
                                    onReOrder('down', taskTemplate.id);
                                  }}
                                  id={taskTemplate.id}
                                  dataCy={`task-reorder-down-${taskTemplate.id}`}
                                />
                              </div>
                            ),
                            className: classnames('h-full p-0 w-34', {
                              hidden: !showReorderButton,
                            }),
                          },
                          {
                            content: (
                              <div
                                className='flex items-center'
                                title={get(taskTemplate, 'name') || '-'}
                              >
                                {taskTemplate.name}
                              </div>
                            ),
                            className: classnames('max-w-94 truncate', {
                              'group-hover:shadow-first-table-cell-hover group-hover:border-secondary-darker shadow-table-column group-hover:shadow-none':
                                !showReorderButton,
                            }),
                          },
                          {
                            content: (
                              <div
                                className='line-clamp-2 whitespace-normal'
                                title={get(taskTemplate, 'description') || '-'}
                              >
                                {get(taskTemplate, 'description') || '-'}
                              </div>
                            ),
                            className: 'max-w-94',
                          },
                          {
                            content: taskTemplate.type || '-',
                            className: 'max-w-46 truncate',
                          },
                          {
                            content: taskTemplate.estimated_hours || '-',
                            className: 'max-w-46 truncate',
                          },
                          {
                            content: (
                              <div
                                className={classnames(
                                  'h-full text-center relative w-16'
                                )}
                                onClick={(
                                  event: React.MouseEvent<HTMLInputElement>
                                ) => event.stopPropagation()}
                              >
                                {
                                  <div className='flex w-full justify-center'>
                                    <Tooltip
                                      openMode='hover1'
                                      timeout={0}
                                      showPopper
                                      position='topCenter'
                                      contentProps={{
                                        className: 'px-3 py-2 text-sm z-50',
                                      }}
                                      usePortal
                                      trigger={
                                        <IconButton
                                          name='trash'
                                          iconClassname='pointer-events-none'
                                          disabled={showReorderButton}
                                          className='text-base ml-2 text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                                          onClick={() => setTaskToDelete(index)}
                                          data-cy={`remove-task-${taskTemplate.id}_button`}
                                          data-testid={`remove-task-${taskTemplate.id}`}
                                        />
                                      }
                                    >
                                      {intl.get('REMOVE')}
                                    </Tooltip>
                                  </div>
                                }
                              </div>
                            ),
                            className:
                              'w-16 right-0 sticky z-1 border-transparent',
                          },
                        ],
                      };
                    }
                  ),
          }}
        />

        {activeTask && (
          <EditTaskTemplateModal
            isModalOpen
            onCloseModal={() => setActiveTask(null)}
            taskTemplateData={activeTask}
            setNewAndUpdatedTemplates={setNewAndUpdatedTemplates}
            setTemplates={setTemplates}
          />
        )}
      </div>
      <DeleteTaskConfirmationModal
        isOpen={taskToDelete !== null}
        closeModal={() => setTaskToDelete(null)}
        onCancel={() => setTaskToDelete(null)}
        onDeleteTask={() => {
          if (taskToDelete !== null) {
            removeTaskTemplate(taskToDelete);
          }
          setTaskToDelete(null);
        }}
      />
      {!searchParamValue && formattedTemplates.length === 0 && (
        <EmptyTasksTable>
          <CreateTaskTemplateModal
            setNewAndUpdatedTemplates={setNewAndUpdatedTemplates}
            setTemplates={setTemplates}
          />
        </EmptyTasksTable>
      )}
    </div>
  );
};

export default BuildBundle;
