import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { DATE } from 'utils/constants';
import { AppliedFilter, Option } from 'utils/customTypes';
import {
  getDateFilterLabel,
  getFilterOptionLabelByKeyAndValue,
} from 'utils/filters';
import { isDateRangeFilter } from 'utils/typeGuards';
import { EntityFilteredType, Filter } from 'utils/types/filters';
import intl from 'react-intl-universal';
interface AppliedFiltersTagsProps<T> {
  savedFilters: EntityFilteredType<T>;
  onUpdateFilters: (filters: EntityFilteredType<T> | {}) => void;
  onDisplayAllFilters: () => void;
  getOptionsbyKey: (key: keyof T) => Option[];
  dataTestid: string;
}

const AppliedFiltersTags = <T,>({
  savedFilters,
  onUpdateFilters,
  onDisplayAllFilters,
  getOptionsbyKey,
  dataTestid,
}: PropsWithChildren<AppliedFiltersTagsProps<T>>) => {
  const appliedFilters = useMemo<AppliedFilter[]>(() => {
    const tags: AppliedFilter[] = [];

    for (const key of Object.keys(savedFilters)) {
      const filterKey = key as keyof T;
      const filterValue: Filter = savedFilters[filterKey];
      if (isDateRangeFilter(filterValue)) {
        const label = getDateFilterLabel(filterValue, DATE.TASK_TABLE_FORMAT);
        tags.push({ label, value: '', key });
      } else {
        const filterOptions = getOptionsbyKey(filterKey);
        filterValue.forEach((value: string) => {
          const label = getFilterOptionLabelByKeyAndValue(filterOptions, value);
          tags.push({ label, value, key });
        });
      }
    }

    return tags;
  }, [getOptionsbyKey, savedFilters]);
  const removeFilter = useCallback(
    (filter: AppliedFilter) => {
      const filterKey = filter.key as keyof T;
      const updatedFilters = { ...savedFilters };
      const filterValue: Filter = updatedFilters[filterKey];

      if (isDateRangeFilter(filterValue)) {
        delete updatedFilters[filterKey];
      } else {
        const updatedFilterValues = filterValue.filter(
          (value) => value !== filter.value
        );
        if (updatedFilterValues.length === 0) {
          delete updatedFilters[filterKey];
        } else {
          updatedFilters[filterKey] =
            updatedFilterValues as EntityFilteredType<T>[keyof T];
        }
      }

      onUpdateFilters(updatedFilters);
    },
    [onUpdateFilters, savedFilters]
  );

  return (
    <AppliedFilters<AppliedFilter>
      filters={appliedFilters}
      onRemoveFilter={removeFilter}
      onClearAll={() => onUpdateFilters({})}
      onDisplayAllFilters={onDisplayAllFilters}
      className={tailwindOverride('border-primary-lighter-two z-20')}
      data-testid={dataTestid}
      title={intl.get('FILTER_PANEL.FILTERS_APPLIED')}
      clearAllLabel={intl.get('FILTER_PANEL.CLEAR_ALL')}
    />
  );
};

export default AppliedFiltersTags;
