import axios, { AxiosRequestConfig } from 'axios';
import { api } from './api';
import { UpdatedDependency } from 'types/store/taskDependencies';

export interface FetchDependenciesUpdatePreviewParams {
  taskId: string;
  newStartDate?: string;
  newDueDate?: string;
  newCompletionDate?: string;
}

export interface FetchDependenciesUpdatePreviewResponse {
  daysShifted: {
    start: number;
    end: number;
  };
  successors: UpdatedDependency[];
  predecessors: UpdatedDependency[];
}

export const fetchDependenciesUpdatePreview = async (
  params: FetchDependenciesUpdatePreviewParams
) => {
  const { taskId, ...otherParams } = params;
  const url = `/v2/tasks/${taskId}/previews/date-shift`;
  const queryParams: AxiosRequestConfig['params'] = { ...otherParams };

  const response = await api.get<FetchDependenciesUpdatePreviewResponse>(url, {
    params: queryParams,
  });

  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
