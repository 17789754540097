import { FC } from 'react';
import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import emptyProjectsTable from 'assets/icons/empty-projects.svg';
import noProjectsFound from 'assets/images/no-projects-found-table.svg';
import emptyFilteredProjects from 'assets/images/empty-filtered-projects-table.svg';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';

interface EmptyProjectsTableProps {
  addNewProject?: () => void;
  emptyFilters?: boolean;
  emptySearch?: boolean;
}

const EmptyProjectsTable: FC<EmptyProjectsTableProps> = ({
  addNewProject,
  emptySearch = true,
  emptyFilters = true,
}) => {
  if (!emptySearch || !emptyFilters) {
    return (
      <RenderNoRecords
        imageSrc={!emptySearch ? noProjectsFound : emptyFilteredProjects}
        imageAlt={intl.get('REQUESTS_LIST_PAGE.NO_RECORDS')}
        className={
          !emptySearch ? 'h-empty-table-body' : 'h-empty-filtered-table-body'
        }
        caption={intl.get('REQUESTS_LIST_PAGE.NO_RECORDS')}
      />
    );
  }

  return (
    <RenderNoRecords
      imageSrc={emptyProjectsTable}
      imageClassName='-ml-4'
      className='h-empty-table-body'
      caption={intl.get('PROJECTS_LIST_PAGE.TABLE.EMPTY')}
      labelClassName='mt-0'
    >
      <Button className='mt-2 mx-auto' onClick={addNewProject}>
        {intl.get('PROJECTS_LIST_PAGE.TABLE.GET_STARTED')}
      </Button>
    </RenderNoRecords>
  );
};

export default EmptyProjectsTable;
