import { FC } from 'react';
import { useHistory, Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import moment from '../../../../../utils/moment';
import { Tooltip } from '@getsynapse/design-system';
import { PATHS, DATE } from 'utils/constants';
import { TaskWithAssigneesAndProject } from 'types/store/tasksList';
import useDueTasks from './useDueTasks';
import MyWorkspaceTable from '../MyWorkspaceTable';
import {
  getStatusColumn,
  getStatusPill,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';

type TasksTableProps = {
  cardTable?: boolean;
  limit?: number;
};

const TasksTable: FC<TasksTableProps> = ({ cardTable = true, limit }) => {
  const history = useHistory();

  const { sortedTasks, setTableSorting } = useDueTasks(limit);

  const handleSort = (
    orderByParam: string[] | string,
    order: 'desc' | 'asc'
  ) => {
    const sortBy = orderByParam as string;
    setTableSorting({ sortBy, order });
  };

  return (
    <MyWorkspaceTable
      cardTable={cardTable}
      dataCy='due-tasks-table'
      headCells={[
        {
          content: intl.get('MY_WORKSPACE.DUE_TASKS.TABLE.HEAD_CELLS.TITLE'),
          columnName: 'name',
        },
        {
          content: intl.get('MY_WORKSPACE.DUE_TASKS.TABLE.HEAD_CELLS.PROJECT'),
          columnName: 'project.title',
        },
        {
          content: intl.get('MY_WORKSPACE.DUE_TASKS.TABLE.HEAD_CELLS.STATUS'),
          columnName: 'status',
          className: `${cardTable ? 'w-20' : 'w-28'}`,
        },
        {
          content: cardTable
            ? intl.get('MY_WORKSPACE.DUE_TASKS.TABLE.HEAD_CELLS.DUE')
            : intl.get('MY_WORKSPACE.DUE_TASKS.TABLE.HEAD_CELLS.DUE_DATE'),
          columnName: 'endDate',
          className: 'w-36',
        },
      ]}
      rows={sortedTasks.map((task: TaskWithAssigneesAndProject) => {
        const dueDateWithoutTime = moment(new Date(task.endDate!))
          .locale('en')
          .format('L');
        const currentDate = moment(new Date());
        const currentTime = currentDate.format('HH:mm:ssZ');
        const momentDueDate = moment(`${dueDateWithoutTime} ${currentTime}`);
        let dueDateFromNow = momentDueDate.fromNow();
        if (
          dueDateFromNow.includes('hour') ||
          dueDateFromNow.includes('minute') ||
          dueDateFromNow.includes('second')
        ) {
          dueDateFromNow = intl.get('MY_WORKSPACE.DUE_TASKS.TABLE.TODAY');
        }
        const dueDate = cardTable
          ? dueDateFromNow
          : momentDueDate.format(DATE.SHORT_FORMAT);
        const dDiff = momentDueDate.diff(currentDate, 'days');
        const daysBetween = currentDate.date() - momentDueDate.date();
        let dueDateStyle = '';
        if (dDiff < 0) {
          dueDateStyle = 'text-error-darker';
          if (!cardTable) {
            dueDateStyle = dueDateStyle + ' bg-error-lightest';
          }
        } else if (dDiff === 0 && daysBetween === 0) {
          dueDateStyle = 'text-warning-darker';
        }
        const dueDateComponent = (
          <span className={dueDateStyle}>
            {dueDate.charAt(0).toUpperCase() + dueDate.slice(1)}
          </span>
        );

        return {
          id: task.id,
          'aria-label': `due-task-${task.id}`,
          className: 'table w-full table-fixed cursor-pointer',
          onClick: () =>
            history.push(
              `${PATHS.PROJECT_PAGE}/${get(task, 'project.id')}${PATHS.TASKS}/${
                task.id
              }`,
              { from: PATHS.ROOT }
            ),
          cells: [
            {
              content: (
                <Link
                  to={`${PATHS.PROJECT_PAGE}/${get(task, 'project.id')}${
                    PATHS.TASKS
                  }/${task.id}`}
                  title={task.name}
                >
                  {task.name}
                </Link>
              ),
              className: 'truncate',
            },
            {
              content: (
                <span title={get(task, 'project.title')}>
                  {get(task, 'project.title')}
                </span>
              ),
              className: 'truncate',
            },
            {
              content: cardTable
                ? getStatusPill(task.status)
                : getStatusColumn(task.status),
              className: `${cardTable ? 'w-20' : 'w-28'}`,
              'aria-label': `due-task-status`,
            },
            {
              content: cardTable ? (
                dueDateComponent
              ) : (
                <Tooltip
                  trigger={dueDateComponent}
                  showPopper
                  openMode='hover1'
                  timeout={0}
                  contentProps={{ className: 'z-200000 py-1 px-3' }}
                >
                  {dueDateFromNow.charAt(0).toUpperCase() +
                    dueDateFromNow.slice(1)}
                </Tooltip>
              ),
              className: 'w-36',
            },
          ],
        };
      })}
      handleSort={handleSort}
    />
  );
};

export default TasksTable;
