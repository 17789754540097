export const BASE_URL = '/projects';

export const PROJECT_LIST_TABS_KEYS = {
  ACTIVE_ITEMS: 'activeItems',
  TEAM_ITEMS: 'teamItems',
  MY_ITEMS: 'myItems',
} as const;

export const PROJECT_STATUS = {
  NEW: 'new',
  IN_PLANNING: 'in_planning',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  ON_HOLD: 'on_hold',
  CLOSED: 'closed',
} as const;

export const ACTIVE_PROJECTS_STATUSES = [
  PROJECT_STATUS.NEW,
  PROJECT_STATUS.IN_PLANNING,
  PROJECT_STATUS.IN_PROGRESS,
  PROJECT_STATUS.ON_HOLD,
] as const;

export const INACTIVE_PROJECTS_STATUSES = [
  PROJECT_STATUS.COMPLETED,
  PROJECT_STATUS.CANCELED,
  PROJECT_STATUS.CLOSED,
] as const;

export const PROJECT_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
} as const;

export const PROJECT_HEALTH = {
  ON_TRACK: 'green',
  OFF_TRACK: 'red',
  AT_RISK: 'orange',
} as const;

export const PROJECT_RESOURCING_TYPE = {
  INTERNAL: 'internal',
  VENDOR: 'vendor',
  MIXED: 'mixed',
} as const;

export const PROJECT_PRIVACY = {
  PRIVATE: 'private',
  TEAM: 'team',
  PUBLIC: 'public',
} as const;

export const PROJECT_BUDGET_SOURCE = {
  LD: 'ld_budget',
  BUSINESS: 'business_budget',
  SPECIAL: 'special',
  MIXED: 'mixed',
  OTHERS: 'others',
} as const;

export const ACTIVE_PROJECTS_TABLE_SORTABLE_COLUMNS = {
  PROJECT_NUMBER: 'displayId',
  PROJECT_NAME: 'name',
  STATUS: 'status',
  START_DATE: 'startDate',
  TARGET_COMPLETION_DATE: 'targetCompletionDate',
  UPDATED_AT: 'updatedAt',
  OWNERS: 'owners',
} as const;

export const ACTIVE_PROJECTS_FIELD_PARAM =
  'displayId,name,status,startDate,targetCompletionDate,permissions,updatedAt';
