import React, { useState, useMemo } from 'react';
import { Button, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import ProjectCreationModal from 'Pages/RequestPage/components/ProjectCreationModal';
import LinkProjectsToRequestModal from '../LinkProjectsToRequestModal';
import { isUserOrganizationAdmin, selectUserId } from 'state/User/userSlice';
import {
  REQUEST_ACTIONS,
  REQUEST_STATUS,
  PATHS,
  PENDO_EVENTS,
  QUESTIONNAIRE_TYPE,
} from 'utils/constants';
import { EDITABLE_REQUEST_STATUSES } from 'utils/constants/request';
import { MoreActionsOption, Request, User } from 'utils/customTypes';
import { deleteRequest, downloadRequest } from 'state/Requests/requestSlice';
import {
  editRequest,
  selectActiveRequestSliceStatus,
  selectIsActiveRequestAForm,
} from 'state/ActiveRequest/activeRequestSlice';
import {
  displayNotification,
  setNotificationText,
  setNotificationVariant,
  setNotificationTimeout,
} from 'state/InlineNotification/inlineNotificationSlice';
import CancelRequestModal from '../CancelRequestModal';
import moment from 'moment';
import MoreActions from 'Organisms/MoreActions/MoreActions';
import kanbanCard from 'assets/icons/kanban-card.svg';
import { trackEvent } from 'Services/pendo';
import { formatRequestIdentifier } from 'Pages/helpers';
import DraftPublicSettingsOption from '../DraftPublicSettingsOption';
import DeleteRequestConfirmationModal from '../DeleteRequestConfirmationModal';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { useAppThunkDispatch } from 'state/store';
import useNavigation from 'Hooks/useNavigation';
import RequestStatusMenu from './RequestStatusMenu/RequestStatusMenu';
import { EditableRequestStatus, RequestStatus } from 'utils/types/request';
import RequestStatusTag from 'Atoms/RequestStatusTag/RequestStatusTag';
import { getCurrentLocale } from 'utils/localeHelper';

const TopBar = ({
  toggleComment,
  showComment,
  requestData,
  canEditProperties,
  handleLeavingPageWithUnsavedChanges = () => {},
}: {
  toggleComment: any;
  showComment: boolean;
  requestData: Request;
  canEditProperties: boolean;
  handleLeavingPageWithUnsavedChanges: (path: string) => void;
}) => {
  const dispatch = useAppThunkDispatch();
  const requestId = requestData.id!;
  const handleToggle = () => {
    toggleComment();
  };
  const requestSliceStatus = useSelector(selectActiveRequestSliceStatus);

  const userId = useSelector(selectUserId);
  const isAdmin = useSelector(isUserOrganizationAdmin);
  const isForm = useSelector(selectIsActiveRequestAForm);
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
  const [isLinkRequestModalOpen, setIsLinkRequestModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { goToBackPage } = useNavigation();
  const currentLocale = getCurrentLocale();
  moment.locale(currentLocale);
  const requestIdentifier = useMemo(
    () => formatRequestIdentifier(requestData.requestIdentifier!),
    [requestData]
  );

  const canCreateProject = useMemo<boolean>(
    () => canEditProperties && requestData.status === 'approved',
    [canEditProperties, requestData.status]
  );

  const canComment = useMemo<boolean>(
    () =>
      canEditProperties ||
      requestData.requester_id === userId ||
      requestData.reviewers.some((reviewer: User) => reviewer.id === userId),
    [canEditProperties, requestData.requester_id, requestData.reviewers, userId]
  );

  const canLinkProjects = useMemo<boolean>(
    () => canEditProperties && requestData.status === 'approved',
    [canEditProperties, requestData.status]
  );

  const canCancel = useMemo<boolean>(
    () =>
      requestData.requester_id === userId &&
      (requestData.status === REQUEST_STATUS.SUBMITTED ||
        requestData.status === REQUEST_STATUS.APPROVED),
    [requestData.requester_id, requestData.status, userId]
  );

  const isRequestDraft = useMemo<boolean>(
    () =>
      (requestData.type === QUESTIONNAIRE_TYPE.REQUEST &&
        requestData.status === REQUEST_STATUS.DRAFT) ||
      requestData.type === QUESTIONNAIRE_TYPE.FORM,
    [requestData.type, requestData.status]
  );

  const canDeleteRequest = useMemo<boolean>(
    () => requestData.requester_id === userId || isAdmin,
    [isAdmin, requestData.requester_id, userId]
  );

  const canChangeStatus = useMemo<boolean>(
    () =>
      Boolean(
        canEditProperties &&
          requestData.status &&
          EDITABLE_REQUEST_STATUSES.includes(
            requestData.status as EditableRequestStatus
          ) &&
          !isForm
      ),
    [canEditProperties, isForm, requestData.status]
  );

  const moreActionsOptions: MoreActionsOption[] = useMemo(() => {
    const options: MoreActionsOption[] = [
      {
        value: REQUEST_ACTIONS.DOWNLOAD_REQUEST,
        label: intl.get('REQUEST_PAGE.TOP_BAR.MORE.DOWNLOAD_REQUEST'),
        iconName: 'cloud-download',
        dataCy: 'download_pdf-button',
      },
    ];

    if (canCancel) {
      options.push({
        value: REQUEST_ACTIONS.CANCEL_REQUEST,
        label: intl.get('REQUEST_PAGE.TOP_BAR.MORE.CANCEL_REQUEST'),
        iconName: 'remove-circle',
        dataCy: 'cancel-request-button',
      });
    }

    if (canCreateProject) {
      options.push({
        value: REQUEST_ACTIONS.CREATE_PROJECT,
        label: intl.get('REQUEST_PAGE.TOP_BAR.MORE.CREATE_PROJECT'),
        iconName: 'kanban-card',
        iconSrc: kanbanCard,
        dataCy: 'request-create-project_button',
      });
    }

    if (canLinkProjects) {
      options.push({
        value: REQUEST_ACTIONS.LINK_PROJECT,
        label: intl.get('REQUEST_PAGE.TOP_BAR.MORE.LINK_PROJECT'),
        iconName: 'link-outline',
        dataCy: 'request-link-project-button',
      });
    }
    if (canDeleteRequest) {
      options.push({
        value: REQUEST_ACTIONS.DELETE_REQUEST,
        label: intl.get('REQUEST_PAGE.TOP_BAR.MORE.DELETE_REQUEST'),
        iconName: 'trash',
        dataCy: 'request-delete-button',
        iconClassName: 'text-fire-darker',
      });
    }

    return options;
  }, [canCancel, canCreateProject, canLinkProjects, canDeleteRequest]);

  const downloadRequests = async () => {
    const requestTitle = requestData.title;
    const fileName = `Request_${requestIdentifier}_${requestTitle}`;
    if (requestId) {
      dispatch(downloadRequest({ requestId, fileName }));
    }
  };

  const approveRequest = async ({ comments = '' }: { comments?: string }) => {
    await dispatch(
      editRequest({
        request: requestData,
        updateData: {
          status: REQUEST_STATUS.APPROVED,
          decision_date: moment().format(),
          data: {
            ...requestData.data,
            approval: {
              comments,
            },
          },
        },
      })
    );
    trackEvent(PENDO_EVENTS.REQUEST_APPROVED, {
      title: requestData.title,
    });
    dispatch(
      setNotificationText(
        intl.get('REQUEST_PAGE.TOP_BAR.APPROVE_SUCCESS_MESSAGE', {
          requestNo: requestIdentifier,
        })
      )
    );
    dispatch(setNotificationTimeout(4000));
    dispatch(setNotificationVariant('success'));
    dispatch(displayNotification());
    handleLeavingPageWithUnsavedChanges(PATHS.REQUESTS_LIST_PAGE);
  };

  const onMoveInReview = async () => {
    await dispatch(
      editRequest({
        request: requestData,
        updateData: {
          status: REQUEST_STATUS.SUBMITTED,
        },
      })
    );
    dispatch(
      showSnackbarNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
        notificationMessage: intl.get(
          'REQUEST_PAGE.NOTIFICATIONS.IN_REVIEW_SUCCESS_MESSAGE'
        ),
      })
    );
    handleLeavingPageWithUnsavedChanges(PATHS.REQUESTS_LIST_PAGE);
  };

  const declineRequest = async ({
    reason,
    message = '',
    comments = '',
  }: {
    reason: string;
    message?: string;
    comments?: string;
  }) => {
    await dispatch(
      editRequest({
        request: requestData,
        updateData: {
          status: REQUEST_STATUS.REJECTED,
          decision_date: moment().format(),
          data: {
            ...requestData.data,
            declination: {
              reason,
              details: message,
              comments,
            },
          },
        },
      })
    );
    trackEvent(PENDO_EVENTS.REQUEST_DECLINED, {
      title: requestData.title,
    });
    dispatch(
      setNotificationText(
        intl.get('REQUEST_PAGE.TOP_BAR.DECLINE_SUCCESS_MESSAGE', {
          requestNo: requestIdentifier,
        })
      )
    );
    dispatch(setNotificationTimeout(4000));
    dispatch(setNotificationVariant('success'));
    dispatch(displayNotification());
    handleLeavingPageWithUnsavedChanges(PATHS.REQUESTS_LIST_PAGE);
  };

  const onCancelRequest = async (reason: string, message?: string) => {
    await dispatch(
      editRequest({
        request: requestData,
        updateData: {
          status:
            requestData.status === REQUEST_STATUS.SUBMITTED
              ? REQUEST_STATUS.CANCELED
              : REQUEST_STATUS.PENDING_CANCELLATION,
          data: {
            ...requestData.data,
            cancellation: {
              reason,
              details: message,
            },
          },
        },
      })
    );

    if (requestSliceStatus === 'idle') {
      dispatch(setNotificationVariant('success'));
      dispatch(setNotificationTimeout(4000));
      dispatch(
        setNotificationText(
          intl.get('REQUESTS_LIST_PAGE.NOTIFICATIONS.CANCELLATION_SUCCESS', {
            requestNo: requestIdentifier,
          })
        )
      );
      dispatch(displayNotification());
      handleLeavingPageWithUnsavedChanges(PATHS.REQUESTS_LIST_PAGE);
    }
  };

  const onDeleteRequest = async () => {
    const res = await dispatch(deleteRequest(requestId));
    if ((res as any).error) {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'REQUEST_PAGE.TOP_BAR.DELETE_REQUEST.ERROR'
          ),
          notificationMessage: intl.get(
            'REQUEST_PAGE.TOP_BAR.DELETE_REQUEST.ERROR_MESSAGE'
          ),
        })
      );
      setIsDeleteModalOpen(false);
    } else {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'REQUEST_PAGE.TOP_BAR.DELETE_REQUEST.SUCCESS'
          ),
          notificationMessage: intl.get(
            'REQUEST_PAGE.TOP_BAR.DELETE_REQUEST.SUCCESS_MESSAGE'
          ),
        })
      );
      goToBackPage();
    }
  };

  const onWaitlistRequest = async ({
    conditions,
    details = '',
    comments = '',
  }: {
    conditions: string[];
    details?: string;
    comments?: string;
  }) => {
    await dispatch(
      editRequest({
        request: requestData,
        updateData: {
          status: REQUEST_STATUS.WAITLISTED,
          decision_date: moment().format(),
          data: {
            ...requestData.data,
            waitlisted: {
              conditions,
              comments,
              details,
            },
          },
        },
      })
    );
    trackEvent(PENDO_EVENTS.REQUEST_DECLINED, {
      title: requestData.title,
    });
    dispatch(setNotificationTimeout(4000));
    dispatch(setNotificationVariant('success'));
    dispatch(
      setNotificationText(
        intl.get('REQUEST_PAGE.TOP_BAR.WAITLIST_SUCCESS_MESSAGE', {
          requestNo: requestIdentifier,
        })
      )
    );
    dispatch(displayNotification());
    handleLeavingPageWithUnsavedChanges(PATHS.REQUESTS_LIST_PAGE);
  };

  const handleMoreActions = (value: MoreActionsOption) => {
    if (value.value === REQUEST_ACTIONS.DOWNLOAD_REQUEST) {
      downloadRequests();
    } else if (value.value === REQUEST_ACTIONS.CANCEL_REQUEST) {
      setIsOpenCancelModal(true);
    } else if (value.value === REQUEST_ACTIONS.CREATE_PROJECT) {
      setModalOpen(true);
    } else if (value.value === REQUEST_ACTIONS.LINK_PROJECT) {
      setIsLinkRequestModalOpen(true);
    } else if (value.value === REQUEST_ACTIONS.DELETE_REQUEST) {
      setIsDeleteModalOpen(true);
    }
  };

  const shouldHideActionButtons =
    requestData.status === REQUEST_STATUS.DRAFT || isForm;

  return (
    <React.Fragment>
      <ProjectCreationModal
        isOpen={modalOpen}
        setModalOpen={setModalOpen}
        requestId={requestId}
      />
      <CancelRequestModal
        isOpen={isOpenCancelModal}
        setIsOpen={setIsOpenCancelModal}
        onCancelRequest={onCancelRequest}
      />
      <LinkProjectsToRequestModal
        isOpen={isLinkRequestModalOpen}
        closeModal={() => setIsLinkRequestModalOpen(false)}
        requestId={requestId}
      />
      <DeleteRequestConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onDeleteRequest}
        request={requestData.title!}
      />
      <div
        className={
          'sticky top-0 z-10 flex justify-between space-x-3 items-center'
        }
      >
        <div className='pl-6 flex'>
          {!isForm ? (
            <div className='mt-1'>
              <Typography variant='body2'>
                <span className='font-semibold'>
                  {intl.get('REQUEST_PAGE.TOP_BAR.ID')}
                </span>
                {formatRequestIdentifier(requestData.requestIdentifier!)}
              </Typography>
            </div>
          ) : (
            <Typography variant='body' className='mt-0.5'>
              {get(requestData, 'title', '')}
            </Typography>
          )}
          <div className='ml-2 mt-1'>
            <RequestStatusTag
              status={requestData.status as RequestStatus}
              isForm={isForm}
            />
          </div>
        </div>
        <div className='flex'>
          {isRequestDraft && (
            <DraftPublicSettingsOption requestData={requestData} />
          )}
          {requestData.status && canChangeStatus && (
            <RequestStatusMenu
              currentStatus={requestData.status as EditableRequestStatus}
              approveRequest={approveRequest}
              waitlistRequest={onWaitlistRequest}
              moveInReviewRequest={onMoveInReview}
              declineRequest={declineRequest}
            />
          )}
          {canComment && !shouldHideActionButtons && (
            <Button
              iconName='chatbox'
              variant='tertiary'
              onClick={handleToggle}
              className={classnames('z-10', {
                'bg-neutral-lightest text-primary-darker': showComment,
              })}
              data-testid='comments_topbar-button'
              disabled={isForm}
              size='small'
            >
              {intl.get('REQUEST_PAGE.TOP_BAR.COMMENTS')}
            </Button>
          )}
          {!shouldHideActionButtons && (
            <MoreActions
              options={moreActionsOptions}
              onSelectOption={handleMoreActions}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
