import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';

import usePerformitivRedirection from 'Atoms/PerformitivRedirect/userPerformitivRedirection';
import openIcon from 'assets/icons/open.svg';

const AccessPerformitiv = () => {
  const { loading, redirect } = usePerformitivRedirection();

  return (
    <Button
      variant='secondary'
      iconSrc={openIcon}
      onClick={redirect}
      loading={loading}
      size='small'
    >
      {intl.get('VISIT_PERFORMITIV')}
    </Button>
  );
};

export default AccessPerformitiv;
