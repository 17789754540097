import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { FormItem, Typography } from '@getsynapse/design-system';
import moment from 'moment';
import useModal from 'Hooks/useModal';
import { DATE } from 'utils/constants';
import {
  updateArchiveStatus,
  getCurrentProjectId,
  getProjectDataAttribute,
  resetProject,
} from 'state/Project/projectSlice';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import UnarchiveIcon from 'assets/icons/unarchive.svg';
import { getCurrentLocale } from 'utils/localeHelper';

export interface ArchiveProjectModalProps {
  afterArchiveProject: () => void;
  onCloseModal: () => void;
  shouldDisplayModal: boolean;
}

const ArchiveProjectModal = ({
  afterArchiveProject,
  onCloseModal,
  shouldDisplayModal,
}: ArchiveProjectModalProps) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const projectId: string = useSelector(getCurrentProjectId);
  const isArchived =
    useSelector(getProjectDataAttribute('is_archived')) || false;
  const action = isArchived ? intl.get('UNARCHIVE') : intl.get('ARCHIVE');
  const dispatch = useDispatch();
  const currentLocale = getCurrentLocale();
  const handleCloseModal = useCallback(() => {
    dispatch(resetProject);
    onCloseModal();
    closeModal();
  }, [closeModal, onCloseModal, dispatch]);

  const confirmArchiveProject = useCallback(async () => {
    const nextArchivedStatus = isArchived
      ? intl.get('PROJECT_DETAIL.UNARCHIVED')
      : intl.get('PROJECT_DETAIL.ARCHIVED');
    await dispatch(
      updateArchiveStatus({
        projectId,
        updateFields: { is_archived: !isArchived },
      })
    );
    dispatch(
      showSnackbarNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PROJECT_DETAIL.PROJECT_UPDATE_SUCCESS_SNACKBAR_MESSAGE',
          { action: nextArchivedStatus.toLowerCase() }
        ),
      })
    );
    afterArchiveProject();
    handleCloseModal();
  }, [afterArchiveProject, dispatch, handleCloseModal, projectId, isArchived]);

  useEffect(() => {
    if (shouldDisplayModal && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [modalProps, openModal, shouldDisplayModal]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('ARCHIVE_PROJECT_MODAL.CONFIRM', {
          action,
        }),
        variant: 'primary',
        onClick: confirmArchiveProject,
        'data-testid': 'confirm-button',
      },
      {
        children: intl.get('CANCEL'),
        variant: 'secondary',
        onClick: handleCloseModal,
        'data-testid': 'cancel-button',
      },
    ],
    [confirmArchiveProject, handleCloseModal, action]
  );

  return (
    <Modal
      {...modalProps}
      title={intl.get('ARCHIVE_PROJECT_MODAL.TITLE', {
        action,
      })}
      titleIcon={{
        name: !isArchived ? 'archive' : '',
        className: 'text-purple-dark',
        src: isArchived ? UnarchiveIcon : '',
      }}
      size='medium'
      aria-label={intl.get('ARCHIVE_PROJECT_MODAL.TITLE', { action })}
      childrenClassName='flex flex-col space-y-6'
      data-testid='archive-project-modal'
      closeModal={handleCloseModal}
      actionButtons={actionButtons}
    >
      <>
        <Typography variant='body' data-testid='archive-caption'>
          {!isArchived
            ? intl.get('ARCHIVE_PROJECT_MODAL.CAPTION')
            : intl.get('ARCHIVE_PROJECT_MODAL.UNARCHIVE_CAPTION')}
        </Typography>

        <FormItem
          component='div'
          label={intl.get('ARCHIVE_PROJECT_MODAL.DATE_LABEL', { action })}
          labelProps={{ 'data-testid': 'archive-date-label' }}
        >
          <Typography variant='label' data-testid='archive-date'>
            {moment().locale(currentLocale).format(DATE.LONG_FORMAT)}
          </Typography>
        </FormItem>
      </>
    </Modal>
  );
};

export default ArchiveProjectModal;
