import { SidePanelFilter } from '@getsynapse/design-system';
import useSidePanelFilters from 'Hooks/useSidePanelFilters';
import { ProgramProjectFilters } from 'utils/types/filters';
import FiltersForm from './FiltersForm';
import intl from 'react-intl-universal';
interface ProgramProjectsSidepanelFiltersProps {
  open: boolean;
  onUpdateFilters: (filters: ProgramProjectFilters) => void;
  onClose: () => void;
  savedFilters: ProgramProjectFilters;
}

const ProgramProjectsSidepanelFilters: React.FC<
  ProgramProjectsSidepanelFiltersProps
> = ({ open, onUpdateFilters, onClose, savedFilters }) => {
  const {
    appliedFilters,
    canApplyFilters,
    handleUpdateFilters,
    handleApplyFilters,
    handleClearAllFilters,
    handleClosePanel,
  } = useSidePanelFilters<ProgramProjectFilters>(
    savedFilters,
    onUpdateFilters,
    onClose
  );

  return (
    <SidePanelFilter
      open={open}
      onClose={handleClosePanel}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='program-projects-filters-side-panel'
      applyButtonLabel={intl.get('FILTER_PANEL.APPLY')}
      clearAllButtonLabel={intl.get('FILTER_PANEL.CLEAR_ALL')}
    >
      <FiltersForm
        appliedFilters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
      />
    </SidePanelFilter>
  );
};

export default ProgramProjectsSidepanelFilters;
