import config from 'config/Config';
import { BusinessUnitSummary } from 'types/store/normalized';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { initAxios } from 'utils/axios';

export type BusinessUnitPaginatedResponse = {
  teams: BusinessUnitSummary[];
  links: { next: string };
};

export default abstract class BusinessUnitApi {
  protected readonly axiosInstance: AxiosInstance;

  constructor(url: string) {
    this.axiosInstance = initAxios(url);
  }

  async getById(id: string): Promise<BusinessUnitSummary> {
    const { data } = await this.axiosInstance.get<BusinessUnitSummary>(id);
    return data;
  }

  async getList(
    config?: AxiosRequestConfig
  ): Promise<BusinessUnitPaginatedResponse> {
    const { data } =
      await this.axiosInstance.get<BusinessUnitPaginatedResponse>('', config);

    return data;
  }
}

export class BusinessUnitSummariesApi extends BusinessUnitApi {
  constructor() {
    super(`${config.get('backendURL')}v2/teams/business`);
  }
}
