import { Checkbox, Typography } from '@getsynapse/design-system';
import { useState } from 'react';
import intl from 'react-intl-universal';

export interface NotificationSettingProps {
  category: string;
  name: string;
  groupId: string;
  value: boolean;
  onToggle: (
    category: string,
    trigger: string,
    groupId: string,
    value: boolean
  ) => void;
}

export function NotificationSetting(props: NotificationSettingProps) {
  const { category, name, groupId, value, onToggle } = props;
  const [checked, setChecked] = useState(value);
  return (
    <div className='grid grid-cols-4 my-1' key={name}>
      <div className='flex flex-row flex-grow col-span-3'>
        <Typography weight='medium' className='text-sm whitespace-nowrap'>
          {intl.get(`NOTIFICATIONS_SETTINGS.${category}.GROUPS.${name}.NAME`) ||
            name}
          ,&nbsp;
        </Typography>

        <Typography className='text-sm text-neutral-dark whitespace-nowrap'>
          {intl.get(
            `NOTIFICATIONS_SETTINGS.${category}.GROUPS.${name}.DESCRIPTION`
          )}
        </Typography>
      </div>
      <div className='col-span-1 ml-auto mr-9'>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            onToggle(category, name, groupId, e.target.checked);
            setChecked(e.target.checked);
          }}
          label=''
        />
      </div>
    </div>
  );
}
