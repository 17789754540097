import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';
import type {
  Project,
  ProjectOwner,
  ProjectsListFilters,
} from 'types/store/projectsList';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import { fetchProjectsList } from 'api/projects';
import { deserialize, normalize, buildJsonApiUrl } from './helpers';

interface ProjectsListState {
  filter?: ProjectsListFilters;
  search?: string;
  status?: Status;
  links?: {
    next?: string;
    self?: string;
  };
  sort?: string;
  totalCount?: number;
  isFetchingMore?: boolean;
}

interface FetchProjectsThunkParams {
  includeTotalCount?: boolean;
}

const initialState: ProjectsListState = {};

const projectsAdapter = createEntityAdapter<Project>();
const projectOwner = createEntityAdapter<ProjectOwner>();

export const fetchProjects = createAsyncThunk(
  'projectsList/fetchProjects',
  async (
    { includeTotalCount }: FetchProjectsThunkParams = {},
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const projectsListState = state.projectsList;
      const { isFetchingMore, links, filter, search, sort } = projectsListState;
      let url = '';
      if (isFetchingMore && links?.next) {
        url = links.next;
      } else {
        const params = {
          filter,
          search,
          sort,
        };
        url = buildJsonApiUrl(params);
      }
      const projects = await fetchProjectsList({ url, includeTotalCount });
      const deserializedProjects = await deserialize(projects);
      const normalizedProjects = normalize(deserializedProjects);
      return {
        ...normalizedProjects,
        links: projects.links,
        meta: projects.meta,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

const projectsListSlice = createSlice({
  name: 'projectsList',
  initialState: {
    ...initialState,
    projects: projectsAdapter.getInitialState(),
    projectOwners: projectOwner.getInitialState(),
  },
  reducers: {
    setSearchValue: (state, action) => {
      state.search = action.payload;
    },
    setSortValue: (state, action) => {
      state.sort = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setFetchingMore: (state, action) => {
      state.isFetchingMore = action.payload;
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        const isFetchingMore = state.isFetchingMore;
        const { projects, projectOwners, links, meta } = action.payload;
        if (isFetchingMore) {
          projectsAdapter.upsertMany(state.projects, projects);
          projectOwner.upsertMany(state.projectOwners, projectOwners);
        } else {
          projectsAdapter.setAll(state.projects, projects);
          projectOwner.setAll(state.projectOwners, projectOwners);
        }
        state.isFetchingMore = false;
        state.links = links;
        if (meta?.totalCount) {
          state.totalCount = meta.totalCount;
        }
        state.status = SLICE_STATUS.SUCCESS;
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      });
  },
});

export const { selectAll: selectAllProjects } = projectsAdapter.getSelectors(
  (state: RootState) => state.projectsList.projects
);
export const {
  selectAll: selectAllProjectOwners,
  selectById: selectProjectOwnerById,
} = projectOwner.getSelectors(
  (state: RootState) => state.projectsList.projectOwners
);

export const selectProjectsListStatus = (state: RootState) =>
  state.projectsList.status;

export const selectProjectOwnersById = (state: RootState, owners: string[]) => {
  const projectOwners: ProjectOwner[] = [];
  for (const id of owners) {
    const owner = selectProjectOwnerById(state, id);
    if (owner) {
      projectOwners.push(owner);
    }
  }
  return projectOwners;
};

export const selectCanFetchMoreProjects = (state: RootState) => {
  if (!state.projectsList.links) {
    return false;
  }
  return !!state.projectsList.links.next;
};

export const selectSearchValue = (state: RootState) =>
  state.projectsList.search;

export const selectSortValue = (state: RootState) => state.projectsList.sort;

export const selectTotalProjectsCount = (state: RootState) =>
  state.projectsList.totalCount;

export const selectLoadedProjectsCount = (state: RootState) =>
  state.projectsList.projects.ids.length;

export const selectIsLoading = (state: RootState) => {
  return (
    (!state.projectsList.status ||
      state.projectsList.status === SLICE_STATUS.LOADING) &&
    !state.projectsList.isFetchingMore
  );
};

export const { setSearchValue, setSortValue, setFilter, setFetchingMore } =
  projectsListSlice.actions;

export default projectsListSlice.reducer;
