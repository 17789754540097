import { useState } from 'react';
import { Modal, Dropdown, FormItem } from '@getsynapse/design-system';
import { useLanguage } from './LanguageContext'; // Import the custom context hook for language switching
import intl from 'react-intl-universal'; // Import the react-intl-universal library for internationalization
// Define the type for language options
type LanguageOption = {
  label: string;
  value: string;
};

// Define the language options that the user can select from
const languageOptions: LanguageOption[] = [
  { label: 'English', value: 'en' },
  { label: 'French (Canada)', value: 'fr' },
];

// Props for LanguageModal
interface LanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LanguageModal = ({ isOpen, onClose }: LanguageModalProps) => {
  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageOption | null>(null);

  // Access the switchLanguage function from LanguageContext to apply the selected language
  const { switchLanguage } = useLanguage();

  // Handle language selection in the dropdown
  const onLanguageChange = (option: LanguageOption) => {
    setSelectedLanguage(option);
  };

  // Confirm the selected language and apply it using switchLanguage from the context
  const confirmLanguageSelection = () => {
    if (selectedLanguage) {
      switchLanguage(selectedLanguage.value); // Update the language in the context
    }
    onClose(); // Call the onClose function passed from the parent component to close the modal
  };

  return (
    <Modal
      aria-label='Select Language'
      isOpen={isOpen}
      closeModal={onClose} // Use the onClose function from props to close the modal
      title={intl.get('LANGUAGE_MODAL.TITLE')}
      size='small'
      actionButtons={[
        {
          children: intl.get('LANGUAGE_MODAL.CONFIRM'),
          onClick: confirmLanguageSelection,
          variant: 'primary',
          disabled: !selectedLanguage, // Disable Confirm button if no language is selected
        },
        {
          children: intl.get('LANGUAGE_MODAL.CANCEL'),
          onClick: onClose, // Close the modal when Cancel is clicked
          variant: 'tertiary',
        },
      ]}
    >
      {/* <Typography>Select your preferred language</Typography> */}
      <FormItem>
        <Dropdown
          options={languageOptions}
          onChange={onLanguageChange}
          triggerProps={{
            placeholder: selectedLanguage
              ? selectedLanguage.label
              : intl.get('LANGUAGE_MODAL.PLACEHOLDER'),
          }}
          listProps={{ 'data-cy': 'language-options' }}
        />
      </FormItem>
    </Modal>
  );
};

export default LanguageModal;
