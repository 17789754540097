import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import truncate from 'lodash/truncate';
import { Button } from '@getsynapse/design-system';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import {
  fetchStrategyGoalDetail,
  resetGoal,
  selectStrategyGoalDetail,
  selectStrategyGoalDetailStatus,
  selectStrategyGoalDetailErrorCode,
} from 'state/SingleStrategyGoal/SingleStrategyGoalSlice';
import {
  fetchGoalProjects,
  selectFormattedGoalProjectsMetrics,
  selectGoalProjectsStatus,
} from 'state/GoalProjects/GoalProjectsSlice';
import LoadingError from 'Molecules/LoadingError/LoadingError';
import { SLICE_STATUS } from 'utils/constants';
import DeleteGoalModal from './components/DeleteGoalModal/DeleteGoalModal';
import GoalDetails from './components/GoalDetails//GoalDetails';
import GoalProjectsTable from './components/GoalProjectsTable/GoalProjectsTable';
import GoalMetrics from './components/GoalMetrics/GoalMetrics';
import Skelton from 'Atoms/Skelton/Skelton';
import {
  DetailsSkeleton,
  MetricsSkeleton,
  TableSkeleton,
} from './components/SkeletonLoaders/SkeletonLoaders';

const GoalDetailsPage = () => {
  const dispatch = useDispatch();

  const { goalId } = useParams<{
    goalId: string;
  }>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (goalId) {
      dispatch(fetchStrategyGoalDetail(goalId));
      dispatch(fetchGoalProjects({ goalId }));
    }
    return () => {
      dispatch(resetGoal());
    };
  }, [dispatch, goalId]);

  const strategyGoalDetailStatus = useSelector(selectStrategyGoalDetailStatus);
  const strategyGoalDetailError = useSelector(
    selectStrategyGoalDetailErrorCode
  );
  const strategyGoalDetail = useSelector(selectStrategyGoalDetail);
  const projectMetrics = useSelector(selectFormattedGoalProjectsMetrics);
  const isProjectsLoading =
    useSelector(selectGoalProjectsStatus) === SLICE_STATUS.LOADING;

  const isLoading = strategyGoalDetailStatus === SLICE_STATUS.LOADING;
  const hasError = strategyGoalDetailStatus === SLICE_STATUS.FAILED;
  const isGoalDetailEmpty = Object.keys(strategyGoalDetail).length === 0;

  return (
    <>
      <DeleteGoalModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        goalId={goalId}
      />
      {hasError && (
        <LoadingError headerHeight='6rem' errorCode={strategyGoalDetailError} />
      )}
      {!hasError && (
        <div className='h-full flex flex-col'>
          {isLoading ? (
            <Skelton className='h-7 w-2/5 mx-6 mt-2.5' />
          ) : (
            !isGoalDetailEmpty && (
              <PageTitle
                titleComponent={truncate(strategyGoalDetail.title, {
                  length: 100,
                })}
                dataCy='goal-title'
                className='leading-6'
                headerChildren={
                  strategyGoalDetail.permissions.canDelete && (
                    <Button
                      variant='tertiary'
                      iconName='trash'
                      data-testid='delete-goal-button'
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      {intl.get('DELETE')}
                    </Button>
                  )
                }
              />
            )
          )}
          <div className='h-full px-6 pt-2.5 pb-6 w-full flex flex-row'>
            <div className='flex flex-col w-3/4 mr-6'>
              {isProjectsLoading ? (
                <>
                  <MetricsSkeleton />
                  <TableSkeleton />
                </>
              ) : (
                <>
                  <GoalMetrics metrics={projectMetrics} />
                  <GoalProjectsTable goalId={goalId} />
                </>
              )}
            </div>
            {isLoading ? <DetailsSkeleton /> : <GoalDetails />}
          </div>
        </div>
      )}
    </>
  );
};

export default GoalDetailsPage;
