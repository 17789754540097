import ReactECharts from 'echarts-for-react';
import { remToPx } from '../../helpers/helper';

const TasksChart = ({
  tasksCompletedPercentage,
  tasksCompletedPercentageText,
}: {
  tasksCompletedPercentageText: string;
  tasksCompletedPercentage: number;
}) => {
  const option = {
    title: {
      text: tasksCompletedPercentageText,
      left: 'center',
      top: 'center',
      right: 'center',
      textStyle: {
        fontSize: remToPx(0.875),
        lineHeight: remToPx(1.5),
        fontWeight: 'bold',
        fontFamily: 'proxima-nova',
        color: '#324045',
      },
    },
    color: ['#327569', '#E1E9EC'],
    series: [
      {
        type: 'pie',
        radius: ['75%', '95%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        data: [
          { value: tasksCompletedPercentage, name: 'Tasks Completed' },
          { value: 100 - tasksCompletedPercentage, name: 'Total Tasks' },
        ],
      },
    ],
    graphic: {
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        fontSize: remToPx(1),
        fontWeight: 'bold',
      },
    },
  };

  return (
    <div data-testid='progress-percentage' className='mr-2'>
      <ReactECharts
        option={option}
        style={{ width: remToPx(3.5), height: remToPx(3.5) }}
      />
    </div>
  );
};

export default TasksChart;
