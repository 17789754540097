import { RequestsApi } from './requests';
import { UserSummariesApi } from './userSummaries';
import { FormSummariesApi } from './formSummaries';
import { ProjectSummariesApi } from './projectSummaries';
import { ProjectPermissionsApi } from './projectPermissions';
import { BusinessUnitSummariesApi } from './businessUnitSummaries';

export class BaseApiFactory {
  private static requestsApi: RequestsApi;
  private static userSummariesApi: UserSummariesApi;
  private static formSummariesApi: FormSummariesApi;
  private static projectSummariesApi: ProjectSummariesApi;
  private static projectPermissionsApi: ProjectPermissionsApi;
  private static businessUnitSummariesApi: BusinessUnitSummariesApi;

  private constructor() {}

  static getRequestsApi(): RequestsApi {
    if (!this.requestsApi) {
      this.requestsApi = new RequestsApi();
    }
    return this.requestsApi;
  }

  static getUserSummariesApi(): UserSummariesApi {
    if (!this.userSummariesApi) {
      this.userSummariesApi = new UserSummariesApi();
    }
    return this.userSummariesApi;
  }

  static getFormSummariesApi(): FormSummariesApi {
    if (!this.formSummariesApi) {
      this.formSummariesApi = new FormSummariesApi();
    }
    return this.formSummariesApi;
  }

  static getProjectSummariesApi(): ProjectSummariesApi {
    if (!this.projectSummariesApi) {
      this.projectSummariesApi = new ProjectSummariesApi();
    }
    return this.projectSummariesApi;
  }

  static getProjectPermissionsApi(): ProjectPermissionsApi {
    if (!this.projectPermissionsApi) {
      this.projectPermissionsApi = new ProjectPermissionsApi();
    }
    return this.projectPermissionsApi;
  }

  static getBusinessUnitsApi(): BusinessUnitSummariesApi {
    if (!this.businessUnitSummariesApi) {
      this.businessUnitSummariesApi = new BusinessUnitSummariesApi();
    }
    return this.businessUnitSummariesApi;
  }
}
