import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import {
  ObjectiveMutableProps,
  NewObjective,
  NewCollectionMethod,
  UpdatedQuestion,
  EvaluationEvidenceFilePostData,
  NewAdditionalBenefit,
} from 'utils/types/program';

class CategoryAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  getCategory = async (programId: string, categoryId: string) => {
    const { data } = await this.instance.get(
      `v2/programs/${programId}/categories/${categoryId}`
    );
    return data;
  };

  updateObjective = async (
    programId: string,
    objectiveId: string,
    name?: ObjectiveMutableProps['name'],
    is_met?: ObjectiveMutableProps['is_met'],
    not_met_reason?: ObjectiveMutableProps['not_met_reason']
  ) => {
    const { data } = await this.instance.put(
      `/program/${programId}/updateProgramObjective/${objectiveId}`,
      { is_met, name, not_met_reason }
    );
    return data;
  };

  deleteObjective = async (
    programId: string,
    objectiveId: string,
    categoryId: string
  ) => {
    const response = await this.instance.delete(
      `v2/programs/${programId}/categories/${categoryId}/objectives/${objectiveId}`
    );

    if (response instanceof Error) {
      throw response;
    } else {
      return response.data;
    }
  };

  getCategoryObjectiveTemplates = async (
    programId: string,
    categoryId: string
  ) => {
    const { data } = await this.instance.get(
      `/program/${programId}/objectiveTemplates/${categoryId}`
    );
    return data;
  };

  setObjectives = async (programId: string, newObjectives: NewObjective[]) => {
    const { data } = await this.instance.post(
      `/program/${programId}/createProgramObjectives`,
      {
        data: {
          objectives: newObjectives,
        },
      }
    );
    return data;
  };

  createAdditionalBenefits = async (
    programId: string,
    categoryId: string,
    newBenefits: NewAdditionalBenefit[]
  ) => {
    const { data } = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/additional_benefits`,
      newBenefits
    );

    return data;
  };

  getEvaluationPlan = async (programId: string, categoryId: string) => {
    const { data } = await this.instance.get(
      `/program/${programId}/retrieveCollectionMethodsAnswers/${categoryId}`
    );
    return data;
  };

  getCategoryEvaluationEvidences = async (
    programId: string,
    categoryId: string
  ) => {
    const { data } = await this.instance.get(
      `v2/programs/${programId}/categories/${categoryId}/evidence`
    );
    return data;
  };

  createCategoryEvaluationEvidence = async (
    programId: string,
    categoryId: string,
    fileData: EvaluationEvidenceFilePostData
  ) => {
    const { data } = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/evidence`,
      fileData
    );
    return data;
  };

  deleteCategoryEvaluationEvidences = async (
    programId: string,
    categoryId: string,
    evidenceId: string
  ) => {
    const response = await this.instance.delete(
      `v2/programs/${programId}/categories/${categoryId}/evidence/${evidenceId}`
    );
    return response.status;
  };

  getCollectionMethodAndOptions = async (categoryId: string) => {
    const { data } = await this.instance.get(
      `/program/programStrategy/evaluationPlanOptions/${categoryId}`
    );
    return data;
  };

  bulkCreateCollectionMethodsWithAnswers = async (
    programId: string,
    categoryId: string,
    dataCollectionMethods: NewCollectionMethod[]
  ) => {
    const { data } = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/collectionMethods`,
      dataCollectionMethods
    );
    return data;
  };

  updateQuestionsAnswers = async (
    programId: string,
    categoryId: string,
    collectionMethodAnswerId: string,
    updatedQuestions: UpdatedQuestion[]
  ) => {
    const { data } = await this.instance.put(
      `v2/programs/${programId}/categories/${categoryId}/collectionMethods/${collectionMethodAnswerId}`,
      updatedQuestions
    );
    return data;
  };

  deleteCollectionMethod = async (
    programId: string,
    categoryId: string,
    dataCollectionMethodId: string
  ) => {
    const { data } = await this.instance.delete(
      `v2/programs/${programId}/categories/${categoryId}/collectionMethods/${dataCollectionMethodId}`
    );
    return data;
  };
}

const categoryAPI = new CategoryAPI();

export default categoryAPI;
