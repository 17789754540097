import { Typography, tailwindOverride } from '@getsynapse/design-system';
import UserAvatar from 'Atoms/UserAvatar';
import { isUserStatusDisabled } from 'utils/functions';

export type TableAvatarType = {
  avatar_url?: string;
  data: {
    firstName: string;
    lastName: string;
    email: string;
  };
  status?: string;
};

type TableAvatarProps = {
  user: TableAvatarType;
  'data-cy'?: string;
};
const TableAvatar = ({ user, 'data-cy': dataCy }: TableAvatarProps) => {
  return (
    <div className='flex items-start' data-cy={dataCy || 'table-avatar'}>
      <UserAvatar user={user} />
      <div className='pl-2'>
        <Typography
          variant='body'
          className={tailwindOverride('text-primary font-semibold', {
            'text-neutral-light': isUserStatusDisabled(user.status),
          })}
        >
          {`${user.data.firstName} ${user.data.lastName}`}
        </Typography>
        <Typography variant='body2' className='text-neutral'>
          {user.data.email}
        </Typography>
      </div>
    </div>
  );
};

export default TableAvatar;
