import intl from 'react-intl-universal';
import { ProgressBar } from '@getsynapse/design-system';
import classNames from 'classnames';

export const getProgress = (
  totalTasks: number,
  completedTasks: number,
  barClassName: string
) => (
  <ProgressBar
    maxValue={totalTasks}
    minValue={0}
    currentValue={completedTasks}
    percentageNumberClassName='hidden'
    barClassName={classNames('flex-none order-1 h-2', barClassName)}
    label={intl.get('STRATEGY_GOALS.LINKED_PROJECTS.TASKS')}
    progressClassName='bg-secondary-dark h-2'
    labelClassName='text-xs order-2 ml-1'
    showPercentageTooltip
    data-testid='progress-bar'
  />
);

export const calculatePercentage = (completed: number, total: number) => {
  return 0 === total ? 0 : Math.floor((completed / total) * 100);
};

export const remToPx = (rem: number) =>
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
