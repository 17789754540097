import { FormItem, TextField } from '@getsynapse/design-system';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import intl from 'react-intl-universal';
import { Option } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';
import { isArrayOfOptions } from 'utils/typeGuards';
import { UserFilters } from 'utils/types/filters';
import useUserFilters from '../hooks/useUserFilters';
import { USER_TABLE_FILTERS } from 'utils/constants/user';

interface FiltersFormProps {
  appliedFilters: UserFilters;
  onUpdateFilters: (newFilters: UserFilters) => void;
}

type UpdateFiltersCallback = (key: string, value: Option[] | string) => void;

const FiltersForm: React.FC<FiltersFormProps> = ({
  appliedFilters,
  onUpdateFilters,
}) => {
  const {
    userTypeOptions,
    teamOptions,
    employmentTypeOptions,
    countryOptions,
    statusOptions,
  } = useUserFilters(appliedFilters);

  const updateFilters: UpdateFiltersCallback = (key, value) => {
    const updatedFilters: UserFilters = { ...appliedFilters };
    if (isArrayOfOptions(value) || typeof value === 'string') {
      if (value.length === 0) {
        delete updatedFilters[key];
      } else if (typeof value === 'string') {
        updatedFilters[key] = [value];
      } else {
        updatedFilters[key] = value.map((option) => option.value);
      }
    }
    onUpdateFilters(updatedFilters);
  };

  return (
    <div className='mt-8 mb-12 flex flex-col space-y-5'>
      <FormItem label={intl.get('USERS_PAGE.TABLE.HEAD.USER_TYPE')}>
        <MultiSelectDropdown
          options={userTypeOptions}
          placeholder={intl.get('SELECT')}
          values={getInitialValueForDropDown(
            userTypeOptions,
            appliedFilters[USER_TABLE_FILTERS.TYPE] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(USER_TABLE_FILTERS.TYPE, options)
          }
          triggerProps={{
            'aria-label': intl.get('USERS_PAGE.TABLE.HEAD.USER_TYPE'),
            'data-cy': 'users-filters__type-picker',
          }}
          listProps={{ 'data-cy': 'users-filters__type__options-list' }}
        />
      </FormItem>

      <FormItem label={intl.get('USERS_PAGE.TABLE.HEAD.TEAM')}>
        <MultiSelectDropdown
          options={teamOptions}
          placeholder={intl.get('SELECT')}
          values={getInitialValueForDropDown(
            teamOptions,
            appliedFilters[USER_TABLE_FILTERS.TEAM] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(USER_TABLE_FILTERS.TEAM, options)
          }
          triggerProps={{
            'aria-label': intl.get('USERS_PAGE.TABLE.HEAD.TEAM'),
            'data-cy': 'users-filters__team-picker',
          }}
          listProps={{ 'data-cy': 'users-filters__team__options-list' }}
        />
      </FormItem>

      <FormItem label={intl.get('USERS_PAGE.TABLE.HEAD.JOB_TITLE')}>
        <TextField
          placeholder={intl.get('FILTER_GENERAL.TEXT_VALUE_PLACEHOLDER')}
          value={appliedFilters[USER_TABLE_FILTERS.JOB_TITLE] || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateFilters(USER_TABLE_FILTERS.JOB_TITLE, event.target.value)
          }
          data-testid='users-filters__job-title-input'
        />
      </FormItem>

      <FormItem label={intl.get('USERS_PAGE.TABLE.HEAD.EMPLOYMENT_TYPE')}>
        <MultiSelectDropdown
          options={employmentTypeOptions}
          placeholder={intl.get('SELECT')}
          values={getInitialValueForDropDown(
            employmentTypeOptions,
            appliedFilters[USER_TABLE_FILTERS.EMPLOYMENT_TYPE] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(USER_TABLE_FILTERS.EMPLOYMENT_TYPE, options)
          }
          triggerProps={{
            'aria-label': intl.get('USERS_PAGE.TABLE.HEAD.EMPLOYMENT_TYPE'),
            'data-cy': 'users-filters__employment-picker',
          }}
          listProps={{ 'data-cy': 'users-filters__employment__options-list' }}
        />
      </FormItem>

      <FormItem
        label={intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.COUNTRY')}
      >
        <MultiSelectDropdown
          options={countryOptions}
          placeholder={intl.get('SELECT')}
          values={getInitialValueForDropDown(
            countryOptions,
            appliedFilters[USER_TABLE_FILTERS.COUNTRY] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(USER_TABLE_FILTERS.COUNTRY, options)
          }
          triggerProps={{
            'aria-label': intl.get(
              'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.COUNTRY'
            ),
            'data-cy': 'users-filters__country-picker',
          }}
          listProps={{ 'data-cy': 'users-filters__country__options-list' }}
        />
      </FormItem>

      <FormItem label={intl.get('USERS_PAGE.TABLE.HEAD.ACCOUNT_STATUS')}>
        <MultiSelectDropdown
          options={statusOptions}
          placeholder={intl.get('SELECT')}
          values={getInitialValueForDropDown(
            statusOptions,
            appliedFilters[USER_TABLE_FILTERS.STATUS] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(USER_TABLE_FILTERS.STATUS, options)
          }
          triggerProps={{
            'aria-label': intl.get('USERS_PAGE.TABLE.HEAD.ACCOUNT_STATUS'),
            'data-cy': 'users-filters__status-picker',
          }}
          listProps={{ 'data-cy': 'users-filters__status__options-list' }}
        />
      </FormItem>
    </div>
  );
};

export default FiltersForm;
