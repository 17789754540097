import { Project } from 'utils/customTypes';
import { useHistory } from 'react-router-dom';
import {
  Tooltip,
  Typography,
  useElevation,
  Table,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { getStatusColumn } from '../../ProjectsListPage/helpers/tableColumnsValues';
import { ProjectWithPermissions } from 'types/store/normalized';
import get from 'lodash/get';
import classNames from 'classnames';
import { PATHS } from 'utils/constants';

const LinkedProjectsTooltip = ({
  projects,
  numberOfProjects,
}: {
  projects: Project[] | ProjectWithPermissions[];
  numberOfProjects: string;
}) => {
  const history = useHistory();

  return (
    <Tooltip
      openMode='hover2'
      position='bottomLeft'
      timeout={100}
      hasMaxWidth={false}
      contentProps={{
        className: `w-100 bg-neutral-white text-neutral-black p-6 z-10 cursor-default ${useElevation(
          3
        )}`,
      }}
      showPopper
      className='w-full h-full'
      trigger={
        <div className='w-full h-full flex items-center'>
          {numberOfProjects}
        </div>
      }
    >
      <div
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          event.stopPropagation()
        }
      >
        <Typography variant='h6' className='mb-4'>
          {intl.get('REQUESTS_LIST_PAGE.TABLE.LINKED_PROJECTS.TITLE')}
        </Typography>
        <div className='max-h-60 overflow-y-auto w-88'>
          <Table
            canSelectRows={false}
            className='w-full'
            numberOfHeadRows={0}
            data={{
              headData: [],
              rows: projects.map((project, index) => {
                const canView = get(project, 'permissions.canView', false);

                return {
                  onClick: () =>
                    canView &&
                    history.push(`${PATHS.PROJECT_PAGE}/${project.id}`),
                  className: classNames({
                    'cursor-not-allowed': !canView,
                    'cursor-pointer': canView,
                  }),
                  cells: [
                    {
                      content: canView ? (
                        <a
                          href={`${PATHS.PROJECT_PAGE}/${project.id}`}
                          className='w-52 truncate inline-block'
                          data-testid={`project-link-${index}`}
                        >
                          {project.title}
                        </a>
                      ) : (
                        <div className='w-52 truncate'>{project.title}</div>
                      ),
                      className:
                        'group-hover:shadow-cancel group-hover:border-transparent',
                    },
                    {
                      content: getStatusColumn(
                        project.status,
                        false,
                        classNames('group-hover:border-transparent', {
                          'cursor-not-allowed': !canView,
                          'cursor-pointer': canView,
                        })
                      ),
                      'data-testid': `project-status-${index}`,
                      className:
                        'group-hover:shadow-cancel group-hover:border-transparent',
                    },
                  ],
                };
              }),
            }}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default LinkedProjectsTooltip;
