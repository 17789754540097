import intl from 'react-intl-universal';
import React, { useEffect, useState } from 'react';
import { SidePanelFilter } from '@getsynapse/design-system';
import { RequestFilters } from 'utils/types/filters';
import FiltersForm from './FiltersForm';
import ActiveRequestsFiltersForm from './ActiveRequestsFiltersForm';
import { REQUESTS_TABLE } from 'utils/constants';

const RequestsSidePanelFilters: React.FC<{
  open: boolean;
  onUpdateFilters: (filters: RequestFilters) => void;
  onClose: () => void;
  filters: RequestFilters;
  tableName?: string;
}> = ({ onClose, open, filters, onUpdateFilters, tableName }) => {
  const [appliedFilters, setAppliedFilters] = useState<RequestFilters>({});
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters);
    }
  }, [filters]);

  const handleUpdateFilters = (updatedFilters: RequestFilters) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClearAllFilters = () => {
    onUpdateFilters({});
    setAppliedFilters({});
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={onClose}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='requests-filters-side-panel'
      applyButtonLabel={intl.get('FILTER_PANEL.APPLY')}
      clearAllButtonLabel={intl.get('FILTER_PANEL.CLEAR_ALL')}
    >
      {tableName === REQUESTS_TABLE.ACTIVE_REQUESTS ? (
        <ActiveRequestsFiltersForm
          filters={appliedFilters}
          onUpdateFilters={handleUpdateFilters}
        />
      ) : (
        <FiltersForm
          filters={appliedFilters}
          onUpdateFilters={handleUpdateFilters}
        />
      )}
    </SidePanelFilter>
  );
};

export default RequestsSidePanelFilters;
