import intl from 'react-intl-universal';

import PageTitle from 'Molecules/PageTitle/PageTitle';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import EvaluationsTable from './EvaluationsTable/EvaluationsTable';
import AccessPerformitiv from './AccessPerformitiv/AccessPerformitiv';
import ServerPagination from 'Organisms/Pagination/ServerPagination';
import useEvaluationsPage from './useEvaluationsPage';

const EvaluationsPage = () => {
  const { onPageChange, isLoading, page, total } = useEvaluationsPage();

  return (
    <>
      <PageTitle
        titleComponent={intl.get('ENTITIES.EVALUATION', { num: 2 })}
        headerChildren={<AccessPerformitiv />}
      />
      <div className='mt-4 px-6 overflow-y-auto'>
        {isLoading && <SkeletonLoader />}
        {!isLoading && (
          <>
            <EvaluationsTable />
            <ServerPagination
              total={total}
              onChange={onPageChange}
              page={page}
            />
          </>
        )}
      </div>
    </>
  );
};

export default EvaluationsPage;
