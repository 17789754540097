import axios from 'axios';
import { api } from './api';
import { Project } from 'types/store/projectsList';
import { JsonApiResponse } from 'types/store/jsonApi';

interface FetchProjectsListParams {
  url: string;
  includeTotalCount?: boolean;
}

export const fetchProjectsList: (
  params: FetchProjectsListParams
) => Promise<JsonApiResponse<Project>> = async ({ url, includeTotalCount }) => {
  const config = {
    headers: {
      'include-total-count': includeTotalCount ? 'true' : 'false',
    },
  };

  const response = await api.get<JsonApiResponse<Project>>(url, config);

  if (axios.isAxiosError(response)) {
    throw response;
  }

  return response.data;
};
