import { FC } from 'react';
import { Request } from 'types/store/normalized';
import RequestTableRow from './RequestTableRow/RequestTableRow';
import RequestTableLastRow from './RequestTableLastRow';

interface RequestsTableBodyProps {
  requests: Request[];
  canFetchMore: boolean;
  fetchMore: () => void;
}

const RequestsTableBody: FC<RequestsTableBodyProps> = ({
  requests,
  canFetchMore,
  fetchMore,
}) => {
  const isListEmpty = requests.length === 0;

  return (
    <tbody>
      {requests.map((request: Request, index: number) => {
        const isOdd = index % 2 !== 0;
        return (
          <RequestTableRow key={request.id} request={request} isOdd={isOdd} />
        );
      })}
      {!isListEmpty && canFetchMore && (
        <RequestTableLastRow
          isOdd={requests.length % 2 !== 0}
          tableName={''}
          onLastRowInView={fetchMore}
        />
      )}
    </tbody>
  );
};

export default RequestsTableBody;
