import {
  Dispatch,
  SetStateAction,
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Request, SortingType, TableExportOptions } from 'utils/customTypes';
import { TABLE_EXPORT_OPTIONS, REQUESTS_TABLE } from 'utils/constants';
import { RequestFilters } from 'utils/types/filters';
import {
  selectTableSearchParam,
  setFilters,
  updateTeamRequestPagination,
  getTeamRequestsFetchStatus,
} from 'state/Requests/requestSlice';
import {
  selectFiltersSettingsByType,
  createFilterSetting,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import Pagination from 'Organisms/Pagination/ClientPagination';
import RequestsTable from './RequestsTable';
import RequestsTableHeader from './RequestsTableHeader';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

type TeamRequestsTableProps = {
  allRequests: {
    data: Request[];
    total: number;
    all: Request[];
  };
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setModalProps: Dispatch<SetStateAction<{}>>;
  handleSort: (orderByParam: string, order: SortingType) => void;
  onExportRequests: (requestIds: string[], callback: () => void) => void;
  onExportRequestsByForm: (requestIds: string[], callback: () => void) => void;
};

const TeamRequestsTable = ({
  allRequests,
  setModalOpen,
  setModalProps,
  handleSort,
  onExportRequests,
  onExportRequestsByForm,
}: TeamRequestsTableProps) => {
  const dispatch = useDispatch();
  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
  const fetchTeamRequestsStatus = useSelector(getTeamRequestsFetchStatus);
  const tableSearchParam = useSelector(
    selectTableSearchParam(REQUESTS_TABLE.TEAM_REQUESTS)
  );
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(REQUESTS_TABLE.TEAM_REQUESTS)
  );

  const appliedFilters = useMemo(
    () =>
      filtersSettings
        ? (filtersSettings.settings as RequestFilters)
        : ({} as RequestFilters),
    [filtersSettings]
  );

  useEffect(() => {
    if (appliedFilters) {
      dispatch(setFilters(appliedFilters, REQUESTS_TABLE.TEAM_REQUESTS));
    }
  }, [appliedFilters, dispatch]);

  const allTeamRequestsIds = useMemo(
    () => allRequests.all.map((request: Request) => request.id!),
    [allRequests.all]
  );

  const currentPageTeamRequestsIds = useMemo(
    () => allRequests.data.map((request: Request) => request.id!),
    [allRequests.data]
  );

  const handleExportRequests = (callback: () => void) => {
    onExportRequests(selectedRequests, callback);
  };

  const handleExportRequestsByForm = (callback: () => void) => {
    onExportRequestsByForm(selectedRequests, callback);
  };

  const handleSelectExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedRequests(allTeamRequestsIds);
      return;
    }
    if (exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE) {
      setSelectedRequests(currentPageTeamRequestsIds);
      return;
    }
    setSelectedRequests([]);
  };

  const fetchRequestWithPagination = useCallback(
    (params) => {
      dispatch(updateTeamRequestPagination(params));
    },
    [dispatch]
  );

  const handleSaveFiltersSettings = (filters: RequestFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: REQUESTS_TABLE.TEAM_REQUESTS,
          filter_settings: filters,
        })
      );
    }
  };

  if (fetchTeamRequestsStatus === 'loading') {
    return <SkeletonLoader data-cy='team-requests-table-skeleton-loader' />;
  }

  return (
    <Fragment>
      <RequestsTableHeader
        requestsTable={REQUESTS_TABLE.TEAM_REQUESTS}
        exportEnabled={!isEmpty(selectedRequests)}
        onExportHandler={handleExportRequests}
        onExportByFormHandler={handleExportRequestsByForm}
        appliedFilters={appliedFilters}
        tableSearchParam={tableSearchParam}
        saveFiltersSettings={handleSaveFiltersSettings}
      />
      <RequestsTable
        requestsTable={REQUESTS_TABLE.TEAM_REQUESTS}
        data={allRequests.data}
        totalRequests={allRequests.total}
        setModalOpen={setModalOpen}
        setModalProps={setModalProps}
        setSelectedRequests={setSelectedRequests}
        selectedRequests={selectedRequests}
        handleSort={handleSort}
        isSearch={tableSearchParam !== ''}
        areFiltersApplied={Object.keys(appliedFilters).length > 0}
        onSelectExportOption={handleSelectExportOption}
        showFilter={Object.keys(appliedFilters).length > 0}
      />
      <Pagination
        total={allRequests.total}
        onChange={fetchRequestWithPagination}
      />
    </Fragment>
  );
};

export default TeamRequestsTable;
