import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class ROICategoyAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/programs/`);
  }

  fetchROICategory = async (programId: string, categoryId: string) => {
    const res = await this.instance.get(
      `${programId}/categories/${categoryId}/roi`
    );
    return res;
  };

  createROIObjective = async (
    programId: string,
    categoryId: string,
    roi: number
  ) => {
    const { data: response } = await this.instance.post(
      `${programId}/categories/${categoryId}/roi`,
      { roi }
    );
    return response;
  };

  updateROIObjective = async (
    programId: string,
    categoryId: string,
    roiId: string,
    roi: number
  ) => {
    const { data: response } = await this.instance.patch(
      `${programId}/categories/${categoryId}/roi/${roiId}`,
      { roi }
    );
    return response;
  };

  deleteROIObjective = async (
    programId: string,
    categoryId: string,
    objectiveId: string
  ) => {
    const response = await this.instance.delete(
      `${programId}/categories/${categoryId}/roi/${objectiveId}`
    );
    return response;
  };
}

const roiCategoryAPI = new ROICategoyAPI();

export default roiCategoryAPI;
