export const REQUEST_STATUSES = {
  SUBMITTED: 'submitted',
  DRAFT: 'draft',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING_CANCELLATION: 'pending_cancellation',
  CANCELED: 'canceled',
  WAITLISTED: 'waitlisted',
  IN_REVIEW: 'in_review',
  DECLINED: 'declined',
} as const;

export const ACTIVE_REQUEST_STATUSES = [
  REQUEST_STATUSES.DRAFT,
  REQUEST_STATUSES.SUBMITTED,
  REQUEST_STATUSES.PENDING_CANCELLATION,
  REQUEST_STATUSES.WAITLISTED,
] as const;

export const EDITABLE_REQUEST_STATUSES = [
  REQUEST_STATUSES.APPROVED,
  REQUEST_STATUSES.WAITLISTED,
  REQUEST_STATUSES.SUBMITTED,
  REQUEST_STATUSES.REJECTED,
] as const;

export const REQUEST_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
  UNASSIGNED: 'unassigned',
} as const;
