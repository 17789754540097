import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { MonetaryBenefitsType } from 'utils/types/program';

class MonetaryBenefitsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  getMonetaryBenefits = async (
    programId: string,
    categoryId: string,
    objectiveId: string
  ) => {
    const { data } = await this.instance.get(
      `v2/programs/${programId}/categories/${categoryId}/objectives/${objectiveId}/monetary_benefits`
    );
    return data;
  };

  createMonetaryBenefits = async (
    programId: string,
    categoryId: string,
    objectiveId: string,
    monetaryBenefits: MonetaryBenefitsType
  ) => {
    const { data } = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/objectives/${objectiveId}/monetary_benefits`,
      monetaryBenefits
    );

    return data;
  };

  updateMonetaryBenefits = async (
    programId: string,
    categoryId: string,
    objectiveId: string,
    monetaryBenefitsId: string,
    monetaryBenefits: MonetaryBenefitsType
  ) => {
    const { data } = await this.instance.put(
      `v2/programs/${programId}/categories/${categoryId}/objectives/${objectiveId}/monetary_benefits/${monetaryBenefitsId}`,
      monetaryBenefits
    );

    return data;
  };
}

const monetaryBenefitsAPI = new MonetaryBenefitsAPI();

export default monetaryBenefitsAPI;
