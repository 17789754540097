import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppThunkDispatch } from 'state/store';
import {
  fetchProjects,
  selectAllProjects,
  selectCanFetchMoreProjects,
  selectIsLoading,
  setFilter,
  setSearchValue,
  setSortValue,
  setFetchingMore,
} from 'state/ProjectsList/projectsListSlice';
import { ACTIVE_PROJECTS_STATUSES } from 'utils/constants/projectsList';
import { Project } from 'types/store/projectsList';

interface Response {
  fetchActiveProjects: () => void;
  fetchMoreProjects: () => void;
  searchProjects: (search: string) => void;
  projects: Project[];
  isLoading: boolean;
  canFetchMoreProjects: boolean;
  handleSort: (column: string) => void;
}

const useProjectsList: () => Response = () => {
  const dispatch = useAppThunkDispatch();
  const projects = useSelector(selectAllProjects);
  const canFetchMoreProjects = useSelector(selectCanFetchMoreProjects);
  const isLoading = useSelector(selectIsLoading);

  const fetchActiveProjects = useCallback(() => {
    dispatch(setFilter({ status: [...ACTIVE_PROJECTS_STATUSES] }));
    dispatch(setSortValue('-updatedAt'));
    dispatch(
      fetchProjects({
        includeTotalCount: true,
      })
    );
  }, [dispatch]);

  const fetchMoreProjects = useCallback(() => {
    dispatch(setFetchingMore(true));
    dispatch(fetchProjects({}));
  }, [dispatch]);

  const searchProjects = useCallback(
    (search: string) => {
      dispatch(setSearchValue(search));
      dispatch(fetchProjects({}));
    },
    [dispatch]
  );

  const handleSort = useCallback(
    (column: string) => {
      dispatch(setSortValue(column));
      dispatch(fetchProjects({}));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchActiveProjects,
      projects,
      isLoading,
      canFetchMoreProjects,
      fetchMoreProjects,
      searchProjects,
      handleSort,
    }),
    [
      projects,
      isLoading,
      fetchActiveProjects,
      canFetchMoreProjects,
      fetchMoreProjects,
      searchProjects,
      handleSort,
    ]
  );
};

export default useProjectsList;
