import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TableRow, Typography } from '@getsynapse/design-system';
import { TimeOffEntry } from 'types/store/manageTimeOff';
import { selectTimeOffType } from 'state/ManageTimeOff/TimeOffTypes/timeOffTypesSlice';
import { RootState } from 'state/store';
import EditButton from '../EditButton/EditButton';
import DeleteButton from '../DeleteButton/DeleteButton';
import { getCurrentLocale } from 'utils/localeHelper';
import intl from 'react-intl-universal';
interface TimeOffRowProps {
  timeOffEntry: TimeOffEntry;
  className?: string;
}

const currentLocale = getCurrentLocale();
const dateFormatter = new Intl.DateTimeFormat(currentLocale, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const formatDate = (date: string): string => {
  return dateFormatter.format(new Date(date.replace(/-/g, '/')));
};

const timeOffDateRange = (startDate: string, endDate: string): string => {
  let dateRange = `${formatDate(startDate)}`;
  if (startDate !== endDate) {
    dateRange += ` - ${formatDate(endDate)}`;
  }
  return dateRange;
};

const TimeOffRow: FC<TimeOffRowProps> = ({ timeOffEntry, className = '' }) => {
  const timeOffType = useSelector((state: RootState) =>
    selectTimeOffType(state, timeOffEntry.timeOffTypeId)
  );

  return (
    <TableRow
      id={timeOffEntry.id}
      aria-label={`time-off-entry-${timeOffEntry.id}`}
      className={className}
      cells={[
        {
          content: (
            <Typography variant='label' className='text-primary'>
              {timeOffDateRange(timeOffEntry.startDate, timeOffEntry.endDate)}
            </Typography>
          ),
          className: 'p-4',
          style: { width: '30%' },
        },
        {
          content: timeOffType && (
            <Typography variant='label' className='text-primary'>
              {intl.get(`TEAMS.TIME_OFF.TIME_OFF_TYPES.${timeOffType.code}`)}
            </Typography>
          ),
          className: 'p-4',
          style: { width: '30%' },
        },
        {
          content: (
            <Typography variant='label' className='text-primary'>
              {timeOffEntry.hours}
            </Typography>
          ),
          className: 'p-4',
          style: { width: '30%' },
        },
        {
          content: (
            <div className='flex flex-1 justify-end gap-x-2'>
              <EditButton timeOffEntryId={timeOffEntry.id} />
              <DeleteButton timeOffEntryId={timeOffEntry.id} />
            </div>
          ),
          className: 'w-24 p-4',
        },
      ]}
    />
  );
};

export default TimeOffRow;
