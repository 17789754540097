import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  TableOperationHeader,
  TableFilter,
  TableFilterProps,
  FormItem,
  Dropdown,
  Datepicker,
  TextField,
} from '@getsynapse/design-system';
import { Option, filter } from 'utils/customTypes';
import {
  COLUMN_OPTION_TYPES,
  TABLE_FILTERS_OPERATORS,
  TIME_OFF_TYPE,
  DATE_COMPARATOR,
} from 'utils/constants';
import {
  setFilters,
  setSearchParam,
  selectFilters,
} from 'state/TimeOff/TimeOffSlice';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';

const TableHeaderFilters = () => {
  const dispatch = useDispatch();
  const taleFilters = useSelector(selectFilters);
  const [searchValue, setSearchValue] = useState('');
  const [displayFilters, setDisplayFilters] = useState(false);

  const filterOptions: {
    name: string;
    value: string;
    operators: {
      value: string;
      name: string;
    }[];
    type: string;
  }[] = [
    {
      name: intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.START_DATE'),
      type: COLUMN_OPTION_TYPES.DATE,
      operators: Object.values(DATE_COMPARATOR).map((operator) => ({
        name: intl.get(`OPERATORS.${operator}`),
        value: operator,
      })),
      value: 'start_date',
    },
    {
      name: intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.END_DATE'),
      type: COLUMN_OPTION_TYPES.DATE,
      operators: Object.values(DATE_COMPARATOR).map((operator) => ({
        name: intl.get(`OPERATORS.${operator}`),
        value: operator,
      })),
      value: 'end_date',
    },
    {
      name: intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.TYPE'),
      type: COLUMN_OPTION_TYPES.OPTIONS,
      operators: [
        {
          name: intl.get('OPERATORS.EQUAL'),
          value: TABLE_FILTERS_OPERATORS.EQUAL,
        },
      ],
      value: 'time_off_type',
    },
  ];

  const tableFilterInput: TableFilterProps['filterInputElement'] = (
    column,
    filterInput,
    setInput,
    operator
  ) => {
    return (
      <FormItem
        label={intl.get('FILTER_GENERAL.VALUE')}
        component='div'
        className='flex-grow'
      >
        {column.type === 'text' && (
          <TextField
            name='table-header__search-input'
            placeholder={intl.get('FILTER_GENERAL.INPUT_VALUE')}
            state='default'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInput(event.target.value);
            }}
            value={filterInput as string}
            data-testid='filter__text-inputs'
            height='small'
            inputClassName='py-1'
          />
        )}
        {column.type === COLUMN_OPTION_TYPES.DATE && (
          <div data-testid='date__picker' className='flex-grow'>
            <Datepicker
              canEnterDateManually={false}
              canSelectRange={operator ? operator.value === 'BETWEEN' : true}
              onPickDate={(event: any) => {
                setInput(event);
              }}
              triggerClassname='h-8 py-1 text-label'
              startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
              endPlaceHolder={intl.get('FILTER_GENERAL.TO')}
              className='w-full'
            />
          </div>
        )}
        {column.type === COLUMN_OPTION_TYPES.OPTIONS && (
          <Dropdown
            options={Object.values(TIME_OFF_TYPE).map((type) => ({
              label: intl.get(`TEAMS.TIME_OFF.TIME_OFF_TYPES.${type}`),
              value: type,
            }))}
            listProps={{ 'data-testid': 'filter__options-list' }}
            triggerProps={{
              'data-testid': 'filter__options-input',
              size: 'sm',
            }}
            onChange={(value: Option) => {
              setInput(value);
            }}
            values={filterInput ? [filterInput] : []}
          />
        )}
      </FormItem>
    );
  };

  const handleSetFilters = (filters: filter[]) => {
    dispatch(setFilters(filters));
  };

  const handleSetSearchParam = (value: string) => {
    setSearchValue(value);
    dispatch(setSearchParam(value));
  };

  return (
    <div className='px-px'>
      <TableOperationHeader
        searchProps={{
          placeholder: intl.get('SEARCH_PLACEHOLDER'),
        }}
        className='border-b-0'
        inputValue={searchValue}
        setInputValue={handleSetSearchParam}
        actionButtons={
          <>
            <FilterButton
              onToggleFilter={() => {
                setDisplayFilters(!displayFilters);
              }}
              aria-pressed={displayFilters}
            />
          </>
        }
        filterComponent={
          <div className='bg-neutral-white'>
            <TableFilter
              columnOptions={filterOptions}
              filterVisible={displayFilters}
              filterAction={handleSetFilters}
              filterInputElement={tableFilterInput}
              selectedFilters={taleFilters}
            />
          </div>
        }
      />
    </div>
  );
};

export default TableHeaderFilters;
