import intl from 'react-intl-universal';
import { Tab, TabList, Button } from '@getsynapse/design-system';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import VendorsPage from './Vendors/VendorsPage';
import LearningTeams from './Teams/LearningTeams/LearningTeams';
import BusinessTeams from './Teams/BusinessTeams/BusinessTeams';
import NewLDTeamModal from './Teams/NewLDTeamModal';
import {
  selectLDUsersForDropdown,
  getLDUsers,
} from 'state/UsersManagement/usersManagementSlice';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SETTINGS_SECTIONS } from 'utils/constants';
import {
  displayNotification,
  setNotificationText,
  setNotificationTimeout,
  setNotificationVariant,
} from 'state/InlineNotification/inlineNotificationSlice';
import {
  createLearningTeam,
  selectLearningTeamsForDropdown,
} from 'state/LearningTeams/learningTeamsSlice';
import NewBusinessTeamModal from 'Organisms/NewBusinessTeamModal/NewBusinessTeamModal';
import NewVendorModal from 'Organisms/NewVendorModal/NewVendorModal';
import type { LearningTeamForm } from 'utils/types/learningTeam';

const TeamsSettings = () => {
  const indexParam = new URLSearchParams(window.location.search).get('section');
  const sectionMapping = {
    [SETTINGS_SECTIONS.LD_TEAM]: 0,
    [SETTINGS_SECTIONS.BUSINESS_TEAM]: 1,
    [SETTINGS_SECTIONS.VENDORS]: 2,
  };
  const [activeIndex, setActiveIndex] = useState(
    indexParam ? sectionMapping[indexParam] : 0
  );
  const dispatch = useDispatch();
  const learningTeamsOptions = useSelector(selectLearningTeamsForDropdown);
  const [newLDTeamIsOpen, setNewLDTeamIsOpen] = useState(false);
  const ldUsers = useSelector(selectLDUsersForDropdown);

  useEffect(() => {
    dispatch(getLDUsers());
  }, [dispatch]);

  const onAddTeam = (team: LearningTeamForm) => {
    dispatch(createLearningTeam(team));
    dispatch(
      setNotificationText(
        intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.ADD_TEAM_MODAL.TEAM_ADDED')
      )
    );
    dispatch(setNotificationVariant('success'));
    dispatch(setNotificationTimeout(4000));
    dispatch(displayNotification());
  };

  return (
    <Tabs
      index={activeIndex}
      onChange={(index) => {
        setActiveIndex(index);
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?section=${
            Object.keys(sectionMapping)[index]
          }`
        );
      }}
      className='h-full overflow-hidden'
    >
      {({ selectedIndex }) => (
        <>
          <NewLDTeamModal
            isOpen={newLDTeamIsOpen}
            setIsOpen={setNewLDTeamIsOpen}
            ldOptions={learningTeamsOptions}
            ldUsers={ldUsers}
            onAddTeam={onAddTeam}
          />
          <div className='flex justify-between items-center mb-4'>
            <TabList className='max-w-3xl flex-grow'>
              <Tab index={0} data-cy='learning-teams'>
                {intl.get('ENTITIES.LEARNING_TEAM', { num: 2 })}
              </Tab>

              <Tab index={1} data-cy='business-teams'>
                {intl.get('ENTITIES.BUSINESS_TEAM', { num: 2 })}
              </Tab>

              <Tab index={2} data-cy='vendors'>
                {intl.get('ENTITIES.VENDOR', { num: 2 })}
              </Tab>
            </TabList>
            {selectedIndex === 0 ? (
              <Button
                onClick={() => setNewLDTeamIsOpen(true)}
                data-testid='add-ld-team_button'
              >
                {intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.ADD_TEAM')}
              </Button>
            ) : selectedIndex === 1 ? (
              <NewBusinessTeamModal />
            ) : (
              <NewVendorModal />
            )}
          </div>

          <TabPanels className='max-h-page-body overflow-y-auto'>
            <TabPanel key={0}>
              <LearningTeams isVisible={selectedIndex === 0} />
            </TabPanel>

            <TabPanel key={1}>
              <BusinessTeams isVisible={selectedIndex === 1} />
            </TabPanel>

            <TabPanel key={2}>
              <VendorsPage isVisible={selectedIndex === 2} />
            </TabPanel>
          </TabPanels>
        </>
      )}
    </Tabs>
  );
};

export default TeamsSettings;
