import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { TableOperationHeader } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const TableHeader: React.FC<{
  onSearch: (value: string) => void;
  shouldClearInput: boolean;
  tableIdentifier?: string;
}> = ({ onSearch, shouldClearInput, tableIdentifier = '' }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debounceCallback = debounce(onSearch, 500);
  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceCallback(value);
  };

  useEffect(() => {
    if (shouldClearInput) {
      setSearchValue('');
    }
  }, [shouldClearInput]);

  return (
    <TableOperationHeader
      className='border-b-0'
      inputValue={searchValue}
      setInputValue={handleSearch}
      searchProps={{
        'data-testid': `${tableIdentifier}__search-input`,
        placeholder: intl.get('SEARCH_PLACEHOLDER'),
      }}
    />
  );
};

export default TableHeader;
