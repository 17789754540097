import { FC } from 'react';
import { TableHead } from '@getsynapse/design-system';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectIsUserLd } from 'state/User/userSlice';

const useRequestHeaderCells = (isLearningUser: boolean) => {
  const baseCellKeys = [
    'REQUESTS_LIST_PAGE.TABLE.HEAD.REQUEST_NO',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.REQUEST_TITLE',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.REQUESTER_NAME',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.OWNER_NAME',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.LINKED_PROJECTS',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.FORM',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.CREATION_DATE',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.DECISION_DATE',
    'REQUESTS_LIST_PAGE.TABLE.HEAD.UPDATE_DATE',
  ];

  if (isLearningUser) {
    baseCellKeys.splice(3, 0, 'REQUESTS_LIST_PAGE.TABLE.HEAD.PRIORITY');
    baseCellKeys.splice(7, 0, 'REQUESTS_LIST_PAGE.TABLE.HEAD.BUSINESS_UNIT');
  }

  return baseCellKeys.map((key) => ({
    content: intl.get(key),
  }));
};

const RequestsTableHeader: FC = () => {
  const isLearningUser = useSelector(selectIsUserLd);
  const headerCells = useRequestHeaderCells(isLearningUser);

  return <TableHead stickyHeader headCells={headerCells} />;
};

export default RequestsTableHeader;
