import { useMemo, useCallback, ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import intl from 'react-intl-universal';
import { ProjectListTabsKeys } from 'utils/types/projectsList';
import { Tag } from '@getsynapse/design-system';

type ProjectTabValues = {
  label: ReactNode;
  index: 0 | 1 | 2;
};

type ProjectsListTabsMapping = Partial<
  Record<ProjectListTabsKeys, ProjectTabValues>
>;

const useProjectsListTabs: () => {
  availableTabs: ProjectsListTabsMapping;
  getTabKeyByIndex: (index: number) => ProjectListTabsKeys;
  getTabIndexByKey: (key: ProjectListTabsKeys) => number;
} = () => {
  const { projectsActiveTab = false } = useFlags();

  const activeProjectsTabLabel = useMemo(() => {
    return (
      <div className='flex items-center justify-center gap-x-1'>
        {intl.get('PROJECTS_LIST_PAGE.ACTIVE_PROJECTS')}
        <Tag
          label={intl.get('BETA')}
          textClassName='text-purple-darker font-semibold'
          className='bg-purple-lightest border-purple border h-5 py-0'
        />
      </div>
    );
  }, []);

  const PROJECTS_LIST_TABS_MAPPING = useMemo<ProjectsListTabsMapping>(() => {
    if (projectsActiveTab) {
      return {
        activeItems: {
          label: activeProjectsTabLabel,
          index: 0,
        },
        teamItems: {
          label: intl.get('PROJECTS_LIST_PAGE.TEAM_PROJECTS'),
          index: 1,
        },
        myItems: {
          label: intl.get('PROJECTS_LIST_PAGE.MY_PROJECTS'),
          index: 2,
        },
      };
    }

    return {
      teamItems: {
        label: intl.get('PROJECTS_LIST_PAGE.TEAM_PROJECTS'),
        index: 0,
      },
      myItems: {
        label: intl.get('PROJECTS_LIST_PAGE.MY_PROJECTS'),
        index: 1,
      },
    };
  }, [projectsActiveTab, activeProjectsTabLabel]);

  const getTabKeyByIndex = useCallback(
    (index: number) => {
      return Object.keys(PROJECTS_LIST_TABS_MAPPING)[
        index
      ] as ProjectListTabsKeys;
    },
    [PROJECTS_LIST_TABS_MAPPING]
  );

  const getTabIndexByKey = useCallback(
    (key: ProjectListTabsKeys) => {
      return PROJECTS_LIST_TABS_MAPPING[key]?.index ?? 0;
    },
    [PROJECTS_LIST_TABS_MAPPING]
  );

  return useMemo(
    () => ({
      availableTabs: PROJECTS_LIST_TABS_MAPPING,
      getTabKeyByIndex,
      getTabIndexByKey,
    }),
    [PROJECTS_LIST_TABS_MAPPING, getTabKeyByIndex, getTabIndexByKey]
  );
};

export default useProjectsListTabs;
