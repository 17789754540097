import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { AdditionalBenefit } from 'utils/types/program';

class AdditionalBenefitAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  getAdditionalBenefit = async (
    programId: string,
    categoryId: string,
    benefitId: string
  ) => {
    const { data } = await this.instance.get(
      `v2/programs/${programId}/categories/${categoryId}/additional_benefits/${benefitId}`
    );
    return data;
  };

  deleteAdditionalBenefit = async (
    programId: string,
    categoryId: string,
    benefitId: string
  ) => {
    const { data } = await this.instance.delete(
      `v2/programs/${programId}/categories/${categoryId}/additional_benefits/${benefitId}`
    );
    return data;
  };

  updateAdditionalBenefit = async (
    programId: string,
    categoryId: string,
    benefitId: string,
    data: AdditionalBenefit
  ) => {
    const { data: response } = await this.instance.put(
      `v2/programs/${programId}/categories/${categoryId}/additional_benefits/${benefitId}`,
      data
    );
    return response;
  };
}

const additionalBenefitAPI = new AdditionalBenefitAPI();

export default additionalBenefitAPI;
