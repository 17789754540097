import { Table } from '@getsynapse/design-system';
import Skelton from 'Atoms/Skelton/Skelton';

export const MetricsSkeleton = () => (
  <div className='flex gap-4'>
    <div className='flex flex-1 p-4 border border-primary-lighter-two rounded-md items-center'>
      <Skelton className='rounded-full h-12 w-12 mr-4' />
      <div className='flex flex-col gap-2 flex-1'>
        <Skelton className='h-4 w-1/2 rounded' />
        <Skelton className='h-4 w-3/4 rounded' />
      </div>
    </div>

    <div className='flex flex-1 p-4 border border-primary-lighter-two rounded-md flex-col justify-center gap-2'>
      <Skelton className='h-4 w-1/3 rounded' />
      <Skelton className='h-4 w-2/3 rounded' />
    </div>

    <div className='flex flex-1 p-4 border border-primary-lighter-two rounded-md flex-col justify-center gap-2'>
      <Skelton className='h-4 w-1/3 rounded' />
      <Skelton className='h-4 w-2/3 rounded' />
    </div>
  </div>
);

export const TableSkeleton = () => {
  const columns = 5;
  const rows = 5;

  const skeletonCell = () => ({
    content: <Skelton className='h-4 w-3/4' />,
  });

  return (
    <Table
      className='mt-4'
      canSelectRows={false}
      data={{
        headData: {
          headCells: Array(columns).fill(0).map(skeletonCell),
        },
        rows: Array(rows)
          .fill(0)
          .map(() => ({
            cells: Array(columns).fill(0).map(skeletonCell),
          })),
      }}
    />
  );
};

export const DetailsSkeleton = () => (
  <div className='p-4 border border-primary-lighter-two rounded-md w-90 2xl:w-3/12'>
    <Skelton className='h-4 w-1/3 mb-4' />

    {[...Array(4)].map((_, i) => (
      <div key={i} className='mb-4'>
        <Skelton className='h-3 w-1/4 mb-2' />
        <Skelton className='h-3 w-3/4' />
      </div>
    ))}
  </div>
);
