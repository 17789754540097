import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState, ThunkApi } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { BusinessUnitSummary } from 'types/store/normalized';

/* ============================== STATE SETUP =============================== */
interface BusinessUnitSummariesState {
  status: Status;
}

const initialState: BusinessUnitSummariesState = {
  status: SLICE_STATUS.IDLE,
};

const adapter = createEntityAdapter<BusinessUnitSummary>();

/* ============================== REDUX THUNK =============================== */
function concurrencyCondition(
  thunkParameters: unknown,
  { getState }: { getState: () => RootState }
): boolean {
  const { status } = getState().normalized.businessUnitSummaries;
  return status !== SLICE_STATUS.LOADING;
}

interface GetListParams {
  businessUnitIds?: string[];
}

interface GetBusinessUnitSummariesResponse {
  teams: BusinessUnitSummary[];
  links: { next: string };
}

const NO_OP_RESPONSE: GetBusinessUnitSummariesResponse = {
  teams: [],
  links: { next: '' },
};

export const getBusinessUnitSummaries = createAsyncThunk<
  GetBusinessUnitSummariesResponse,
  GetListParams,
  ThunkApi
>(
  'normalized/businessUnitSummary/getBusinessUnitSummaries',
  async (
    { businessUnitIds = [] },
    { getState, extra: { getBusinessUnitSummariesApi } }
  ) => {
    const state = getState();
    const uniqueForms = new Set(businessUnitIds);
    const notCached = Array.from(uniqueForms).filter(
      (id) => !state.normalized.businessUnitSummaries.entities[id]
    );

    if (notCached.length === 0) {
      return NO_OP_RESPONSE;
    }
    const params = new URLSearchParams();
    notCached.forEach((id) => id && params.append('filter[ids][]', id));

    const { teams, links } = await getBusinessUnitSummariesApi().getList({
      params,
    });
    return { teams, links };
  },
  { condition: concurrencyCondition }
);

/* ================================= REDUCER ================================ */
const businessUnitSummariesSlice = createSlice({
  name: 'normalized/businessUnitSummary',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessUnitSummaries.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getBusinessUnitSummaries.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getBusinessUnitSummaries.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.teams);
        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelectors = adapter.getSelectors<RootState>(
  (state) => state.normalized.businessUnitSummaries
);

const selectStatus = (state: RootState) =>
  state.normalized.businessUnitSummaries.status;

const selectBusinessUnitSummaryById = (state: RootState) => (id: string) =>
  defaultSelectors.selectById(state, id);

export const businessUnitSummarySelectors = {
  ...defaultSelectors,
  selectStatus,
  selectBusinessUnitSummaryById,
};

export default businessUnitSummariesSlice.reducer;
