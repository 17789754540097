import { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import {
  fetchUpcomingTimeOffEntries,
  selectUpcomingTimeOffEntries,
  selectFetchUpcomingTimeOffEntriesStatus,
  UpcomingTimeOffEntriesStateStatus,
  resetUpcomingTimeOffEntries,
  setSorting,
  selectUpcomingTimeOffEntriesSorting,
  setFilters,
  selectCanFetchMoreUpcomingTimeOffEntries,
  selectTotalUpcomingTimeOffEntries,
} from 'state/ManageTimeOff/TimeOffEntries/upcoming/upcomingTimeOffEntriesSlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import useGetUserId from '../../hooks/useGetUserId';
import { SLICE_STATUS, PATHS, SETTINGS_FILTERS } from 'utils/constants';
import { TIME_OFF_TABLES } from 'utils/constants/manageTimeOff';
import SkeletonLoader from '../TimeOffTableList/SkeletonLoader/SkeletonLoader';
import TimeOffTableList from '../TimeOffTableList/TimeOffTableList';
import {
  createFilterSetting,
  fetchFilterSettingByType,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';
import moment from 'moment';
import { getCurrentLocale } from 'utils/localeHelper';

const UpcomingTimeOffList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useGetUserId();
  const [isFetching, setIsFetching] = useState(true);
  const [hasFilterLoaded, setHasFilterLoaded] = useState(false);
  const status = useRef<UpcomingTimeOffEntriesStateStatus>(SLICE_STATUS.IDLE);
  const isMounted = useRef(true);
  const fetchUpcomingTimeOffEntriesStatus = useSelector(
    selectFetchUpcomingTimeOffEntriesStatus
  );
  const timeOffEntries = useSelector(selectUpcomingTimeOffEntries);
  const sorting = useSelector(selectUpcomingTimeOffEntriesSorting);
  const canFetchMore = useSelector(selectCanFetchMoreUpcomingTimeOffEntries);
  const totalCount = useSelector(selectTotalUpcomingTimeOffEntries);
  const [showFilterComponent, setShowFilterComponent] = useState(false);
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.UPCOMING_TIME_OFFS_TABLE)
  );
  const savedFilters = useMemo<TimeOffEntryFilters>(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  const locale = getCurrentLocale();
  moment.locale(locale);
  useEffect(() => {
    const fetchFilter = async () => {
      await dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.UPCOMING_TIME_OFFS_TABLE)
      );
      if (isMounted.current) {
        setHasFilterLoaded(true);
      }
    };
    fetchFilter();
  }, [dispatch]);

  const handleSaveFiltersSettings = (updatedFilters: TimeOffEntryFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: updatedFilters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: SETTINGS_FILTERS.UPCOMING_TIME_OFFS_TABLE,
          filter_settings: updatedFilters,
        })
      );
    }
  };

  const handleToggleFilterComponent = () => {
    setShowFilterComponent((prevState) => !prevState);
  };

  useEffect(() => {
    if (userId && hasFilterLoaded) {
      dispatch(setFilters(savedFilters));
      dispatch(
        fetchUpcomingTimeOffEntries({ userId, includeTotalCount: true })
      );
    }
    return () => {
      isMounted.current = false;
      dispatch(resetUpcomingTimeOffEntries());
    };
  }, [dispatch, userId, savedFilters, hasFilterLoaded]);

  useEffect(() => {
    if (fetchUpcomingTimeOffEntriesStatus !== status.current) {
      status.current = fetchUpcomingTimeOffEntriesStatus;
      if (fetchUpcomingTimeOffEntriesStatus === 'rejected') {
        dispatch(
          showNotification({
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'MANAGE_TIME_OFF.NO_PERMISSION_ERROR_TITLE'
            ),
            notificationMessage: intl.get(
              'MANAGE_TIME_OFF.NO_PERMISSION_ERROR_MESSAGE'
            ),
          })
        );
        history.replace(PATHS.ROOT);
      }
      if (fetchUpcomingTimeOffEntriesStatus !== SLICE_STATUS.LOADING) {
        setIsFetching(false);
      }
    }
  }, [fetchUpcomingTimeOffEntriesStatus, history, dispatch]);

  const onHandleSort = (sortBy: string, order: string) => {
    dispatch(setSorting({ sortBy, order }));
    dispatch(fetchUpcomingTimeOffEntries({ userId: userId! }));
  };

  const handleFetchMoreTimeOffEntries = () => {
    dispatch(fetchUpcomingTimeOffEntries({ userId: userId!, fetchNext: true }));
  };

  if (isFetching) {
    return (
      <SkeletonLoader id={`${TIME_OFF_TABLES.UPCOMING}__skeleton-loader`} />
    );
  }

  return (
    !isFetching && (
      <TimeOffTableList
        id={TIME_OFF_TABLES.UPCOMING}
        timeOffEntries={timeOffEntries}
        handleSort={onHandleSort}
        sorting={sorting}
        savedFilters={savedFilters}
        onUpdateFilters={handleSaveFiltersSettings}
        onCloseFilterPanel={handleToggleFilterComponent}
        isFilterPanelOpen={showFilterComponent}
        toggleFilterPanel={handleToggleFilterComponent}
        minFilterDate={moment().format('YYYY-MM-DD')}
        canFetchMore={canFetchMore}
        fetchMoreTimeOffEntries={handleFetchMoreTimeOffEntries}
        totalCount={totalCount}
      />
    )
  );
};

export default UpcomingTimeOffList;
