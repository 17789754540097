import { FC, useEffect, useState, useRef } from 'react';
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import { useInView } from 'react-intersection-observer';
import { tailwindOverride } from '@getsynapse/design-system';

interface LastRowProps {
  isOdd: boolean;
  tableName: string;
  onLastRowInView: (inView: boolean) => void;
  children: (props: {
    rowRef: (node?: Element | null) => void;
    rowStyles: string;
    columnStyles: string;
    columnChildStyles: string;
  }) => JSX.Element;
}

const LastRow: FC<LastRowProps> = ({
  isOdd,
  tableName,
  onLastRowInView,
  children,
}) => {
  const [rootRef, setRootRef] = useState<HTMLElement | null>(null);
  const debouncedOnChangeHandler =
    useRef<DebouncedFunc<(inView: boolean) => void>>();

  useEffect(() => {
    const table = document.querySelector(
      `table[arial-label="${tableName}"]`
    ) as HTMLElement;
    setRootRef(table);
  }, [tableName]);

  useEffect(() => {
    debouncedOnChangeHandler.current = debounce(
      (inView: boolean) => inView && onLastRowInView(inView),
      500
    );
  }, [onLastRowInView]);

  const { ref } = useInView({
    root: rootRef,
    threshold: 0,
    rootMargin: '-80px 0px 0px',
    onChange: debouncedOnChangeHandler.current,
  });

  const rowStyles = tailwindOverride(
    tailwindOverride('h-10', {
      'bg-neutral-lightest-two': isOdd,
      'bg-neutral-white': !isOdd,
    })
  );

  const columnStyles = tailwindOverride('px-4 py-3', 'animate-pulse');

  const columnChildStyles = tailwindOverride(
    'h-4 rounded',
    'bg-primary-lighter-two'
  );

  return (
    <>
      {children({
        rowRef: ref,
        rowStyles,
        columnStyles,
        columnChildStyles,
      })}
    </>
  );
};

export default LastRow;
