import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { SortingType } from 'utils/customTypes';

class GoalProjectsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/strategy/goals/`);
  }

  fetchGoalProjects = async (
    goalId: string,
    orderByParam?: string,
    order?: SortingType
  ) => {
    const queryParams = new URLSearchParams();
    if (orderByParam && order) {
      queryParams.append('sort', orderByParam);
      queryParams.append('order', order);
    }
    const res = await this.instance.get(
      `${goalId}/projects?${queryParams.toString()}`
    );
    return res.data;
  };
}

const projectGoalsAPI = new GoalProjectsAPI();

export default projectGoalsAPI;
