import intl from 'react-intl-universal';
import moment from 'moment';
import get from 'lodash/get';
import {
  NOTIFICATION_TYPE,
  REQUEST_STATUS,
  PATHS,
  SETTINGS_TABS,
  DATE,
  TEAMS_PAGE_TABS,
  DELETE_PROGRAM_OPTIONS,
} from 'utils/constants';
import {
  FormattedNotification,
  Notification,
  CurrentUser,
} from 'utils/customTypes';
import approvedRequestIcon from 'assets/icons/approved-request.svg';
import declinedRequestIcon from 'assets/icons/declined-request.svg';
import waitlistedRequestIcon from 'assets/icons/waitlisted-request.svg';
import inReviewIcon from 'assets/icons/in-review-request.svg';
import projectUpdateIcon from 'assets/icons/project-update-icon.svg';
import programIcon from 'assets/icons/program-icon.svg';
import programLinkProjectIcon from 'assets/icons/program-link-project.svg';
import programUnlinkProjectIcon from 'assets/icons/program-unlink-project.svg';
import programClosed from 'assets/icons/program-closed.svg';
import statusUpdateIcon from 'assets/icons/status-update.svg';
import disableIcon from 'assets/icons/disable-icon.svg';
import enableIcon from 'assets/icons/enable-icon.svg';
import removeIcon from 'assets/icons/remove-icon.svg';
import dueDateIcon from 'assets/icons/due-date-icon.svg';
import pastDueDateIcon from 'assets/icons/past-due-date-icon.svg';
import commentMentionIcon from 'assets/icons/comment-mention.svg';
import calendarIcon from 'assets/icons/calendar.svg';
import unarchive from 'assets/icons/unarchive.svg';
import updateTaskBundleIcon from 'assets/icons/update-task-bundle.svg';
import blockingTaskCompletedIcon from 'assets/icons/blocking-task-completed.svg';
import assignOwnerIcon from 'assets/icons/assign-owner.svg';
import { v4 as uuidv4 } from 'uuid';
import ProjectStatusNotification from 'Molecules/Notification/ProjectStatusNotification';

const renderContent = (
  triggererName: string,
  content: string,
  requestTitle: string
) => (
  <>
    {triggererName}
    {content}
    <span className='font-semibold'>{requestTitle}</span>
  </>
);

const formatDateRange = (startDate: string, endDate: string) => {
  const formattedEndDate = moment(new Date(endDate))
    .utc()
    .format(DATE.TASK_TABLE_FORMAT);
  const formattedStartDate = moment(new Date(startDate))
    .utc()
    .format(DATE.TASK_TABLE_FORMAT);
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatNotifications = (
  notificationsList: Notification[],
  currentUser: CurrentUser
) => {
  return notificationsList.map((notification) => {
    let formttedNotification: FormattedNotification = {
      id: '',
      link: '',
    };
    switch (notification.message) {
      case NOTIFICATION_TYPE.REQUEST_ATTRIBUTES_UPDATED:
      case NOTIFICATION_TYPE.REQUEST_QUESTIONS_UPDATED:
        formttedNotification.link = `${PATHS.REQUEST_PAGE}/${get(
          notification.data,
          'requestId'
        )}`;
        formttedNotification = {
          ...formttedNotification,
          content: renderContent(
            get(notification.data, 'triggererName'),
            intl.get('APP_NOTIFICATIONS.REQUEST_QUESTIONS_UPDATED'),
            get(notification.data, 'requestTitle')
          ),
          avatar: {
            iconName: 'hand-right',
            iconProps: {
              className: 'text-neutral-dark text-h3',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.REQUEST_STATUS_UPDATED:
        const previousStatus = get(notification.data, 'previousStatus');
        const currentStatus = get(notification.data, 'currentStatus');
        formttedNotification.link = `${PATHS.REQUEST_PAGE}/${get(
          notification.data,
          'requestId'
        )}`;
        if (
          previousStatus === REQUEST_STATUS.DRAFT &&
          currentStatus === REQUEST_STATUS.SUBMITTED
        ) {
          formttedNotification = {
            ...formttedNotification,
            content: renderContent(
              get(notification.data, 'triggererName'),
              intl.get('APP_NOTIFICATIONS.REQUEST_SUBMITTED'),
              get(notification.data, 'requestTitle')
            ),
            avatar: {
              iconName: 'hand-right',
              iconProps: {
                className: 'text-neutral-dark text-h3',
              },
            },
          };
        } else if (
          previousStatus !== REQUEST_STATUS.DRAFT &&
          currentStatus === REQUEST_STATUS.SUBMITTED
        ) {
          formttedNotification = {
            ...formttedNotification,
            content: intl.getHTML('APP_NOTIFICATIONS.REQUEST_BACK_IN_REVIEW', {
              requestTitle: get(notification.data, 'requestTitle'),
            }),
            avatar: {
              iconSrc: inReviewIcon,
              iconProps: {
                className: 'text-teal-dark text-h3',
              },
            },
          };
        } else if (
          previousStatus === REQUEST_STATUS.APPROVED &&
          currentStatus === REQUEST_STATUS.PENDING_CANCELLATION
        ) {
          formttedNotification = {
            ...formttedNotification,
            content: renderContent(
              get(notification.data, 'triggererName'),
              intl.get('APP_NOTIFICATIONS.REQUEST_CANCELLED'),
              get(notification.data, 'requestTitle')
            ),
            avatar: {
              iconName: 'remove-circle',
              iconProps: {
                className: 'text-error-dark text-h3',
              },
            },
          };
        } else if (currentStatus === REQUEST_STATUS.APPROVED) {
          formttedNotification = {
            ...formttedNotification,
            content: intl.getHTML('APP_NOTIFICATIONS.REQUEST_APPROVED', {
              requestTitle: get(notification.data, 'requestTitle'),
            }),
            avatar: {
              iconSrc: approvedRequestIcon,
              iconProps: {
                className: 'text-h3 fill-current text-neutral-dark',
              },
            },
          };
        } else if (currentStatus === REQUEST_STATUS.REJECTED) {
          formttedNotification = {
            ...formttedNotification,
            content: intl.getHTML('APP_NOTIFICATIONS.REQUEST_DECLINED', {
              requestTitle: get(notification.data, 'requestTitle'),
            }),
            avatar: {
              iconSrc: declinedRequestIcon,
              iconProps: {
                className: 'text-h3',
              },
            },
          };
        } else if (currentStatus === REQUEST_STATUS.WAITLISTED) {
          formttedNotification = {
            ...formttedNotification,
            content: intl.getHTML('APP_NOTIFICATIONS.REQUEST_WAITLISTED', {
              requestTitle: get(notification.data, 'requestTitle'),
            }),
            avatar: {
              iconSrc: waitlistedRequestIcon,
              iconProps: {
                className: 'text-h3',
              },
            },
          };
        }
        break;
      case NOTIFICATION_TYPE.QUESTION_COMMENTED:
      case NOTIFICATION_TYPE.REQUEST_PROPERTY_COMMENTED:
        let queryString =
          notification.message === NOTIFICATION_TYPE.QUESTION_COMMENTED
            ? `questionId=${get(notification.data, 'questionId')}`
            : `propertyName=${get(notification.data, 'propertyName')}`;
        queryString = `${queryString}&uuid=${uuidv4()}`;
        formttedNotification = {
          ...formttedNotification,
          content: renderContent(
            get(notification.data, 'triggererName'),
            intl.get('APP_NOTIFICATIONS.COMMENT_ADDED'),
            get(notification.data, 'requestTitle')
          ),
          link: `${PATHS.REQUEST_PAGE}/${get(
            notification.data,
            'requestId'
          )}?${queryString}`,
          avatar: {
            iconName: 'chatbox',
            iconProps: {
              className: 'text-neutral-dark text-h3',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.REQUEST_PROPERTY_COMMENT_MENTION:
      case NOTIFICATION_TYPE.QUESTION_COMMENT_MENTION:
        const query =
          notification.message === NOTIFICATION_TYPE.QUESTION_COMMENT_MENTION
            ? `questionId=${get(notification.data, 'questionId')}`
            : `propertyName=${get(notification.data, 'propertyName')}`;
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.REQUEST_COMMENT_MENTION', {
            userName: get(notification, 'data.triggererName'),
            requestTitle: get(notification, 'data.requestTitle'),
          }),
          link: `${PATHS.REQUEST_PAGE}/${get(
            notification.data,
            'requestId'
          )}?${query}`,
          avatar: {
            iconSrc: commentMentionIcon,
            iconProps: {
              className: 'w-5 h-5 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.ASSIGN_NEW_OWNER:
        formttedNotification = {
          ...formttedNotification,
          content: renderContent(
            get(notification.data, 'triggererName'),
            intl.get('APP_NOTIFICATIONS.ASSIGN_NEW_OWNER'),
            get(notification.data, 'requestTitle')
          ),
          link: `${PATHS.REQUEST_PAGE}/${get(notification.data, 'requestId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.UNASSIGN_OWNER:
        formttedNotification = {
          ...formttedNotification,
          content: renderContent(
            get(notification.data, 'triggererName'),
            intl.get('APP_NOTIFICATIONS.UNASSIGN_OWNER'),
            get(notification.data, 'requestTitle')
          ),
          link: `${PATHS.REQUEST_PAGE}/${get(notification.data, 'requestId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_USER_ASSIGNED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_USER_ASSIGNED', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_USER_ASSIGNED_OWNER_VIEW:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.TASK_USER_ASSIGNED_OWNER_VIEW',
            {
              userName: get(notification, 'data.triggererName'),
              taskName: get(notification, 'data.taskName'),
              actionTargetName: get(notification, 'data.actionTargetName'),
            }
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_USER_UNASSIGNED_OWNER_VIEW:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.TASK_USER_UNASSIGNED_OWNER_VIEW',
            {
              userName: get(notification, 'data.triggererName'),
              taskName: get(notification, 'data.taskName'),
              actionTargetName: get(notification, 'data.actionTargetName'),
            }
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_OWNER_ASSIGNED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROJECT_OWNER_ASSIGNED', {
            userName: get(notification, 'data.triggererName'),
            projectName: get(notification, 'data.projectTitle'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_USER_UNASSIGNED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_USER_UNASSIGNED', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_OWNER_UNASSIGNED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROJECT_OWNER_UNASSIGNED', {
            userName: get(notification, 'data.triggererName'),
            projectName: get(notification, 'data.projectTitle'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_UPDATED', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            iconSrc: projectUpdateIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_ACTUAL_HOURS_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_ACTUAL_HOURS_UPDATED', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            iconSrc: projectUpdateIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_STATUS_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_STATUS_UPDATED', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            iconSrc: statusUpdateIcon,
            iconProps: {
              className: 'w-5 h-5 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_COMMENT_MENTION:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_COMMENT_MENTION', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            iconName: 'chatbox',
            iconProps: {
              className: 'text-neutral-dark text-h3',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_STATUS_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: (
            <ProjectStatusNotification
              triggererName={get(notification, 'data.triggererName')}
              projectName={get(notification, 'data.projectName')}
              currentStatus={get(notification, 'data.currentStatus')}
              previousStatus={get(notification, 'data.previousStatus')}
              unread={get(notification, 'status') !== 'read'}
            />
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconSrc: statusUpdateIcon,
            iconProps: {
              className: 'w-5 h-5 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_DISABLED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_DISABLED', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
            enabled: get(notification, 'data.disabled') ? 0 : 1,
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            iconSrc: get(notification, 'data.disabled')
              ? disableIcon
              : enableIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROJECT_UPDATED', {
            userName: get(notification, 'data.triggererName'),
            projectName: get(notification, 'data.projectName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconSrc: projectUpdateIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_DELETED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_DELETED', {
            userName: get(notification, 'data.triggererName'),
            taskName: get(notification, 'data.taskName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}?tab=tasks`,
          avatar: {
            iconSrc: removeIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_DUE_DATE:
        formttedNotification = {
          ...formttedNotification,
          content:
            get(notification, 'data.daysLeft') === 0
              ? intl.getHTML('APP_NOTIFICATIONS.TASK_PAST_DUE_DATE', {
                  taskName: get(notification, 'data.taskName'),
                })
              : intl.getHTML('APP_NOTIFICATIONS.TASK_DUE_DATE', {
                  taskName: get(notification, 'data.taskName'),
                  number: get(notification, 'data.daysLeft'),
                }),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(notification, 'data.taskId')}`,
          avatar: {
            iconSrc:
              get(notification, 'data.daysLeft') === 0
                ? pastDueDateIcon
                : dueDateIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_COMMENTED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROJECT_COMMENTED', {
            userName: get(notification, 'data.triggererName'),
            projectName: get(notification, 'data.projectName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconName: 'chatbox',
            iconProps: {
              className: 'text-neutral-dark text-h3',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_COMMENT_MENTION:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROJECT_COMMENT_MENTION', {
            userName: get(notification, 'data.triggererName'),
            projectName: get(notification, 'data.projectName'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconSrc: commentMentionIcon,
            iconProps: {
              className: 'w-5 h-5 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.NEW_PROJECT_PARTICIPANT:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.NEW_PROJECT_PARTICIPANT', {
            userName: get(notification, 'data.triggererName'),
            projectName: get(notification, 'data.projectTitle'),
            role: get(notification, 'data.role'),
          }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_PARTICIPANT_REMOVED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.PROJECT_PARTICIPANT_REMOVED',
            {
              userName: get(notification, 'data.triggererName'),
              projectName: get(notification, 'data.projectTitle'),
            }
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconName: 'remove-circle',
            iconProps: {
              className: 'text-error-dark text-h3',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_ASSIGNMENT_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.PROJECT_ASSIGNMENT_UPDATED',
            {
              userName: get(notification, 'data.triggererName'),
              projectName: get(notification, 'data.projectTitle'),
              role: get(notification, 'data.role'),
            }
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}?tab=people`,
          avatar: {
            iconSrc: projectUpdateIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_ASSIGNMENT_REMOVED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.PROJECT_ASSIGNMENT_REMOVED',
            {
              userName: get(notification, 'data.triggererName'),
              projectName: get(notification, 'data.projectTitle'),
              role: get(notification, 'data.role'),
            }
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}?tab=people`,
          avatar: {
            iconName: 'remove-circle',
            iconProps: {
              className: 'text-error-dark text-h3',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_START_DATE:
        const startDateIsPast =
          get(notification, 'data.daysLeftToStart', -1) === -1;
        formttedNotification = {
          ...formttedNotification,
          content: startDateIsPast
            ? intl.getHTML('APP_NOTIFICATIONS.PROJECT_START_DATE_PASSED', {
                projectName: get(notification, 'data.projectName'),
              })
            : intl.getHTML('APP_NOTIFICATIONS.PROJECT_START_DATE_THREE_DAYS', {
                projectName: get(notification, 'data.projectName'),
              }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconSrc: startDateIsPast ? pastDueDateIcon : dueDateIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_DUE_DATE:
        const daysLeft = get(notification, 'data.daysLeftToCompletionDate', -1);
        const isPastDue = daysLeft === -1;
        formttedNotification = {
          ...formttedNotification,
          content: isPastDue
            ? intl.getHTML('APP_NOTIFICATIONS.PROJECT_PAST_DUE', {
                projectName: get(notification, 'data.projectName'),
              })
            : intl.getHTML('APP_NOTIFICATIONS.PROJECT_DUE_DATE', {
                projectName: get(notification, 'data.projectName'),
                number: daysLeft,
              }),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconSrc: isPastDue ? pastDueDateIcon : dueDateIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.BUSINESS_USER_NEW_REGISTRATION:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.BUSINESS_USER_NEW_REGISTRATION',
            {
              newUserEmail: get(notification, 'data.newUserEmail'),
            }
          ),
          link: `${PATHS.SETTINGS}/${SETTINGS_TABS.USERS}`,
          avatar: {
            iconName: 'person',
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.REQUEST_BUSINESS_REVIEWER:
        formttedNotification.link = `${PATHS.REQUEST_PAGE}/${get(
          notification.data,
          'requestId'
        )}`;
        formttedNotification = {
          ...formttedNotification,
          content: renderContent(
            get(notification.data, 'triggererName'),
            intl.get('APP_NOTIFICATIONS.REQUEST_BUSINESS_REVIEWER'),
            get(notification.data, 'requestTitle')
          ),
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.TIME_OFF_REMOVED:
        const endDate = get(notification.data, 'timeOffEndDate');
        const startDate = get(notification.data, 'timeOffStartDate');
        formttedNotification.link = `${PATHS.TEAMS}/${TEAMS_PAGE_TABS.TEAMS}`;
        formttedNotification = {
          ...formttedNotification,
          content: intl.get('APP_NOTIFICATIONS.TIME_OFF_REMOVED', {
            triggererName: get(notification.data, 'triggererName'),
            dateRange: formatDateRange(startDate, endDate),
          }),
          avatar: {
            iconSrc: calendarIcon,
            iconProps: {
              className: 'w-5 h-5 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TIME_OFF_CREATED:
        const timeOffEndDate = get(notification.data, 'timeOffEndDate');
        const timeOffStartDate = get(notification.data, 'timeOffStartDate');
        formttedNotification.link = `${PATHS.TEAMS}/${TEAMS_PAGE_TABS.TEAMS}`;
        formttedNotification = {
          ...formttedNotification,
          content: intl.get('APP_NOTIFICATIONS.TIME_OFF_CREATED', {
            triggererName: get(notification.data, 'triggererName'),
            dateRange: formatDateRange(timeOffStartDate, timeOffEndDate),
          }),
          avatar: {
            iconSrc: calendarIcon,
            iconProps: {
              className: 'w-5 h-5 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TIME_OFF_UPDATED:
        const timeOffEndDateUpdated = get(notification.data, 'timeOffEndDate');
        const timeOffStartDateUpdated = get(
          notification.data,
          'timeOffStartDate'
        );
        formttedNotification.link = `${PATHS.TEAMS}/${TEAMS_PAGE_TABS.TEAMS}`;
        formttedNotification = {
          ...formttedNotification,
          content: intl.get('APP_NOTIFICATIONS.TIME_OFF_UPDATED', {
            triggererName: get(notification.data, 'triggererName'),
            dateRange: formatDateRange(
              timeOffStartDateUpdated,
              timeOffEndDateUpdated
            ),
          }),
          avatar: {
            iconSrc: calendarIcon,
            iconProps: {
              className: 'w-5 h-5 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROGRAM_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROGRAM_UPDATED', {
            triggererName: get(notification, 'data.triggererName'),
            programName: get(notification, 'data.programName'),
          }),
          link: `${PATHS.PROGRAM_PAGE}/${get(notification.data, 'programId')}`,
          avatar: {
            iconSrc: programIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_LINKED_TO_PROGRAM:
        const link = get(notification, 'data.project')
          ? `${PATHS.PROJECT_PAGE}/${get(notification.data, 'projectId')}`
          : `${PATHS.PROGRAM_PAGE}/${get(notification.data, 'programId')}`;
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROJECT_LINKED_TO_PROGRAM', {
            triggererName: get(notification, 'data.triggererName'),
            programName: get(notification, 'data.programName'),
            projectName: get(notification, 'data.projectName'),
          }),
          link: link,
          avatar: {
            iconSrc: programLinkProjectIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_UNLINKED_FROM_PROGRAM:
        const notLink = get(notification, 'data.project')
          ? `${PATHS.PROJECT_PAGE}/${get(notification.data, 'projectId')}`
          : `${PATHS.PROGRAM_PAGE}/${get(notification.data, 'programId')}`;
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.PROJECT_UNLINKED_FROM_PROGRAM',
            {
              triggererName: get(notification, 'data.triggererName'),
              programName: get(notification, 'data.programName'),
              projectName: get(notification, 'data.projectName'),
            }
          ),
          link: notLink,
          avatar: {
            iconSrc: programUnlinkProjectIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROGRAM_CLOSED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.PROGRAM_CLOSED', {
            triggererName: get(notification, 'data.triggererName'),
            programName: get(notification, 'data.programName'),
          }),
          link: `${PATHS.PROGRAM_PAGE}/${get(notification.data, 'programId')}`,
          avatar: {
            iconSrc: programClosed,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROGRAM_DELETED:
        const flag = get(notification, 'data.flag');
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(`APP_NOTIFICATIONS.PROGRAM_DELETED.${flag}`, {
            triggererName: get(notification, 'data.triggererName'),
            programName: get(notification, 'data.programName'),
            numberOfProjects: get(notification, 'data.numberOfProjects'),
            newProgramTitle: get(notification, 'data.newProgramTitle'),
          }),
          link:
            flag === DELETE_PROGRAM_OPTIONS.MOVE_PROJECTS
              ? `${PATHS.PROGRAM_PAGE}/${get(
                  notification.data,
                  'newProgramId'
                )}`
              : '',
          avatar: {
            iconName: 'trash',
            iconProps: {
              className: 'w-6 h-6 text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.PROJECT_IS_ARCHIVED_UPDATED:
        let action = intl.get('PROJECT_DETAIL.ARCHIVED');
        const currentIsArchivedStatus =
          get(notification, 'data.currentIsArchivedStatus') || false;
        const previousIsArchivedStatus =
          get(notification, 'data.previousIsArchivedStatus') || false;
        if (!currentIsArchivedStatus && previousIsArchivedStatus) {
          action = intl.get('PROJECT_DETAIL.UNARCHIVED');
        }
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.PROJECT_IS_ARCHIVED_UPDATED',
            {
              triggererName: get(notification, 'data.triggererName'),
              projectName: get(notification, 'data.projectName'),
              action: action.toLowerCase(),
            }
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            iconSrc:
              !currentIsArchivedStatus && previousIsArchivedStatus
                ? unarchive
                : '',
            iconName:
              currentIsArchivedStatus && !previousIsArchivedStatus
                ? 'archive'
                : '',
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_BUNDLE_UPDATED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_BUNDLE_UPDATED', {
            adminName: get(notification, 'data.adminName'),
            bundleTitle: get(notification, 'data.bundleTitle'),
          }),
          link: PATHS.EDIT_TASK_BUNDLE_PAGE.replace(
            ':taskBundleId',
            get(notification, 'data.bundleId')
          ),
          avatar: {
            iconSrc: updateTaskBundleIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.TASK_BUNDLE_DELETED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.TASK_BUNDLE_DELETED', {
            adminName: get(notification, 'data.adminName'),
            bundleTitle: get(notification, 'data.bundleTitle'),
          }),
          link: '',
          avatar: {
            iconSrc: removeIcon,
            iconProps: {
              className: 'w-6 h-6 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.V2_PROJECT_PARTICIPANT_ADDED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.V2_PROJECT_PARTICIPANT_ADDED',
            {
              triggererName: get(notification, 'data.triggererName'),
              projectName: get(notification, 'data.projectName'),
            }
          ),
          link: `${PATHS.PROJECT_PAGE}/${get(notification, 'data.projectId')}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.V2_PROJECT_PARTICIPANT_REMOVED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.V2_PROJECT_PARTICIPANT_REMOVED',
            {
              triggererName: get(notification, 'data.triggererName'),
              projectName: get(notification, 'data.projectName'),
            }
          ),
          link: `${PATHS.PROJECTS_LIST_PAGE}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.BLOCKING_TASK_COMPLETED:
        const unblockedTasksList = get(
          notification,
          'data.unblockedTasksList',
          []
        );
        const unblockedTasksLeft = unblockedTasksList.length - 1;
        let notificationLink = `${PATHS.PROJECT_PAGE}/${get(
          notification,
          'data.projectId'
        )}?tab=tasks`;
        if (unblockedTasksList.length === 1) {
          notificationLink = `${PATHS.PROJECT_PAGE}/${get(
            notification,
            'data.projectId'
          )}/tasks/${get(unblockedTasksList[0], 'id')}`;
        }
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.BLOCKING_TASK_COMPLETED', {
            taskName: get(notification, 'data.taskName'),
            unblockedTaskName: get(unblockedTasksList[0], 'name'),
            unblockedTasksLeft,
          }),
          link: notificationLink,
          avatar: {
            iconSrc: blockingTaskCompletedIcon,
            iconProps: {
              className: 'w-7 h-7 fill-current text-neutral-dark',
            },
          },
        };
        break;
      case NOTIFICATION_TYPE.DASHBOARD_INVITATION_ADDED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.DASHBOARD_INVITATION_ADDED',
            {
              triggererName: get(notification, 'data.triggererName'),
              dashboardName: get(notification, 'data.dashboardName'),
            }
          ),
          link: `${PATHS.DASHBOARD_PAGE}/${get(
            notification,
            'data.dashboardId'
          )}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      case NOTIFICATION_TYPE.SCHEDULE_DELIVERY_INVITATION_ADDED: {
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML(
            'APP_NOTIFICATIONS.SCHEDULE_DELIVERY_INVITATION_ADDED',
            {
              triggererName: get(notification, 'data.triggererName'),
              frequencyValue: get(notification, 'data.frequencyValue'),
              dashboardName: get(notification, 'data.dashboardName'),
            }
          ),
          link: `${PATHS.DASHBOARD_PAGE}/${get(
            notification,
            'data.dashboardId'
          )}`,
          avatar: {
            imageSrc: currentUser.avatar_url,
            initial: `${get(currentUser, 'firstName[0]', '')}${get(
              currentUser,
              'lastName[0]',
              ''
            )}`,
            bgColorClass: 'bg-neutral-darker',
            size: 'small',
          },
        };
        break;
      }
      case NOTIFICATION_TYPE.GOAL_OWNER_ASSIGNED:
        formttedNotification = {
          ...formttedNotification,
          content: intl.getHTML('APP_NOTIFICATIONS.GOAL_OWNER_ASSIGNED', {
            triggererName: get(notification, 'data.triggererName'),
            goalTitle: get(notification, 'data.goalTitle'),
          }),
          link: `${PATHS.STRATEGY_GOAL_DETAILS}/${get(
            notification.data,
            'goalId'
          )}`,
          avatar: {
            iconSrc: assignOwnerIcon,
            iconProps: {
              className: 'w-6 h-6',
            },
          },
        };
        break;
    }

    if (formttedNotification.content) {
      formttedNotification = {
        ...formttedNotification,
        id: notification.id || '',
        time: notification.createdAt
          ? moment(notification.createdAt)?.fromNow()
          : '',
        status: notification.status,
        avatar: {
          ...formttedNotification.avatar,
          bgColorClass: get(
            formttedNotification,
            'avatar.bgColorClass',
            'bg-transparent'
          ),
          size: get(formttedNotification, 'avatar.size', 'medium'),
        },
      };
    }
    return formttedNotification;
  });
};
