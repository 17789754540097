import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import {
  AppliedFilter,
  FieldsFilter,
  FieldsFiltersKey,
} from 'utils/types/filters';
import { CUSTOM_FIELDS_FILTERS } from 'utils/constants';

export type Props = {
  filters: FieldsFilter;
  onUpdateFilters: (filters: FieldsFilter) => void;
  onDisplayAllFilters: () => void;
  className?: string;
};

const FieldsAppliedFiltersTags: React.FC<Props> = ({
  filters,
  onUpdateFilters,
  onDisplayAllFilters,
  className = '',
}) => {
  const appliedFilters = useMemo<AppliedFilter[]>(() => {
    const tags: AppliedFilter[] = [];
    for (const key of Object.keys(filters)) {
      const filterKey = key as FieldsFiltersKey;
      const filter = filters[filterKey] as string[];
      filter.forEach((value: string) => {
        const label = intl.get(
          `SETTINGS_PAGE.FIELDS_PAGE.FIELD_NAMES.${value.toUpperCase()}`
        );
        tags.push({ label, value, key });
      });
    }
    return tags;
  }, [filters]);

  const handleRemoveFilter = (filter: AppliedFilter) => {
    const { key, value } = filter;
    const filterKey = key as FieldsFiltersKey;
    const updatedFilters = { ...filters };
    if (filterKey === CUSTOM_FIELDS_FILTERS.TYPE) {
      const filterValues = updatedFilters[filterKey] as string[];
      const updatedFilterValues = filterValues.filter(
        (filterValue) => filterValue !== value
      );
      if (updatedFilterValues.length === 0) {
        delete updatedFilters[filterKey];
      } else {
        updatedFilters[filterKey] = updatedFilterValues;
      }
    }
    onUpdateFilters(updatedFilters);
  };

  return (
    <AppliedFilters<AppliedFilter>
      filters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearAll={() => onUpdateFilters({} as FieldsFilter)}
      onDisplayAllFilters={onDisplayAllFilters}
      className={tailwindOverride('border-primary-lighter-two z-20', className)}
      data-testid='applied-fields-filters-tags'
      title={intl.get('FILTER_PANEL.FILTERS_APPLIED')}
      clearAllLabel={intl.get('FILTER_PANEL.CLEAR_ALL')}
    />
  );
};

export default FieldsAppliedFiltersTags;
