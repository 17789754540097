import { FC } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Avatar, AvatarGroup, Typography } from '@getsynapse/design-system';
import { selectProjectOwnersById } from 'state/ProjectsList/projectsListSlice';
import { RootState } from 'state/store';
import { mapProjectOwnerToAvatar } from '../../helpers';

interface ProjectOwnersProps {
  owners: string[];
}

const ProjectOwners: FC<ProjectOwnersProps> = ({ owners }) => {
  const projectOwners = useSelector((state: RootState) =>
    selectProjectOwnersById(state, owners)
  );

  if (projectOwners.length === 1) {
    return (
      <div className='flex items-center gap-x-2.5'>
        <Avatar {...mapProjectOwnerToAvatar(projectOwners[0])} />
        <Typography variant='label'>{projectOwners[0].name}</Typography>
      </div>
    );
  }

  return (
    <AvatarGroup
      avatars={projectOwners.map((projectOwner) => ({
        ...mapProjectOwnerToAvatar(projectOwner),
        key: projectOwner.id,
      }))}
      deactivatedText={intl.get('DEACTIVATED')}
    />
  );
};

export default ProjectOwners;
