import { useCallback, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@getsynapse/design-system';
import { isUserTypeFacilitator, selectIsUserLd } from 'state/User/userSlice';
import Tasks from '../components/Tasks';
import {
  selectMyTasksList,
  selectTeamTasksList,
  selectAllMyTasksIds,
  selectAllTeamTasksIds,
  selectTasksTablePagination,
  updateMyTasksPagination,
  updateTeamTasksPagination,
  setFetchTasksStatus,
  tasksFetchStatus,
} from 'state/Tasks/taskSlice';
import { getLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { SETTINGS_FILTERS, TABLE_TABS_MAPPING } from 'utils/constants';
import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'utils/constants/ui';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import isEmpty from 'lodash/isEmpty';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';
import { fetchProjects } from 'state/Projects/projectsSlice';

const TableView: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('activeTab');

  const dispatch = useDispatch();
  const [currentTabIndex, setCurrentTabIndex] = useState(
    activeTab ? TABLE_TABS_MAPPING[activeTab] : 0
  );
  const isLDUser = useSelector(selectIsUserLd);
  const isUserFacilitator = useSelector(isUserTypeFacilitator);
  const teamTasksList = useSelector(selectTeamTasksList);
  const myTasksList = useSelector(selectMyTasksList);
  const allTeamTasksList = useSelector(selectAllTeamTasksIds);
  const allMyTasksList = useSelector(selectAllMyTasksIds);
  const fetchTasksStatus = useSelector(tasksFetchStatus);
  const teamTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS)
  );
  const myTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS)
  );

  const getTeamTasks = useCallback(() => {
    dispatch(
      updateTeamTasksPagination({
        limit: teamTasksTablePagination.limit,
        offset: teamTasksTablePagination.offset,
      })
    );
  }, [
    dispatch,
    teamTasksTablePagination.limit,
    teamTasksTablePagination.offset,
  ]);

  const getUserTasks = useCallback(() => {
    dispatch(
      updateMyTasksPagination({
        limit: myTasksTablePagination.limit,
        offset: myTasksTablePagination.offset,
      })
    );
  }, [dispatch, myTasksTablePagination.limit, myTasksTablePagination.offset]);

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        dispatch(fetchProjects()),
        dispatch(getLearningTeams()),
        dispatch(getAllUsers()),
        dispatch(
          fetchFilterSettingByType(
            SETTINGS_FILTERS.CENTRALIZED_TEAM_TASKS_TABLE
          )
        ),
        dispatch(
          fetchFilterSettingByType(SETTINGS_FILTERS.MY_CENTRALIZED_TASKS_TABLE)
        ),
      ]);
      dispatch(
        updateTeamTasksPagination({
          limit: DEFAULT_PAGE_SIZE,
          offset: DEFAULT_OFFSET,
        })
      );
      dispatch(
        updateMyTasksPagination({
          limit: DEFAULT_PAGE_SIZE,
          offset: DEFAULT_OFFSET,
        })
      );
    };
    dispatch(setFetchTasksStatus('loading'));
    init();
  }, [dispatch]);

  const populatedMyTaskList = useMemo(
    () => ({
      data: myTasksList.data,
      allIds: allMyTasksList,
      total: allMyTasksList.length,
    }),
    [allMyTasksList, myTasksList]
  );

  const populatedTeamTaskList = useMemo(
    () => ({
      data: teamTasksList.data,
      allIds: allTeamTasksList,
      total: allTeamTasksList.length,
    }),
    [allTeamTasksList, teamTasksList]
  );

  const handleUpdateTeamPagination = useCallback(
    (pagination) => {
      dispatch(updateTeamTasksPagination(pagination));
    },
    [dispatch]
  );

  const handleUpdateMyPagination = useCallback(
    (pagination) => {
      dispatch(updateMyTasksPagination(pagination));
    },
    [dispatch]
  );

  return (
    <div
      data-cy='centralized-tasks-table-view'
      className='relative w-full h-full'
    >
      <div className='pb-4 px-6'>
        {isLDUser || isUserFacilitator ? (
          <Tabs
            index={currentTabIndex}
            onChange={(index: number) => {
              setCurrentTabIndex(index);
              window.history.replaceState(
                {},
                '',
                `${window.location.pathname}?activeTab=${
                  Object.keys(TABLE_TABS_MAPPING)[index]
                }`
              );
            }}
            tabListProps={{ className: 'max-w-sm mb-4' }}
            tabPanelsProps={{ className: 'max-h-table overflow-y-auto' }}
            type='tab'
            data={[
              {
                label: intl.get('TASKS_LIST_PAGE.TEAM_TASKS'),
                content: currentTabIndex === 0 && (
                  <Tasks
                    taskTable={SETTINGS_FILTERS.CENTRALIZED_TEAM_TASKS_TABLE}
                    tabName='fromTeamTasks'
                    exportEnabled={!isEmpty(populatedTeamTaskList)}
                    tasksList={populatedTeamTaskList}
                    onUpdatePagination={handleUpdateTeamPagination}
                    onUpdateTask={getTeamTasks}
                    isLoading={fetchTasksStatus === 'loading'}
                  />
                ),
              },
              {
                label: intl.get('TASKS_LIST_PAGE.MY_TASKS'),
                content: currentTabIndex === 1 && (
                  <Tasks
                    taskTable={SETTINGS_FILTERS.MY_CENTRALIZED_TASKS_TABLE}
                    tabName='fromMyTasks'
                    exportEnabled={!isEmpty(populatedMyTaskList)}
                    tasksList={populatedMyTaskList}
                    onUpdatePagination={handleUpdateMyPagination}
                    onUpdateTask={getUserTasks}
                    isLoading={fetchTasksStatus === 'loading'}
                  />
                ),
              },
            ]}
          />
        ) : (
          <div>
            <span className='text-neutral-dark font-semibold text-base'>
              {intl.get('TASKS_LIST_PAGE.MY_TASKS')}
            </span>
            <Tasks
              taskTable={SETTINGS_FILTERS.MY_CENTRALIZED_TASKS_TABLE}
              tabName='fromMyTasks'
              tasksList={populatedMyTaskList}
              exportEnabled={!isEmpty(populatedMyTaskList)}
              onUpdatePagination={handleUpdateMyPagination}
              onUpdateTask={getUserTasks}
              isLoading={fetchTasksStatus === 'loading'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableView;
