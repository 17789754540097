import { FC } from 'react';
import LastRow from 'Molecules/LastRow/LastRow';
import { tailwindOverride } from '@getsynapse/design-system';

interface RequestTableLastRowProps {
  isOdd: boolean;
  tableName: string;
  onLastRowInView: (inView: boolean) => void;
}

const RequestTableLastRow: FC<RequestTableLastRowProps> = ({
  isOdd,
  tableName,
  onLastRowInView,
}) => {
  return (
    <LastRow
      isOdd={isOdd}
      tableName={tableName}
      onLastRowInView={onLastRowInView}
    >
      {({ rowRef, rowStyles, columnStyles, columnChildStyles }) => (
        <tr
          ref={rowRef}
          className={rowStyles}
          aria-label={`${tableName}__last-row`}
        >
          <td className={tailwindOverride('w-84', columnStyles)}>
            <div className={tailwindOverride('w-3/5', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-32', columnStyles)}>
            <div className={tailwindOverride('w-3/5', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-72', columnStyles)}>
            <div className={tailwindOverride('w-3/4', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-72', columnStyles)}>
            <div className={tailwindOverride('w-1/4', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-32', columnStyles)}>
            <div className={tailwindOverride('w-3/5', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-32', columnStyles)}>
            <div className={tailwindOverride('w-3/5', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-32', columnStyles)}>
            <div className={tailwindOverride('w-1/4', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-32', columnStyles)}>
            <div className={tailwindOverride('w-1/4', columnChildStyles)} />
          </td>
          <td className={tailwindOverride('w-6', columnStyles)}></td>
        </tr>
      )}
    </LastRow>
  );
};

export default RequestTableLastRow;
