import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  LICENSE_TIER,
  LICENSE_TIER_FOR_PENDO_DISPLAY,
  PATHS,
  PENDO_EVENTS,
  USER_TYPES,
} from 'utils/constants';
import useAuthentication from 'Hooks/useAuthentication';
import RequestPage from 'Pages/RequestPage/RequestPage';
import RequestsListPage from 'Pages/RequestsListPage/RequestsListPage';
import Loader from 'Molecules/Loader/Loader';
import SideBar from 'Organisms/SideBar/SideBar';
import Sidekick from 'Organisms/Sidekick/Sidekick';
import {
  getOrganization,
  selectOrganizationLicense,
} from 'state/Organization/organizationSlice';
import {
  selectIsFirstUser,
  selectIsUserLd,
  selectOrganizationId,
  selectUser,
  selectUserEmail,
  selectUserId,
  selectUserName,
  selectUserType,
} from 'state/User/userSlice';
import { selectSidekickData } from 'state/Sidekick/sidekickSlice';
import { License } from 'utils/customTypes';
import { initPendo, trackEvent } from 'Services/pendo';
import InsightsPage from 'Pages/InsightsPage/InsightsPage';
import DesignPage from 'Pages/DesignPage';
import TeamsPage from 'Pages/TeamsPage/TeamsPage';
import ProjectsListPage from 'Pages/ProjectsListPage/ProjectsListPage';
import TasksListPage from 'Pages/TasksListPage/TasksListPage';
import NewProjectPage from 'Pages/NewProjectPage/NewProjectPage';
import ProjectPage from 'Pages/ProjectPage/ProjectPage';
import Notifications from './Notifications';
import Header from 'Pages/Components/Header';
import SettingsPage from 'Pages/SettingsPage/SettingsPage';
import CustomFieldPage from 'Pages/CustomFieldPage/CustomFieldPage';
import NewCustomFieldPage from 'Pages/NewCustomFieldPage/NewCustomFieldPage';
import V1TaskPage from 'Pages/ProjectPage/tabs/Tasks/TaskPage';
import V2TaskPage from 'Pages/TaskDetailPage/TaskDetailPage';
import SidePanel from 'Organisms/SidePanel/SidePanel';
import UserPage from 'Pages/SettingsPage/UsersListPage/UserPage/UserPage';
import TemplatePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TemplatePage/TemplatePage';
import EditTeam from 'Pages/SettingsPage/TeamsSettings/Teams/EditTeam/EditTeam';
import FormPage from 'Pages/SettingsPage/Configurations/IntakeSettings/components/FormPage/FormPage';
import NewForm from 'Pages/SettingsPage/Configurations/IntakeSettings/components/FormPage/NewForm';
import VendorPage from 'Pages/SettingsPage/TeamsSettings/Vendor/VendorPage';
import LandingPage from 'Pages/LandingPage/LandingPage';
import EditProfilePage from 'Pages/EditProfilePage/EditProfilePage';
import MyTimeOff from 'Pages/UserTimeOff/MyTimeOff';
import TimeOffListPage from 'Pages/TimeOffListPage/TimeOffListPage';
import usePathPermission from 'Hooks/usePathPermission';
import useTrialPermission from 'Hooks/useTrialPermission';
import { getDesigns } from 'state/Designs/designsSlice';
import ProgramsListPage from 'Pages/ProgramsListPage/ProgramsListPage';
import ProgramPage from 'Pages/ProgramPage/ProgramPage';
import StrategyCategoryPage from 'Pages/StrategyCategoryPage/StrategyCategoryPage';
import NewTemplatePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/NewTemplatePage/NewTemplatePage';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import { UserPermissionsProvider } from 'Pages/ProjectPage/context/UserPermissionsContext';
import TaskBundlePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/TaskBundlePage';
import CategoryObjectivePage from 'Pages/CategoryObjectivePage/CategoryObjectivePage';
import ProjectDetailsUnloader from './ProjectDetailsUnloader';
import AdditionalBenefitPage from 'Pages/AdditionalBenefitPage/AdditionalBenefitPage';
import InputCategoryPage from 'Pages/InputCategoryPage/InputCategoryPage';
import ROICategoryPage from 'Pages/ROICategoryPage/ROICategoryPage';
import ROIObjectivePage from 'Pages/ROIObjectivePage/ROIObjectivePage';
import ROICostsPage from 'Pages/ROICostsPage/ROICostsPage';
import LearningSchedule from 'Pages/LearningSchedule/LearningSchedule';
import ProcessDetailPage from 'Pages/SettingsPage/Configurations/ProjectSettings/ProcessDetailPage';
import { NotificationsSettingsPage } from 'Pages/Notifications/NotificationsSettingsPage';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { H } from 'highlight.run';
import EvaluationsPage from 'Pages/EvaluationsPage/EvaluationsPage';
import StrategyGoalsPage from 'Pages/StrategyGoalsPage/StrategyGoalsPage';
import GoalDetailsPage from 'Pages/GoalDetailsPage/GoalDetailsPage';

const AppPage = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuthentication();
  const currentUserName = useSelector(selectUserName);
  const currentUserEmail = useSelector(selectUserEmail);
  const currentUserId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const userType = useSelector(selectUserType);
  const isFirstUser = useSelector(selectIsFirstUser);
  const isCurrentUserLD = useSelector(selectIsUserLd);
  const sidekickData = useSelector(selectSidekickData);
  const licenseData: License = useSelector(selectOrganizationLicense);
  const ldClient = useLDClient();
  const {
    enableProgramStrategy = false,
    enableProgramStrategyBeta122 = false,
    enableProgramStrategyBeta14 = false,
    enableProgramStrategy15Roi = false,
    facilitatorManagement = false,
    timeOff20 = false,
    highlightIoSessionReplay = false,
    amplitudeSessionReplay = false,
    newTaskDetailView = false,
    userEmailNotificationSettings = false,
    strategyPage,
  } = useFlags();

  const { hasUserAccess, permissionsLevel } = useHasUserAccess();
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOrganization(organizationId));
      dispatch(getDesigns());
    }
  }, [organizationId, dispatch]);

  const licenseType = useMemo(() => {
    if (licenseData.license_tier === LICENSE_TIER.REGULAR) {
      return LICENSE_TIER_FOR_PENDO_DISPLAY.REGULAR;
    } else {
      return licenseData.license_tier || '';
    }
  }, [licenseData.license_tier]);

  useEffect(() => {
    if (currentUserEmail && organizationId && licenseType) {
      initPendo(currentUserEmail, organizationId, licenseType, currentUser);
      trackEvent(PENDO_EVENTS.USER_LOGGED_IN, {
        email: currentUserEmail,
        accountType: licenseType,
        firstUser: isFirstUser,
      });
    }
  }, [organizationId, currentUserEmail, licenseType, isFirstUser, currentUser]);

  useEffect(() => {
    if (
      currentUserEmail &&
      currentUserId &&
      organizationId &&
      amplitudeSessionReplay
    ) {
      try {
        // TODO: import config to env after product trial end
        amplitude.init('dd1c3542e290d63e6e208807bcc82ac0', {
          defaultTracking: {
            sessions: true,
          },
          autocapture: {
            elementInteractions: true,
          },
        });

        amplitude.add(
          sessionReplayPlugin({
            sampleRate: 1,
          })
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error initializing Amplitude', error);
      }
    }
  }, [currentUserEmail, currentUserId, organizationId, amplitudeSessionReplay]);

  useEffect(() => {
    if (
      currentUserEmail &&
      currentUserId &&
      organizationId &&
      highlightIoSessionReplay
    ) {
      try {
        // TODO: import config to env after product trial end
        H.init('jdk0rxre', {
          serviceName: 'frontend-app',
          tracingOrigins: true,
          networkRecording: {
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [],
          },
        });
        H.identify(currentUserEmail, {
          id: currentUserId,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error initializing Highlight', error);
      }
    }
  }, [
    currentUserEmail,
    currentUserId,
    organizationId,
    highlightIoSessionReplay,
  ]);

  useEffect(() => {
    if (organizationId && currentUserEmail && currentUserName && ldClient) {
      ldClient.identify({
        kind: 'user',
        key: currentUserEmail,
        name: currentUserName,
        accountId: organizationId,
      });
    }
  }, [organizationId, currentUserEmail, currentUserName, ldClient]);

  usePathPermission();

  useTrialPermission();

  if (!isAuthenticated) {
    return (
      <div className='flex h-screen px-16'>
        <Loader />
      </div>
    );
  }

  let TaskPage = V1TaskPage;
  if (newTaskDetailView) {
    // TODO: Implement a better component
    TaskPage = V2TaskPage;
  }

  return (
    <div className='flex h-screen overflow-hidden'>
      <SideBar />
      <SidePanel />
      {sidekickData.isOpen && <Sidekick />}
      <div
        className='flex flex-col flex-grow relative'
        style={{ width: '85%' }}
      >
        <Header />

        <Notifications />

        <div className='flex-grow overflow-y-auto bg-neutral-white'>
          <Switch>
            <Route exact path={PATHS.ROOT}>
              {userType !== USER_TYPES.BUSINESS ? (
                <LandingPage />
              ) : (
                <Redirect to={PATHS.REQUESTS_LIST_PAGE} />
              )}
            </Route>

            <Route
              path={[`${PATHS.REQUEST_PAGE}/:requestId`, PATHS.REQUEST_PAGE]}
            >
              <RequestPage />
            </Route>

            <Route path={`${PATHS.STRATEGY_GOAL_DETAILS}/:goalId`}>
              <GoalDetailsPage />
            </Route>

            <Route path={PATHS.REQUESTS_LIST_PAGE}>
              <RequestsListPage />
            </Route>

            <Route path={`${PATHS.NEW_PROJECT_PAGE}/:templateId`}>
              {isCurrentUserLD ? (
                <NewProjectPage />
              ) : (
                <Redirect to={PATHS.PROJECTS_LIST_PAGE} />
              )}
            </Route>

            {facilitatorManagement && (
              <Route path={PATHS.LEARNING_SCHEDULE_PAGE}>
                <LearningSchedule />
              </Route>
            )}

            <Route path={`${PATHS.PROJECT_PAGE}/:projectId`}>
              <ProjectDetailsUnloader>
                <Switch>
                  <Route exact path={`${PATHS.PROJECT_PAGE}/:projectId`}>
                    {hasUserAccess ? (
                      <UserPermissionsProvider
                        userPermissionsLevel={permissionsLevel}
                      >
                        <ProjectPage />
                      </UserPermissionsProvider>
                    ) : (
                      <Redirect to={PATHS.PROJECTS_LIST_PAGE} />
                    )}
                  </Route>

                  <Route
                    exact
                    path={`${PATHS.PROJECT_PAGE}/:projectId/tasks/:taskId`}
                  >
                    <TaskPage />
                  </Route>
                </Switch>
              </ProjectDetailsUnloader>
            </Route>

            <Route path={PATHS.INSIGHTS}>
              <InsightsPage />
            </Route>

            <Route path={PATHS.PROJECTS_LIST_PAGE}>
              <ProjectsListPage />
            </Route>

            <Route path={PATHS.TASKS_LIST_PAGE}>
              <TasksListPage />
            </Route>

            <Route path={PATHS.DESIGN}>
              <DesignPage />
            </Route>

            <Route path={PATHS.EVALUATIONS}>
              <EvaluationsPage />
            </Route>

            <Route path={[`${PATHS.TEAMS}/:activeTab`, PATHS.TEAMS]}>
              <TeamsPage />
            </Route>

            <Route path={`${PATHS.SETTINGS}${PATHS.FORM_PAGE}/:formId`}>
              <FormPage />
            </Route>

            <Route
              path={`${PATHS.SETTINGS}${PATHS.CUSTOM_FIELDS_PAGE}/:fieldId`}
            >
              <CustomFieldPage />
            </Route>

            <Route path={PATHS.NEW_CUSTOM_FIELD_PAGE}>
              <NewCustomFieldPage />
            </Route>

            <Route path={`${PATHS.SETTINGS}${PATHS.FORM_PAGE}`} exact>
              <NewForm />
            </Route>

            <Route
              path={[`${PATHS.SETTINGS}/:activeTab`, PATHS.SETTINGS]}
              exact
            >
              <SettingsPage />
            </Route>

            <Route path={`${PATHS.EDIT_TEAM}/:teamType/:teamId`}>
              <EditTeam />
            </Route>

            <Route path={`${PATHS.USER_PAGE}/:userId/:userType?`}>
              <UserPage />
            </Route>

            <Route path={`${PATHS.PROCESS_PAGE}/:processId`} exact>
              <ProcessDetailPage />
            </Route>

            <Route path={PATHS.NEW_TEMPLATE_PAGE}>
              <NewTemplatePage />
            </Route>

            <Route
              path={[
                PATHS.NEW_TASK_BUNDLE_PAGE,
                PATHS.CLONE_TASK_BUNDLE_PAGE,
                PATHS.EDIT_TASK_BUNDLE_PAGE,
              ]}
            >
              <TaskBundlePage />
            </Route>

            <Route path={`${PATHS.TEMPLATE_PAGE}/:templateId`}>
              <TemplatePage />
            </Route>

            <Route path={`${PATHS.VENDOR_PAGE}/:vendorId`}>
              <VendorPage />
            </Route>

            <Route path={PATHS.PROFILE}>
              <EditProfilePage />
            </Route>

            {userEmailNotificationSettings && (
              <Route path={PATHS.NOTIFICATIONS}>
                <NotificationsSettingsPage />
              </Route>
            )}

            <Route path={PATHS.PROGRAMS_LIST_PAGE}>
              <ProgramsListPage />
            </Route>
            {enableProgramStrategy && (
              <Route
                exact
                path={`${PATHS.PROGRAM_PAGE}/:programId/:activeTab?`}
              >
                <ProgramPage />
              </Route>
            )}
            {enableProgramStrategy && (
              <Route
                exact
                path={`${PATHS.PROGRAM_PAGE}/:programId${PATHS.STRATEGY_PAGE}/:categoryId`}
              >
                <StrategyCategoryPage />
              </Route>
            )}
            {enableProgramStrategy && (
              <Route
                path={`${PATHS.PROGRAM_PAGE}/:programId/:activeTab?/:categoryId${PATHS.CATEGORY_OBJECTIVE_PAGE}/:objectiveId`}
              >
                <CategoryObjectivePage />
              </Route>
            )}
            {enableProgramStrategyBeta122 && (
              <Route
                path={`${PATHS.PROGRAM_PAGE}/:programId${PATHS.STRATEGY_PAGE}/:categoryId${PATHS.ADDITIONAL_BENEFIT_PAGE}/:benefitId`}
              >
                <AdditionalBenefitPage />
              </Route>
            )}
            {enableProgramStrategyBeta14 && (
              <Route
                path={`${PATHS.PROGRAM_PAGE}/:programId${PATHS.INPUT_CATEGORY_PAGE}/:categoryId`}
              >
                <InputCategoryPage />
              </Route>
            )}
            {enableProgramStrategy15Roi && (
              <Route
                exact
                path={`${PATHS.PROGRAM_PAGE}/:programId${PATHS.ROI_CATEGORY_PAGE}/:categoryId`}
              >
                <ROICategoryPage />
              </Route>
            )}
            {enableProgramStrategy15Roi && (
              <Route
                path={`${PATHS.PROGRAM_PAGE}/:programId${PATHS.ROI_CATEGORY_PAGE}/:categoryId${PATHS.ROI_OBJECTIVE_PAGE}/:objectiveId`}
              >
                <ROIObjectivePage />
              </Route>
            )}
            {enableProgramStrategy15Roi && (
              <Route
                path={`${PATHS.PROGRAM_PAGE}/:programId${PATHS.ROI_CATEGORY_PAGE}/:categoryId${PATHS.ROI_COSTS_PAGE}`}
              >
                <ROICostsPage />
              </Route>
            )}
            {!enableProgramStrategy && (
              <Route exact path={`${PATHS.PROGRAM_PAGE}/:programId`}>
                <ProgramPage />
              </Route>
            )}
            <Route path={PATHS.TIME_OFF}>
              <MyTimeOff />
            </Route>
            {timeOff20 && (
              <Route
                path={[
                  `${PATHS.MANAGE_TIME_OFF}/:userId`,
                  PATHS.MANAGE_TIME_OFF,
                ]}
              >
                <TimeOffListPage />
              </Route>
            )}
            {strategyPage && (
              <Route path={PATHS.STRATEGY_GOALS}>
                <StrategyGoalsPage />
              </Route>
            )}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AppPage;
