import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import intl from 'react-intl-universal';
import { Tag } from '@getsynapse/design-system';

export enum RequestTabsKeys {
  ACTIVE_ITEMS = 'activeItems',
  TEAM_ITEMS = 'teamItems',
  MY_ITEMS = 'myItems',
}
type RequestTabsIndices = 0 | 1 | 2;
interface RequestTabValues {
  label: React.ReactNode;
  index: RequestTabsIndices;
}
type RequestTabsMapping = Partial<Record<RequestTabsKeys, RequestTabValues>>;

const useRequestTabsMapping = (isBusinessUser: boolean): RequestTabsMapping => {
  const { intakeActiveTab = false } = useFlags();

  const activeRequestsTabLabel = useMemo(
    () => (
      <div className='flex items-center justify-center'>
        {intl.get('REQUESTS_LIST_PAGE.SUB_NAV.ACTIVE_REQUESTS')}
        <Tag
          label={intl.get('BETA')}
          textClassName='text-purple-darker font-semibold'
          className='bg-purple-lightest border-purple border ml-1 h-5 py-0'
        />
      </div>
    ),
    []
  );

  const BUSINESS_USER_TABS_MAPPING = useMemo<RequestTabsMapping>(() => {
    if (intakeActiveTab) {
      return {
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 0,
        },
        activeItems: {
          label: activeRequestsTabLabel,
          index: 1,
        },
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 2,
        },
      };
    } else {
      return {
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 0,
        },
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 1,
        },
      };
    }
  }, [intakeActiveTab, activeRequestsTabLabel]);

  const NON_BUSINESS_USER_TABS_MAPPING = useMemo<RequestTabsMapping>(() => {
    if (intakeActiveTab) {
      return {
        activeItems: {
          label: activeRequestsTabLabel,
          index: 0,
        },
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 1,
        },
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 2,
        },
      };
    } else {
      return {
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 0,
        },
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 1,
        },
      };
    }
  }, [intakeActiveTab, activeRequestsTabLabel]);

  const availableTabs: RequestTabsMapping = isBusinessUser
    ? BUSINESS_USER_TABS_MAPPING
    : NON_BUSINESS_USER_TABS_MAPPING;

  return availableTabs;
};

export default useRequestTabsMapping;
