import { useEffect, useState, useMemo } from 'react';
import intl from 'react-intl-universal';
import en from 'lang/en.json';
import fr from 'lang/fr.json';
import {
  Theme,
  GlobalBanner,
  tailwindOverride,
} from '@getsynapse/design-system';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import AppPage from 'Templates/AppPage';
import { PATHS } from 'utils/constants';
import {
  hideNotification,
  selectNotificationText,
  selectShowNotification,
  selectNotificationTimeout,
  selectNotificationVariant,
} from 'state/InlineNotification/inlineNotificationSlice';
import { getSnackBarNotificationState } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import AuthPage from 'Templates/AuthPage';
import Login from 'Pages/Login/Login';
import ForgotPassword from 'Pages/ForgotPassword/ForgotPassword';
import ChangePassword from 'Pages/ChangePassword/ChangePassword';
import SSOAuthentication from 'Pages/SSOAuthentication/SSOAuthentication';
import { initAuthentication } from './aws-exports';
import DeactivatedAccountPage from 'Pages/DeactivatedAccountPage/DeactivatedAccountPage';
import PasswordSetup from 'Pages/PasswordSetup/PasswordSetup';
import VerifyUser from 'Pages/VerifyUser/VerifyUser';
import RegisterPage from './Pages/RegisterPage/RegisterPage';
import AnonNotice from 'Templates/AnonNotice';
import UpgradePage from 'Pages/UpgradePage/UpgradePage';
import AlmostThere from 'Pages/AlmostThere/AlmostThere';
import CompleteRegistration from 'Pages/CompleteRegistration/CompleteRegistration';
import SnackbarNotification from 'Molecules/SnackbarNotification';
import { LEGACY_PATHS } from 'utils/constants/ui';
import initSyncfusion from './SyncfusionConfig';
import {
  LanguageProvider,
  useLanguage,
} from 'Organisms/LanguageModal/LanguageContext'; // Import LanguageContext

const AppContent = () => {
  const locales: { [key: string]: any } = useMemo(() => ({ en, fr }), []);

  const dispatch = useDispatch();
  const [initDone, setInitDone] = useState<boolean>(false);
  const { currentLocale } = useLanguage();
  const showNotification = useSelector(selectShowNotification);
  const notificationMessage = useSelector(selectNotificationText);
  const timeout = useSelector(selectNotificationTimeout);
  const notificationVariant = useSelector(selectNotificationVariant);
  const isSnackBarNotificationVisible = useSelector(
    getSnackBarNotificationState
  );
  const requireLang = (require as any).context('lang', false, /\.json$/);

  requireLang.keys().forEach((filename: string) => {
    const lang = filename.replace('./', '').replace('.json', ''); // Get the language code from filename
    locales[lang] = requireLang(filename); // Add the imported language JSON to the locales object
  });

  useEffect(() => {
    const loadLocales = async () =>
      await intl.init({
        currentLocale, // Dynamically load based on currentLocale
        locales,
      });

    loadLocales();
    initAuthentication();
    setInitDone(true);
    initSyncfusion();
  }, [currentLocale, locales]);

  if (!initDone) {
    return null;
  }

  return (
    <LanguageProvider>
      <Theme>
        {showNotification && (
          <GlobalBanner
            variant={notificationVariant}
            timeout={timeout}
            onTimeout={() => dispatch(hideNotification())}
            className={tailwindOverride(
              'absolute',
              'top-0',
              'left-0',
              'z-40',
              'flex-grow',
              'w-full'
            )}
            hasCloseIcon
            data-cy='app-inline-notification'
          >
            {() => <span>{notificationMessage}</span>}
          </GlobalBanner>
        )}
        {isSnackBarNotificationVisible && <SnackbarNotification />}
        <Router>
          <Switch>
            <Route path={`${PATHS.LOGIN}/:organizationId?`}>
              <AuthPage>
                <Login />
              </AuthPage>
            </Route>

            <Route
              path={[
                PATHS.SSO_AUTHENTICATION_PAGE,
                PATHS.SSO_AUTHENTICATION_CALLBACK,
              ]}
            >
              <SSOAuthentication />
            </Route>

            <Route
              path={LEGACY_PATHS.REGISTER}
              component={() => {
                window.location.href = 'https://cognota.com/book-consult/';
                return null;
              }}
            />

            <Route path={`${PATHS.REGISTER}/:userId?`}>
              <AuthPage>
                <RegisterPage />
              </AuthPage>
            </Route>

            <Route path={PATHS.UPGRADE}>
              <AnonNotice page='upgrade'>
                <UpgradePage />
              </AnonNotice>
            </Route>

            <Route path={PATHS.DEACTIVATED_ACCOUNT}>
              <AnonNotice page='deactivated'>
                <DeactivatedAccountPage />
              </AnonNotice>
            </Route>

            <Route path={PATHS.FORGOT_PASSWORD}>
              <AuthPage>
                <ForgotPassword />
              </AuthPage>
            </Route>

            <Route path={PATHS.CHANGE_PASSWORD}>
              <AuthPage>
                <ChangePassword />
              </AuthPage>
            </Route>

            <Route path={`${PATHS.PASSWORD_SETUP}/:userId`}>
              <AuthPage>
                <PasswordSetup />
              </AuthPage>
            </Route>

            <Route path={`${PATHS.ALMOST_THERE}/:email`}>
              <AnonNotice page='almostThere'>
                <AlmostThere />
              </AnonNotice>
            </Route>

            <Route path={PATHS.VERIFY_USER}>
              <AnonNotice page='verify'>
                <VerifyUser />
              </AnonNotice>
            </Route>

            <Route path={PATHS.COMPLETE_REGISTRATION}>
              <AuthPage>
                <CompleteRegistration />
              </AuthPage>
            </Route>

            <Route path={PATHS.ROOT}>
              <AppPage />
            </Route>
          </Switch>
        </Router>
      </Theme>
    </LanguageProvider>
  );
};

const App = () => (
  <LanguageProvider>
    <AppContent />
  </LanguageProvider>
);

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LEARNOPS_LAUNCH_DARKLY_API_KEY || '',
  options: {
    logger: basicLogger({ level: 'none' }),
  },
})(App);
