export const GOAL_TYPES = {
  COMPANY: 'company',
  TEAM: 'team',
};

export const GOAL_PERIODS = {
  FISCAL_YEAR: 'FY',
  QUARTER: 'Q',
};

export const timePeriodOptions = [
  {
    label: 'FY 2025',
    value: { timePeriod: 'FY', year: '2025' },
  },
  {
    label: 'Q1 2025',
    value: { timePeriod: 'Q1', year: '2025' },
  },
  {
    label: 'Q2 2025',
    value: { timePeriod: 'Q2', year: '2025' },
  },
  {
    label: 'Q3 2025',
    value: { timePeriod: 'Q3', year: '2025' },
  },
  {
    label: 'Q4 2025',
    value: { timePeriod: 'Q4', year: '2025' },
  },
];

export const GOAL_PROJECTS_TABLE_COLUMNS = {
  ID: 'project_number',
  TITLE: 'project_title',
  STATUS: 'project_status',
  OWNER: 'project_owners',
  PROGRESS: 'project_progress',
  START_DATE: 'project_start_date',
  TARGET_COMPLETION_DATE: 'project_target_completion_date',
};
